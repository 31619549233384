import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import TextBoxComponent from '../../components/TextboxComponent';
import RestaurantsService from '../../services/founder/RestaurantsServices'
import { toast } from 'react-toastify'

const RestaurantMenu = () => {

    const model ={
        id: "",
        name: "",
        description: {
            description: "",
            phone: "",
            cheff_name: ""
        },
        menu: "",
        address: {
            id: "",
            latitude: 0,
            longitude: 0,
            address: ""
        },
        cost: {
            id: "",
            name: ""
        },
        kitchens: [],
        extra_services: [],
        photos: [],
        capacity: [],
        schedule: []
    }

    const [restaurantId] = React.useState(useParams());
    const [restaurantInfo, setRestaurantInfo]= React.useState(model);
    const [menuValidation, setMenuValidation] = React.useState({hasValidate:false,hasError:false});

    const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

    function getRestaurantInfo(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        RestaurantsService.getRestaurant(restaurantId.id).then((r) => {
            if(r != null)
            {
                setRestaurantInfo(r);
                setMenuValidation({})
            }
            else
            {

            }
        });
    }

    function handleMenuChange(e){
        let urlRegex = /^(http|https):\/\/[^ "]+$/;
        restaurantInfo.menu = e.target.value;
        if(!urlRegex.test(String(e.target.value)) ||e.target.value == "" || e.target.value.includes(' ')){
            setMenuValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else if(e.target.value.length > 250){
            setMenuValidation({hasValidate:true,hasError:true,error:'Maximo 250 caracteres'});
        }
        else if (regex.test(e.target.value)) {
            setMenuValidation({hasValidate:true,hasError:true,error:'Formato incorrecto.'});
        }
        else{
            setMenuValidation({hasValidate:true,hasError:false})
            
        }
    }

    function putMenu(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(menuValidation.hasValidate && !menuValidation.hasError)
        {
            let model = {};
            model.id = restaurantId.id;
            model.url = restaurantInfo.menu;
            RestaurantsService.putMenu(model).then((r) => {
                if(r.success && r.message == "OK"){
                    notify()
                    setMenuValidation({})
                }
                else{
                    error()
                }
            });
        }
    }

    function notify() {
        toast.success('Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    function error(message) {
        toast.error(message != undefined ? message : 'No se pudo actualizar', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    React.useEffect(()=>{
        getRestaurantInfo()
    },[]);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = () =>(
        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    RESTAURANTE
                </label>
            </div>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "31px",
                textAlign: "left",
                padding: "0 24px"
            }}>
                <Link className="tabitem" to={`/Restaurants/Details/${restaurantId.id}`}>DESCRIPCIÓN</Link>
                <Link className="tabitem" to={`/Restaurants/Photographs/${restaurantId.id}`}>FOTOGRAFÍAS</Link>
                <Link className="tabitem" to={`/Restaurants/Capacity/${restaurantId.id}`}>CAPACIDAD</Link>
                <Link className="tabitem selected" to={`/Restaurants/Menu/${restaurantId.id}`}>MENÚ</Link>
                <Link className="tabitem" to={`/Restaurants/Hours/${restaurantId.id}`}>HORARIO</Link>
            </div>
            <div className="row" style={{display: "flex", padding: "29px 40px", height:'calc(100% - 150px)', overflow:'auto'}}>
                <div className="col-5" style={{textAlign: "left"}}>
                    <label style={{
                                    width: "370px",
                                    height: "25px",
                                    fontFamily: "Commuters Sans SemiBold",
                                    fontSize: "16px",
                                    fontWeight: 300,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.25,
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    "flex-grow": "1",
                                    color: "black",
                                    margin: '41px 57px 20px 0px'}}>
                        ACTUALIZA LA URL DE TU MENÚ DIGITAL
                    </label>
                    <label style={{
                                color:'black',
                                width: '450px',
                                height: '64px',
                                margin: '20px 58px 0px 0Px',
                                fontfamily: 'Roboto-Light',
                                fontsize: '14px',
                                fontweight: '300',
                                fontstretch: 'normal',
                                fontstyle: 'normal',
                                lineheight: '1.14',
                                letterspacing: '0.35px'}}>
                        Escribe la dirección web donde podemos encontrar tu menú. 
                    </label>
                </div>
                <div style={{
                        width: "0px",
                        height: "499px",
                        margin: "45px 0px 70px",
                        opacity: "0.15",
                        border: "solid 0.5px rgba(200, 200, 200)",
                        paddingLeft:"0px",
                        paddingRight:"0px"
                    }}>
    
                </div>
                <div className="col-6" style={{marginTop:'240px', marginLeft:'70px'}}>
                    <TextBoxComponent value={restaurantInfo.menu} placeholder="URL Menú" type="text" onChange={handleMenuChange} {...menuValidation}/> 
                    <div style={{paddingTop:'20px', display:'flex', justifyContent:'flex-end'}}>
                        <button className="btn btn-lg mt-5" style={{width:'162px', boxShadow: 'none', fontFamily:'Commuters Sans SemiBold'}} onClick={() => {getRestaurantInfo()}}>
                            CANCELAR
                        </button>
                        <button className="btn btn-primary btn-lg mt-5" type="button" style={{width:'190px'}} onClick={() => {putMenu()}}>
                            GUARDAR
                        </button>
                    </div>
                    {/* <button className="btn btn-primary" style={{marginTop:'25px'}} onClick={() =>(putMenu())}>ACTUALIZAR MENÚ</button> */}
                </div>
            </div>
        </article>
    );
        
    const movile = () =>(
        <article>
            <a style={{position: "absolute", top:25, left:'5%' }} onClick={()=> window.location = "/Restaurants" }>
                <img width="24" height="24" src="/images/backicon.svg"/>
            </a>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '35%'
                         }}>
                RESTAURANTE
            </h5>
            <div className="div-tab-item">
                <Link className="tabitem" to={`/Restaurants/Details/${restaurantId.id}`} style={{paddingBottom:'16px'}}>DESCRIPCIÓN</Link>
                <Link className="tabitem" to={`/Restaurants/Photographs/${restaurantId.id}`} style={{paddingBottom:'16px'}}>FOTOGRAFÍAS</Link>
                <Link className="tabitem" to={`/Restaurants/Capacity/${restaurantId.id}`} style={{paddingBottom:'16px'}}>CAPACIDAD</Link>
                <Link className="tabitem selected" to={`/Restaurants/Menu/${restaurantId.id}`} style={{paddingBottom:'16px'}}>MENÚ</Link>
                <Link className="tabitem" to={`/Restaurants/Hours/${restaurantId.id}`} style={{paddingBottom:'16px'}}>HORARIO</Link>
            </div>
            <div style={{display:'flex', flexDirection:'column', padding: "0px 40px"}}>
                <label style={{
                                width: "358px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "16px",
                                fontWeight: 300,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                margin: '38px 57px 20px 0px'}}>
                    ACTUALIZA LA URL DE TU MENÚ DIGITAL
                </label>
                <label style={{
                            color:'black',
                            width: '358px',
                            height: '64px',
                            margin: '20px 58px 0px 0px',
                            fontfamily: 'Roboto-Light',
                            fontsize: '14px',
                            fontweight: '300',
                            fontstretch: 'normal',
                            fontstyle: 'normal',
                            lineheight: '1.14',
                            letterspacing: '0.35px',
                            textAlign: "left"}}>
                    Escribe la dirección web donde podemos encontrar tu menú. 
                </label>
                <TextBoxComponent value={restaurantInfo.menu} placeholder="URL Menú" type="text" onChange={handleMenuChange} {...menuValidation}/> 
                <div style={{paddingTop:'20px'}}>
                    <button className="btn btn-lg mt-5" style={{width:'162px', boxShadow: 'none', fontFamily:'Commuters Sans SemiBold'}} onClick={() => {getRestaurantInfo()}}>
                        CANCELAR
                    </button>
                    <button className="btn btn-primary btn-lg mt-5" type="button" style={{width:'190px'}} onClick={() => {putMenu()}}>
                        GUARDAR
                    </button>
                </div>
            </div>
        </article>
    );

    return (
        isDesktopOrLaptop ? desktop() : movile()
    )
}

export default RestaurantMenu
