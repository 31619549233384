import React from 'react'

export const ModalFunctions = {
    ToggleModal,
    HideModal,
    ShowModal
}

class ModalComponent extends React.Component {

    render(){
        return (
            <div className="modal-container d-none" {...this.props} id={this.props.id}>
                {this.props.children}
            </div>
        )
    }

}

function ToggleModal(id){
    var rootElement = document.getElementById('root');
    var s = rootElement.hasAttribute('showModal')
    if(s)
    {
        HideModal(id);
    }
    else{
        ShowModal(id);
    }
}

function HideModal(id){

    var rootElement = document.getElementById('root');
    rootElement.removeAttribute('showModal');
    document.getElementById(id).classList.add('d-none');
}

function ShowModal(id){
    
    var rootElement = document.getElementById('root');
    rootElement.setAttribute('showModal',id);
    document.getElementById(id).classList.remove('d-none');
}

export default ModalComponent;
