import React from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect, Link} from 'react-router-dom'
import OfferspageList from './offerspageList'
import Newoffer from './NewOffer'
import OffersDetails from './Offers/OfferDetails'



const OffersPage = () => {
    return (
        <Router>
            <Switch>
                
                <Route path="/offers/Newoffer" component={Newoffer}/>
                <Route path="/offers/Details/:id" component={OffersDetails} />

                <Route component={OfferspageList}/>
            </Switch>
        </Router>
    )
}

export default OffersPage
