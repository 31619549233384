import AuthenticationService from '../../services/AuthenticationService';import BackendService from '../../services/BackendService';
const baseApiUrl = BackendService.getBasepath() + '/founder/restaurants';

const PhotographService = {
    postPhotograph,
    getPhotographs,
    removePhotograph
}

function getPhotographs(restaurantId) {
    return fetch(restaurantId, {
        method: 'GET',
        headers: {
            "Authorization": `Bearer ${AuthenticationService.curentAccessToken}`
        }
    });
}

function postPhotograph(file,id,restaurantId) {
    const formData = new FormData();
    formData.append(
        "image",
        file,
        file.name
    );
    formData.append('restaurant_eid', restaurantId.id);
    return fetch(`${baseApiUrl}/photos`, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${AuthenticationService.curentAccessToken}`
        },
        body: formData
    });
}

function removePhotograph(id,restaurantId) {
    return fetch(`${baseApiUrl}/photos`, {
        method: 'DELETE',
        headers: {
            "Authorization": `Bearer ${AuthenticationService.curentAccessToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({'photo_eid': id, 'restaurant_eid': restaurantId.id })
    });
}

export default PhotographService