import React from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import ChargeList from './ChargesTo/ChargeList'
import ChargesRestaurantsList from './ChargesTo/ChargesRestaurantsList'

const ChargeToRestaurantspage = () => {
    return (
        <Router>
            <Switch>
                <Route path="/ChargeTo/Restaurant/:restaurantId/:restaurantName/:paymentDate" component={ChargeList}/>
                <Route path="/ChargeTo/:paymentDate" component={ChargesRestaurantsList}/>
                <Route component={ChargesRestaurantsList}/>
            </Switch>
        </Router>
    )
}

export default ChargeToRestaurantspage
