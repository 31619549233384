import React from 'react'

class CheckBoxComponent extends React.Component {
    
    render()
    {
        return (
            <div style={this.props.style}>
                <label className="founderCheckbox" style={{fontFamily: "Roboto"}}>{this.props.text}
                    <input type="checkbox" checked={this.props.checked} onChange={this.props.onChange}/>
                    <span className="checkmark">
                        <img src="/images/checkbox.svg" alt="checkbox" style={{margin:"-1px -1px -1px -1px", display:"none"}} width="20px"></img>
                    </span>
                </label>
            </div>
        )
    }

}

export default CheckBoxComponent