import React from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect, Link} from 'react-router-dom'
import OfferspageList from './bannerspage'
import NewBanner from './NewBanner'
import OffersDetails from './Banners/BannersDetails '



const OffersPage = () => {
    return (
        <Router>
            <Switch>
                <Route path="/Banners/NewBanner" component={NewBanner}/>
                <Route path="/Banners/Details/:id" component={OffersDetails} />

                <Route component={OfferspageList}/>
            </Switch>
        </Router>
    )
}

export default OffersPage
