import React from 'react'
import {useParams, Link } from 'react-router-dom'
import { reduce } from 'rxjs'
import { useMediaQuery } from 'react-responsive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BackgroundInfo from '../components/BackgroundInfo'
import TextBoxComponent from '../components/TextboxComponent'
import ClientsServices from '../services/founder/ClientsServices'

const ClientsPageReservations = () => {

    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total,setTotal] = React.useState(0);
    const [firstRow,setFirstRow] = React.useState(0);
    const [lastRow,setLastRow] = React.useState(0);
    const [lastPage,setLastPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [restaurants, setRestaurants] = React.useState([]);
    const [refreshForce, setRefreshForce] = React.useState([]);
    const [backgrounInfo,setBackgrounInfo] = React.useState("");
    const [order, setOrder] = React.useState("");

    const [clientId] = React.useState(useParams());

    function handleMovePageDown(e){
        if(page > 1){
            setPage(page-1);
            setRefreshForce(Math.random());
        }
        
    }

    function handleMovePageUp(e){
        if(page != lastPage){
            setPage(page+1);
            setRefreshForce(Math.random());
        }
    }

    function handleSearchChange(e){
        setSearch(e.target.value);
    }
    
    function handleRowsChange(e){
        setPage(1);
        setRowsPerPage(e.target.value);
    }

    function handleSearchOnEnter(e){
        setRefreshForce(Math.random());
    }

    function getDate(date) {
        let mont =["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ]
        let data = new Date(date);
        return (data.getDate() >= 10 ? data.getDate():"0" + data.getDate()) + " " + mont[data.getMonth()] + " " + data.getFullYear()
    }

    function getClientsReservation() {
        ClientsServices.getClientsReservation(page,rowsPerPage,clientId.id,order).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setRestaurants(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                }
                else
                {
                    setRestaurants([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setBackgrounInfo("Reservations");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
            
        });
    }

    function getClientsReservationSearch() {
        ClientsServices.getClientsReservationSearchByName(search, clientId.id, rowsPerPage, page, order).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setRestaurants(r.data);
                    setPage(r.data.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                    setLastPage(r.last_page);
                }
                else
                {
                    setRestaurants([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setLastPage(0);
                    setBackgrounInfo("Serch");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
            
        });
    }

    function orderInfo() {
        if(order === "" || order === "desc"){
            setOrder("asc");
        }
        else{
            setOrder("desc");
        }
        setRefreshForce(Math.random());
    }


    React.useEffect(()=>{
        if(search == "")
        {
            getClientsReservation()
        }
        else{
            getClientsReservationSearch()
        }
    },[rowsPerPage,refreshForce]);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = (restaurants) =>(
        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    RESERVACIONES DE {clientId.name.toUpperCase()}
                </label>
            </div>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}>
            </div>
            <div style={{display: "flex", alignItems: "center", padding: "30px 40px"}}>
                    <div style={{position:'relative', width:'320px'}}>
                    <TextBoxComponent onEnter={handleSearchOnEnter} onChange={handleSearchChange} placeholder="Búsqueda por nombre" type="text"/>      
                        <a style={{position:'absolute', right: "5px", top: "18px"}}>
                            <img src="/images/search.svg"/>
                        </a>
                    </div>
            </div>
            <div style={{padding: "14px 40px 29px 40px", overflow: "auto", height: "calc(100% - 303px)",}}>
                    <table className="table table-borderless table-hover" style={{
                        textAlign:'initial',
                        fontFamily:'CommutersSans'}}>
                        <thead>
                            <tr style={{ fontFamily:'Commuters Sans Light', color:'rgba(0, 0, 0, 0.75)'}}>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}} onClick={(e)=>{e.stopPropagation(); orderInfo();}}>RESTAURANTE</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>FECHA DE RESERVACIÓN</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>NÚMERO DE PERSONAS</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>COSTO DE LA CUENTA</th>
                            </tr>
                        </thead>
                        {
                            restaurants.length == 0 ? <tbody style={{display:'table-caption'}}><BackgroundInfo Type={backgrounInfo}/></tbody>:
                            <tbody>
                            {
                                restaurants.map(restaurant =>(
                                    <tr style={{height:'64px', fontFamily: 'Roboto', fontSize: '12px', verticalAlign: 'middle'}}>
                                        <td>{restaurant.name}</td>
                                        <td>{getDate(restaurant.reservation_date)}</td>
                                        <td>{restaurant.people}</td>
                                        <td>${restaurant.bill}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        }
                    </table>
                </div>
                <div className="content-pagination">
                    <div className="content-select">
                        <span className="label">
                            Filas:&nbsp;&nbsp;
                        </span>
                        <select onChange={handleRowsChange} className="pagination-select">
                            <option selected={rowsPerPage===5} value="5">5</option>
                            <option selected={rowsPerPage===25} value="25">25</option>
                            <option selected={rowsPerPage===100} value="100">100</option>
                        </select>
                    </div>
                    <div className="content-select-pagination">
                        <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                        <label className="label">de&nbsp;&nbsp;</label>
                        <label className="number">{total}</label>
                        <button className="button-angle" onClick={handleMovePageDown}>
                            <FontAwesomeIcon icon="angle-left" />
                        </button>
                        <button className="button-angle" onClick={handleMovePageUp}>
                            <FontAwesomeIcon icon="angle-right" />
                        </button>
                    </div>
                </div>
        </article>
    );
    
    const movile = (restaurants) =>(
        <article>
            <a style={{position: "absolute", top:25, left:'5%' }} onClick={()=> window.location = "/Clients/Accepted" }>
                <img width="24" height="24" src="/images/backicon.svg"/>
            </a>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '40%'
                         }}>
                CLIENTES
            </h5>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}></div>
            <div class="list-group">
                {
                    restaurants.length == 0 ? <BackgroundInfo Type={backgrounInfo}/> :
                    restaurants.map( restaurant =>(
                        <a class="list-group-item list-group-item-action">
                            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', margin:'30px'}}>
                                <label style={{
                                        width: "350px",
                                        height: "25px",
                                        fontFamily: "Commuters Sans SemiBold",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.25,
                                        letterSpacing: "normal",
                                        color: "black",
                                        textAlign:"initial"}}>
                                            {restaurant.name}
                                </label>
                                <label>{restaurant.people} personas</label>
                                <label>{getDate(restaurant.reservation_date)}</label>
                                <label>{restaurant.bill}</label>
                            </div>
                        </a>
                    ))
                }
            </div>
        </article>
    );

    return (
       isDesktopOrLaptop ? desktop(restaurants) : movile(restaurants)
    )
}

export default ClientsPageReservations