import AuthenticationService from "../AuthenticationService";
import BackendService from '../../services/BackendService';
const baseApiUrl = BackendService.getBasepath();
class ProfilesService {

    static putChangePassword(model) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                {
                    password : model.password,
                    new_password : model.newPassword,
                    new_password_confirmation : model.confirmPassword
                })
        };

        return fetch(`${baseApiUrl}/founder/profile/password`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                return data
            });
    }

}
export default ProfilesService;