import React from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect, Link} from 'react-router-dom'
import CheckBoxComponent from '../components/CheckBoxComponent'
import DropDowComponent from '../components/DropDowComponent'
import { useMediaQuery } from 'react-responsive'
import PriceComponent from '../components/PriceComponent'
import TextBoxComponent from '../components/TextboxComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BackgroundInfo from '../components/BackgroundInfo'
import RestaurantsService from '../services/founder/RestaurantsServices'
import AuthenticationService from '../services/AuthenticationService';
import { RatingView } from 'react-simple-star-rating'

const RestaurantsList = () => {
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [total,setTotal] = React.useState(0);
    const [firstRow,setFirstRow] = React.useState(0);
    const [lastRow,setLastRow] = React.useState(0);
    const [lastPage,setLastPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [restaurants, setRestaurants] = React.useState([]);
    const [refreshForce, setRefreshForce] = React.useState([]);
    const [backgrounInfo,setBackgrounInfo] = React.useState("");
    const [order, setOrder] = React.useState("");

    function handleMovePageDown(e){
        if(page > 1){
            setPage(page-1);
            setRefreshForce(Math.random());
        }
        
    }

    function handleMovePageUp(e){
        if(page != lastPage){
            setPage(page+1);
            setRefreshForce(Math.random());
        }
    }

    function handleSearchChange(e){
        setSearch(e.target.value);
    }
    
    function handleRowsChange(e){
        setPage(1);
        setRowsPerPage(e.target.value);
    }

    function handleSearchOnEnter(e){
        setRefreshForce(Math.random());
    }

    function getCost(data){
        let number = data.length;
        return number;
    }

    function disableRestaurant(encrypt_id){
        RestaurantsService.putRestaurantStatus(encrypt_id, 0).then((r) =>{
            if(r.message == "Información guardada correctamente")
            {
                if(!isDesktopOrLaptop)
                {

                }
                else{
                }
                setRefreshForce(Math.random());
            }
        });
    }

    function enabledRestaurant(encrypt_id){
        RestaurantsService.putRestaurantStatus(encrypt_id, 1).then((r) =>{
            if(r.message == "Información guardada correctamente")
            {
                if(!isDesktopOrLaptop)
                {
                    
                }
                else{
                }
                setRefreshForce(Math.random());
            }
        });
    }

    function getRestaurantsList() {
        RestaurantsService.getRestaurantsList(page,rowsPerPage,order).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setRestaurants(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                    setLastPage(r.last_page);
                }
                else
                {
                    setRestaurants([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setLastPage(0);
                    setBackgrounInfo("Restaurant");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
            
        });
    }

    function getRestaurantsListSearch() {
        RestaurantsService.getRestaurantsSearch(search,page,rowsPerPage,order).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setRestaurants(r.data);
                    setPage(r.data.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                }
                else
                {
                    setRestaurants([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setBackgrounInfo("Serch");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
            
        });
    }

    function orderInfo() {
        if(order === "" || order === "desc"){
            setOrder("asc");
        }
        else{
            setOrder("desc");
        }
        setRefreshForce(Math.random());
    }

    function openSearch()
    {
        document.getElementById("buttonSearch").style.visibility="visible";
        document.getElementById('inputSearch').style.width="95%";
    }

    function closeSearch()
    {
        document.getElementById("buttonSearch").style.visibility="hidden";
        document.getElementById('inputSearch').value = '';
        document.getElementById('inputSearch').style.width="10%";
        setSearch("");
        getRestaurantsList();
    }

    React.useEffect(()=>{
        if(search == "")
        {
            getRestaurantsList()
        }
        else{
            getRestaurantsListSearch()
        }
    },[rowsPerPage,refreshForce])

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = (restaurants) =>(
        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    RESTAURANTES
                </label>
                <Link to="/Restaurants/NewRestaurant" style={{
                    width:"364px",
                    height: "40px"
                }} className="btn btn-primary">NUEVO RESTAURANTE</Link>
            </div>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}>
            </div>
            <div style={{display: "flex", alignItems: "center", padding: "30px 40px"}}>
                    <div style={{position:'relative', width:'320px'}}>
                        <TextBoxComponent onEnter={handleSearchOnEnter} onChange={handleSearchChange} placeholder="Búsqueda por nombre" type="text"/>
                        <a style={{position:'absolute', right: "5px", top: "18px"}}>
                            <img src="/images/search.svg"/>
                        </a>
                    </div>
            </div>
            <div style={{padding: "14px 40px 29px 40px", overflow: "auto", height: "calc(100% - 303px)",}}>
                    <table className="table table-borderless table-hover" style={{
                        textAlign:'initial',
                        fontFamily:'CommutersSans',
                        tableLayout:'fixed'}}>
                        <thead>
                            <tr style={{ fontFamily:'Commuters Sans Light', color:'rgba(0, 0, 0, 0.5)'}}>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}} onClick={(e)=>{e.stopPropagation(); orderInfo();}}>RESTAURANTE</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>DUEÑO</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>ZONA</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>TIPO DE COMIDA</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>CHEF</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>PRECIO</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>TELÉFONO</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>CORREO</th>
                            </tr>
                        </thead>
                        {
                            restaurants.length == 0 ? <tbody style={{display:'table-caption'}}><BackgroundInfo Type={backgrounInfo}/></tbody>:
                            <tbody>
                            {
                                restaurants.map(restaurant =>(
                                    <tr style={{height:'64px', fontFamily: 'Roboto', fontSize: '12px', verticalAlign:'middle', color: restaurant.active === 0 ? 'rgba(0, 0, 0, 0.4)' : '', whiteSpace:'nowrap'}} onClick={ (e) => { document.location = '/Restaurants/Details/' + restaurant.encrypt_id; e.defaultPrevented(); e.stopPropagation();}}>
                                        <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{restaurant.name} <img src="/images/alert.svg" alt="" style={{height:'24px', width:'24px', marginBottom:'5px'}} hidden={Boolean(restaurant.status_information)}/></td>
                                        <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{restaurant.owner_name}</td>
                                        <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{restaurant.zone}</td>
                                        <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>
                                        {
                                            restaurant.kitchens.map(kitchen =>( kitchen.name + ", "))
                                        }
                                        </td>
                                        <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{restaurant.cheff_name}</td>
                                        <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>
                                            {/* <PriceComponent value={getCost(restaurant.cost)} style={{height:'25px', width:'65px'}} edit={false} color="transparent" /> */}
                                            <RatingView
                                                ratingValue={getCost(restaurant.cost)}
                                                transition
                                                size={20}
                                                stars={5}
                                                fillColor={restaurant.active === 0 ? 'rgba(0, 0, 0, 0.3)' : 'black'}
                                                emptyColor={'transparent'}
                                                >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                                                </svg>
                                            </RatingView>
                                        </td>
                                        <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{restaurant.phone}</td>
                                        <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{restaurant.email}</td>
                                        <td style={{textAlign:"end"}} onClick={(e)=>{e.preventDefault(); e.stopPropagation(); }}><DropDowComponent options={
                                            restaurant.active === 0 ?
                                            [{
                                                onClick: (e) =>{ document.location = '/Restaurants/Details/' + restaurant.encrypt_id; e.defaultPrevented(); e.stopPropagation();},
                                                page: "#",
                                                text: "EDITAR"
                                            },
                                            {
                                                onClick: (e) => {e.preventDefault(); e.stopPropagation(); enabledRestaurant(restaurant.encrypt_id);},
                                                page: "#",
                                                text: "HABILITAR"
                                            }]
                                            :
                                            [{
                                                onClick: (e) =>{ document.location = '/Restaurants/Details/' + restaurant.encrypt_id; e.defaultPrevented(); e.stopPropagation();},
                                                page: "#",
                                                text: "EDITAR"
                                            },
                                            {
                                                onClick: (e) => {e.preventDefault(); e.stopPropagation(); disableRestaurant(restaurant.encrypt_id);},
                                                page: "#",
                                                text: "DESHABILITAR"
                                            }]
                                            }/>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        }
                    </table>
                </div>
                <div className="content-pagination">
                    <div className="content-select">
                        <span className="label">
                            Filas:&nbsp;&nbsp;
                        </span>
                        <select onChange={handleRowsChange} className="pagination-select">
                            <option selected={rowsPerPage===5} value="5">5</option>
                            <option selected={rowsPerPage===25} value="25">25</option>
                            <option selected={rowsPerPage===100} value="100">100</option>
                        </select>
                    </div>
                    <div className="content-select-pagination">
                        <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                        <label className="label">de&nbsp;&nbsp;</label>
                        <label className="number">{total}</label>
                        <button className="button-angle" onClick={handleMovePageDown}>
                            <FontAwesomeIcon icon="angle-left" />
                        </button>
                        <button className="button-angle" onClick={handleMovePageUp}>
                            <FontAwesomeIcon icon="angle-right" />
                        </button>
                    </div>
                </div>
        </article>
    );

    const movile = (restaurants) =>(
        <article>
            <div  class="search-input">
                <input onClick={openSearch} type="search" id="inputSearch" name="search" placeholder="Buscar" onKeyUp={(ev) => {
                    setSearch(ev.target.value);
                    setRefreshForce(Math.random());
                  }}/>
                <button onClick={closeSearch} id="buttonSearch" style={{visibility:"hidden"}} >x</button>
            </div>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '34%'
                         }}>
                RESTAURANTES
            </h5>
            <a style={{position: "absolute", top:15, right:'5%' }} onClick={()=> window.location = "/Restaurants/NewRestaurant"}>
                <img width="40" height="40" src="/images/plus-circle-fill.svg"/>
            </a>
             <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}></div>
            <div class="list-group">
                {
                    restaurants.length == 0 ? <BackgroundInfo Type={backgrounInfo}/> :
                    restaurants.map( restaurant =>(
                        <a class="list-group-item list-group-item-action" onClick={ (e) => { document.location = '/Restaurants/Details/' + restaurant.encrypt_id; e.defaultPrevented(); e.stopPropagation();}}>
                            <div style={{display:'flex', flexDirection:'column' ,width:'100%', marginTop:'30px', marginBottom:'20px'}}>
                                <div style={{
                                    textOverflow:'ellipsis',
                                    whiteSpace:'nowrap',
                                    overflow:'hidden',
                                    textAlign:'initial',
                                    width: "350px",
                                    height: "25px",
                                    fontFamily: "Commuters Sans SemiBold",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.25,
                                    letterSpacing: "normal",
                                    color: restaurant.active === 0 ? 'rgba(0, 0, 0, 0.4)' : "black"}}
                                    onClick={ (e) => { document.location = '/Restaurants/Details/' + restaurant.encrypt_id; e.defaultPrevented(); e.stopPropagation();}}>
                                        {restaurant.name}
                                </div>
                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}} onClick={(e) => {e.preventDefault(); e.stopPropagation();}}>
                                    <div style={{display:'flex', alignItems:'center'}} onClick={ (e) => { document.location = '/Restaurants/Details/' + restaurant.encrypt_id; e.defaultPrevented(); e.stopPropagation();}}>
                                        <div style={{color: restaurant.active === 0 ? 'rgba(0, 0, 0, 0.4)' : '', textOverflow:'ellipsis',whiteSpace:'nowrap', overflow:'hidden', maxWidth:'250px'}}>{restaurant.owner_name}</div>
                                        <span style={{backgroundColor: restaurant.active === 0 ? 'rgba(0, 0, 0, 0.4)' : 'black', height: '6px', width:'6px', borderRadius: '6px', display: 'inline-block', verticalAlign:'middle', marginLeft:'5px', marginRight:'5px'}}>&nbsp;</span>
                                        <div style={{color: restaurant.active === 0 ? 'rgba(0, 0, 0, 0.4)' : ''}}>{restaurant.zone}</div>
                                    </div>
                                    <DropDowComponent options={
                                            restaurant.active === 0 ?
                                            [{
                                                onClick: (e) => {e.preventDefault(); e.stopPropagation(); enabledRestaurant(restaurant.encrypt_id);},
                                                page: "#",
                                                text: "HABILITAR"
                                            }]
                                            :
                                            [{
                                                onClick: (e) => {e.preventDefault(); e.stopPropagation(); disableRestaurant(restaurant.encrypt_id);},
                                                page: "#",
                                                text: "DESHABILITAR"
                                            }]
                                            }/>
                                </div>
                                <div style={{marginLeft:'-3px', textAlign:'initial'}}>
                                    <RatingView
                                        ratingValue={getCost(restaurant.cost)}
                                        transition
                                        size={20}
                                        stars={5}
                                        fillColor={restaurant.active === 0 ? 'rgba(0, 0, 0, 0.3)' : 'black'}
                                        emptyColor={'transparent'}
                                        >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                                        </svg>
                                    </RatingView>
                                    {/* <PriceComponent value={2} style={{height:'25px', width:'135px'}} edit={false} color="transparent" /> */}
                                </div>
                            </div>
                        </a>
                    ))
                }
            </div>
            <div className="content-pagination">
                <div className="content-select">
                    <span className="label">
                        Filas:&nbsp;&nbsp;
                    </span>
                    <select onChange={handleRowsChange} className="pagination-select" defaultValue="5">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className="content-select-pagination">
                    <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                    <label className="label">de&nbsp;&nbsp;</label>
                    <label className="number">{total}</label>
                    <button className="button-angle" onClick={handleMovePageDown}>
                        <FontAwesomeIcon icon="angle-left" />
                    </button>
                    <button className="button-angle" onClick={handleMovePageUp}>
                        <FontAwesomeIcon icon="angle-right" />
                    </button>
                </div>
            </div>
        </article>
    );

    return (
        isDesktopOrLaptop ? desktop(restaurants) : movile(restaurants)
    )
}

export default RestaurantsList
