import React from 'react'

const SwitchComponent = (props) => {

    function onChange(event) {
        props.handle(event.target.checked, props.idRestaurant)
    }


    return (
        <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" 
                style={{width:'48px',
                        height:'24px', 
                        boxShadow:'none'}}
                onChange={onChange}
                checked={props.checked}/>
        </div>
    )
}

export default SwitchComponent
