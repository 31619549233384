import React, { useEffect, useState } from 'react';
import RestaurantsService from '../services/founder/RestaurantsServices';
import '../scss/offers.scss';
import ModalComponent, { ModalFunctions } from '../components/ModalComponent2';
import DropDowComponent from '../components/DropDowComponent'
import OffersService from '../services/founder/OffersService'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/core/styles';
import SwitchComponent from '../components/SwitchComponent'
import { Link } from 'react-router-dom'


const OffersPageList = () => {
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [refreshForce, setRefreshForce] = React.useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showModalOption, setShowModalOption] = useState(false); // Nueva variable de estado
  
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
  },
  title: {
    fontSize: 24,
    color: '#333',
    marginBottom: 10,
  },
  table: {
    maxWidth: '100%',
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: '#f9f9f9',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  tableData: {
    textAlign: 'center',
  },
  // Resto de los estilos necesarios
}));

const SwitchComponent = ({ initialState = false, onStateChange }) => {
  const [isActive, setIsActive] = useState(initialState);

  const handleStateChange = () => {
    const newState = !isActive;
    setIsActive(newState);
    onStateChange(newState);
  };

  return (
    <div className="form-check form-switch">
      <label className={`switch ${isActive ? 'active' : 'inactive'}`}>
        <input  class="form-check-input" type="checkbox" checked={isActive} onChange={handleStateChange}  />
        <span  className="slider round"></span>
      </label>
    </div>
  );
};
const handleSwitchChange = (offer, newState) => {
  // Aquí puedes actualizar el estado del banner en la base de datos
  console.log(`Banner "${offer.id}" is now ${newState ? '1' : '0'}`); 
  const body = {};
  body.activo = newState;
  RestaurantsService.statusBanner(offer.id,body).then((r) =>{
    if(r != null && r.success === 1){
        notify(r.message)
        setTimeout(() => {
            document.location = '/Banners';
        }, 1500);
    }
    else{
       console.log(r.message)
    }
});



}
  useEffect(() => {
    RestaurantsService.listImagen()
      .then(data => {
        console.log(data); // Verifica los datos recibidos en la consola
        setOffers(data);
      })
      .catch(error => {
        console.error('Error al obtener las ofertas:', error);
      });

      
  }, []);

  const openModal = (offer) => {
    setSelectedOffer(offer);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedOffer(null);
    setModalIsOpen(false);
  };
  const handleShowModal = (offer, e) => {
    e.stopPropagation();
    setSelectedOffer(offer);
    setShowModalOption(true); // Establece showModalOption en true al hacer clic en "Mostrar"
    setModalIsOpen(true); // Abre el modal al hacer clic en "Mostrar"
  };

  const handleCloseModalOption = () => {
    setSelectedOffer(null);
    setShowModalOption(false); // Establece la variable de estado showModalOption en false
  };

  const handleCloseModal = () => {
    setSelectedOffer(null);
    setShowModal(false);
  };
  const handleEdit = () => {
    // Aquí puedes agregar la lógica para editar la oferta seleccionada
    console.log('Editar oferta:', selectedOffer);
    setModalIsOpen(true); // Abre el modal al hacer clic en "Editar"
  };
  const handleEdit2 = () => {

    setModalIsOpen(true); // Abre el modal al hacer clic en "Editar"
  };

  function notify() {
    toast.success('Información actualizada con éxito', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
  }


  return (
    <div className="offers-page">
       <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    flexGrow: "1",
                    color: "black"}}>
                    BANNERS
                </label>
                <Link to="/Banners/NewBanner" style={{
                    width:"284px",
                    height: "40px"
                }} className="btn btn-primary" id="btnInvitation">Crear Banner</Link>
            </div>
     
      <table className="offers-table">
        <thead>
          <tr>
            <th>Título</th>
            <th>Descripción</th>
            <th>Estado: Activo/Inactivo</th>
            <th>Posición</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {offers.map(offer => (
            <tr key={offer.id} onClick={() => openModal(offer)}>
              <td>{offer.titulo}</td>
              <td>{offer.descripcion}</td>
              <td  onClick={(e) => { e.stopPropagation(); }}>
              <SwitchComponent
                initialState={offer.activo === 1}
                onStateChange={(newState) => handleSwitchChange(offer, newState)}
                offer={offer}
              />
            </td>
              <td>{offer.posicion}</td>
            
              <td>

            

    <button className="btn btn-primary btn-lg mt-1" type="button"  onClick={() => {   document.location = '/Banners/Details/' + offer.id }}>
                            EDITAR
                        </button>
            </td>
             
            </tr>
          ))}
        </tbody>
      </table>

      {modalIsOpen && selectedOffer && (
        <ModalComponent
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          title={selectedOffer.titulo}
          image={`https://api21.com.do/storage/app/public/${selectedOffer.imagen}`}
          description={selectedOffer.descripcion}
        />
      )}

    </div>
  );
};

export default OffersPageList;