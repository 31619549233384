import AuthenticationService from "../AuthenticationService";
import BackendService from '../../services/BackendService';
const baseApiUrl = BackendService.getBasepath();
class ReportContent{

    static getReportContentLits(page, itemsPerPage) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };
    
        return fetch(`${baseApiUrl}/founder/reported/publications/list/all?page=${page}&pagesize=${itemsPerPage}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }
    
    static getReportContentListSearchByName(name, page, itemsPerPage) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };
    
        return fetch(`${baseApiUrl}/founder/reported/publications/list/search?name=${name}&page=${page}&pagesize=${itemsPerPage}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getReportDetail(clientId, page, itemsPerPage) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/reported/publications/detail/${clientId}?page=${page}&pagesize=${itemsPerPage}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getReportDetailImage(clientId) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/reported/publications/detail/image/${clientId}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getReportDetailClient(clientId) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/reported/publications/detail/client/${clientId}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static deleteContent(clientId)
    {
        const requestOptions = {
            method: 'DELETE',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };
    
        return fetch(`${baseApiUrl}/founder/reported/publications/detail/${clientId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data;
                }
                else
                {
                    return null;
                }
            });
    }
}

export default ReportContent;