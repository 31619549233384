import { faCheckCircle, faEye, faEyeSlash, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, InputBase, TextField } from '@material-ui/core'
import React from 'react'

class TextBoxComponent extends React.Component {
    
    constructor(props){
        super(props);
    }

    render()
    {
        function passAdornment (hasValidate, hasError, type, clickEvent) {
            return {
                endAdornment:
                <InputAdornment position="end">
                    <a onClick={clickEvent} edge="end">
                        { type == 'password' ? <FontAwesomeIcon icon={faEyeSlash}/> : <FontAwesomeIcon icon={faEye}/> }
                    </a>
                  { hasValidate && !hasError ? <img src="/images/validation-success-icon.svg"/> : hasValidate && hasError && <img src="/images/validation-error-icon.svg"/> }
                </InputAdornment>
            }
        }
        function regularAdornment (hasValidate, hasError) {
            return hasValidate && !hasError ?
            {
                endAdornment:
                    <InputAdornment position="end">
                        <img src="/images/validation-success-icon.svg"/>
                    </InputAdornment>,
            } : hasValidate && hasError &&
            {
                endAdornment:
                    <InputAdornment position="end">
                        <img src="/images/validation-error-icon.svg"/>
                    </InputAdornment>,
            }
        }

        return (
            <TextField 
                style={this.props.style}
                fullWidth
                error = { this.props.hasError }
                helperText= { this.props.hasError && this.props.error }
                label= {this.props.placeholder}
                InputProps = { this.props.showEye ? passAdornment(this.props.hasValidate,this.props.hasError,this.props.type,this.props.showEyeClick) : regularAdornment(this.props.hasValidate,this.props.hasError) }
                value={this.props.value}
                variant="standard"
                type={this.props.type || 'text'}
                onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                        if(this.props.onEnter)
                        {
                            this.props.onEnter();
                        }
                        ev.preventDefault();
                    }
                    if(ev.key === ' ' && this.props.NoSpaces) {
                        ev.preventDefault();
                    }
                  }}
                onChange={this.props.onChange}>
            </TextField>
        )
    }
}

export default TextBoxComponent
