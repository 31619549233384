import React from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextBoxComponent from '../components/TextboxComponent'
import BackgroundInfo from '../components/BackgroundInfo'
import ModalComponent, { ModalFunctions } from '../components/ModalComponent'
import ClientsServices from '../services/founder/ClientsServices'
import AuthenticationService from '../services/AuthenticationService'
import ReportsService from '../services/founder/ReportsService'

import Toast from 'react-bootstrap/Toast'
import { toast } from 'react-toastify'

const ClientsPageRejectedList = () => {
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [total,setTotal] = React.useState(0);
    const [firstRow,setFirstRow] = React.useState(0);
    const [lastRow,setLastRow] = React.useState(0);
    const [lastPage,setLastPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [users, setUsers] = React.useState([]);
    const [refreshForce, setRefreshForce] = React.useState([]);
    const [backgrounInfo,setBackgrounInfo] = React.useState("");
    const [order, setOrder] = React.useState("");
    const [photo, setPhoto] = React.useState("");

    function notify() {
        toast.success('Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    function openSearch()
    {
        document.getElementById("buttonSearch").style.visibility="visible";
        document.getElementById('inputSearch').style.width="95%";
    }

    function closeSearch()
    {
        document.getElementById("buttonSearch").style.visibility="hidden";
        document.getElementById('inputSearch').value = '';
        document.getElementById('inputSearch').style.width="10%";
        setSearch("");
        getClientsRejected();
    }

    function handleMovePageDown(e){
        if(page > 1){
            setPage(page-1);
            setRefreshForce(Math.random());
        }
        
    }

    function handleMovePageUp(e){
        if(page != lastPage){
            setPage(page+1);
            setRefreshForce(Math.random());
        }
    }

    function handleSearchChange(e){
        setSearch(e.target.value);
    }
    
    function handleRowsChange(e){
        setPage(1);
        setRowsPerPage(e.target.value);
    }

    function handleSearchOnEnter(e){
        setRefreshForce(Math.random());
    }

    function AcceptClient(encrypt_id){
        ClientsServices.addClientAccept(encrypt_id).then((r) =>{
            if(r != null)
            {
                if(r.message == "Información guardada correctamente")
                {
                    if(!isDesktopOrLaptop)
                    {
                        var element = document.getElementById('accepted');
                        var visible = element.hasAttribute('hidden')
                        if(visible)
                        {
                            Show(element);
                            setTimeout(() => {
                                Hide(element);
                            }, 300);
                        }
                    }
                    else{
                        notify()
                    }
                    document.location.reload();
                }
            }
        });
    }

    function downloadReport(){
        ReportsService.getReportClientRejected()
        .then(response => response.blob())
        .then(blob => {
            let link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "report.xlsx";
            link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
            link.remove();
            window.URL.revokeObjectURL(link.href);
        })
        .catch(err => console.error(err));
        ModalFunctions.ShowModal('modalReportDownload');
    }

    function RejectClient(encrypt_id){
        ClientsServices.addClientDelete(encrypt_id).then((r) =>{
            if(r != null)
            {
                if(r.message == "Información eliminada correctamente")
                {
                    if(!isDesktopOrLaptop)
                    {
                        var element = document.getElementById('rejected');
                        var visible = element.hasAttribute('hidden')
                        if(visible)
                        {
                            Show(element);
                            setTimeout(() => {
                                Hide(element);
                            }, 300);
                        }
                    }
                    else{
                        notify()
                    }
                    document.location.reload();
                }
            }
        });
    }

    function Show(element){
        element.removeAttribute('hidden');
    }
    
    function Hide(element){
        element.setAttribute('hidden',element);
    }

    function getDate(date) {
        let mont =["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ]
        let data = new Date(date);
        return ( (data.getDate() >= 10 ? data.getDate():"0" + data.getDate()) + " " + mont[data.getMonth()] + " " + data.getFullYear() )
    }

    function getClientsRejected() {
        ClientsServices.getClientsRejected(page,rowsPerPage,order).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setUsers(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                    setLastPage(r.last_page);
                }
                else
                {
                    setUsers([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setLastPage(0);
                    setBackgrounInfo("Content");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }

    function getClientsRejectedSearch() {
        ClientsServices.getClientsRejectedSearchByName(search,page,rowsPerPage,order).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setUsers(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                }
                else
                {
                    setUsers([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setBackgrounInfo("Serch");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }

    function orderInfo() {
        if(order === "" || order === "desc"){
            setOrder("asc");
        }
        else{
            setOrder("desc");
        }
        setRefreshForce(Math.random());
    }

    function showProfilePhoto(photo) {
        setPhoto(photo != null ? photo : "/images/profile.jpg")
        ModalFunctions.ShowModal('modalprofile');
    }

    React.useEffect(()=>{
        if(search == "")
        {
            getClientsRejected();
        }
        else{
            getClientsRejectedSearch();
        }
    },[rowsPerPage,refreshForce])

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = (users) =>(
        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    CLIENTES
                </label>
                <Link to="/Clients/SendInvitation" style={{
                    width:"284px",
                    height: "40px"
                }} className="btn btn-primary">ENVIAR INVITACIÓN</Link>
            </div>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "31px",
                textAlign: "left",
                padding: "0 24px"
            }}>
                <Link className="tabitem" to="/Clients">Solicitudes</Link>
                <Link className="tabitem" to="/Clients/Accepted">Aceptados</Link>
                <Link className="tabitem selected" to="/Clients/Rejected">Rechazados</Link>
            </div>
                <div style={{display: "flex", alignItems: "center", padding: "30px 40px"}}>
                    <div style={{position:'relative', width:'320px'}}>
                        <TextBoxComponent onEnter={handleSearchOnEnter} onChange={handleSearchChange} placeholder="Búsqueda por nombre" type="text"/>    
                        <a style={{position:'absolute', right: "5px", top: "18px"}}>
                            <img src="/images/search.svg"/>
                        </a>
                    </div>
                    <div style={{"flex-grow": "1", textAlign:'end'}}>
                        <button className="puerta21-btn" type="button" style={{fontsize: "16px"}} onClick={downloadReport}>DESCARGAR REPORTE</button>
                    </div>
                </div>
            <div style={{padding: "14px 40px 29px 40px", overflow: "auto", height: "calc(100% - 303px)",}}>
                <table className="table table-borderless table-hover" style={{
                    textAlign:'initial',
                    fontFamily:'CommutersSans'}}>
                    <thead>
                        <tr style={{ fontFamily:'Commuters Sans Thin', color:'rgba(0, 0, 0, 0.75)'}}>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}} onClick={(e)=>{e.stopPropagation(); orderInfo();}}>CLIENTE</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>COLABORACIÓN</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>INVITACIÓN</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>FECHA SOLICITUD</th>
                            <th scope="col" colSpan='3' style={{fontSize:'10px', paddingBottom:'32px'}}>REDES SOCIALES</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>ACCIONES</th>
                        </tr>
                    </thead>
                    {
                         users.length == 0 ? <tbody style={{display:'table-caption'}}><BackgroundInfo Type={backgrounInfo}/></tbody>:
                         <tbody>
                        {
                            users.map(user =>(
                                <tr style={{height:'64px', fontFamily: 'Roboto', fontSize: '12px', verticalAlign: 'middle'}}>
                                    <td>
                                        <span style={{backgroundColor:'#6a1415', height: '6px', width:'6px', borderRadius: '3px', display: 'inline-block', verticalAlign:'inherit'}}>&nbsp;</span>
                                        <a onClick={(e)=>{e.stopPropagation(); showProfilePhoto(user.photo)}} style={{cursor:'pointer'}}>
                                            <img src = {
                                                            user.photo != null ? user.photo : "/images/profile.jpg"
                                                    } 
                                                style={{height: '34px',width: '26px',margin:'0 8px',borderTopLeftRadius: '17px',borderTopRightRadius: '17px',border: '0.5px solid rgba(0,0,0,0.4)'}}/>
                                        </a>
                                        <label htmlFor="">{user.first_name} {user.middle_name} {user.last_name}</label>
                                    </td>
                                    <td>{user.collaborator}</td>
                                    <td>
                                    {
                                        user.invitations.map(invitation =>(invitation.first_name + " " + invitation.middle_name + " " + (invitation.last_name === null ? '' : invitation.last_name) + ", "))
                                    }
                                    </td>
                                    <td>{getDate(user.date)}</td>
                                    {
                                        user.social_medias.length == 0 ? 
                                        <td colSpan="3">
                                            Sin redes
                                        </td>
                                        :
                                        <td colSpan="3">
                                        {
                                            user.social_medias.map(socialMedia => (
                                                <a href={'http://' + socialMedia.url} target='_blank'><img src=
                                                {
                                                    socialMedia.name == "Facebook" ? "/images/fb.svg" :
                                                    socialMedia.name == "LinkedIn" ? "/images/lin.svg":
                                                    socialMedia.name == "Instagram" ? "/images/ins.svg":
                                                    ""
                                                } style={{ width: "20px", height: "20px", marginRight:"15px", marginBottom:"2px" }}/></a>
                                            ))
                                        }
                                        </td>
                                    }
                                    <td style={{ maxWidth:'160px' }}>
                                        <div>
                                            <button className="puerta21-btn" style={{marginRight:"24px"}} type="button" onClick={() =>{AcceptClient(user.encrypt_id)}}>APROBAR</button>
                                            <button className="puerta21-btn" type="button" onClick={() =>{RejectClient(user.encrypt_id)}}>ELIMINAR</button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    }
                </table>
            </div>
            <div className="content-pagination">
                <div className="content-select">
                    <span className="label">
                        Filas:&nbsp;&nbsp;
                    </span>
                    <select onChange={handleRowsChange} className="pagination-select">
                        <option selected={rowsPerPage===5} value="5">5</option>
                        <option selected={rowsPerPage===25} value="25">25</option>
                        <option selected={rowsPerPage===100} value="100">100</option>
                    </select>
                </div>
                <div className="content-select-pagination">
                    <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                    <label className="label">de&nbsp;&nbsp;</label>
                    <label className="number">{total}</label>
                    <button className="button-angle" onClick={handleMovePageDown}>
                        <FontAwesomeIcon icon="angle-left" />
                    </button>
                    <button className="button-angle" onClick={handleMovePageUp}>
                        <FontAwesomeIcon icon="angle-right" />
                    </button>
                </div>
            </div>
            <ModalComponent id="modalReportDownload" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <img src="/images/iconoDescargaArchivo.svg" style={{
                                                                    height:'120px', 
                                                                    width:'120px'}}/>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"20px",
                                    color:'gray',
                                    marginTop:'60px'}}>
                                Descargamos tu archivo correctamente
                            </label>
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalReportDownload');}}>CERRAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalprofile" semi2="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', width:'100%'}}>
                            <img src={photo} style={{ maxWidth:'450px', maxHeight:'450px'}}/>
                        </div>
                        <div style={{display:"flex",marginTop:"23px"}}>
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalprofile');}}>CERRAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );

    const movile = (users) =>(
        <article>
            <div  class="search-input">
                <input onClick={openSearch} type="search" id="inputSearch" name="search" placeholder="Buscar" onKeyUp={(ev) => {
                    setSearch(ev.target.value);
                    setRefreshForce(Math.random());
                  }}/>
                <button onClick={closeSearch} id="buttonSearch" style={{visibility:"hidden"}} >x</button>
            </div>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '40%'
                         }}>
                CLIENTES
            </h5>
            <a style={{position: "absolute", top:15, right:'5%' }} onClick={()=> window.location = "/Clients/SendInvitation"}>
                <img width="40" height="40" src="/images/plus-circle-fill.svg"/>
            </a>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "39px",
                textAlign: "center",
                padding: "0px 24px",
                marginTop:"8px"
            }}>
                <Link className="tabitem" to="/Clients" style={{paddingBottom:'16px'}}>SOLICITUDES</Link>
                <Link className="tabitem" to="/Clients/Accepted" style={{paddingBottom:'16px'}}>ACEPTADOS</Link>
                <Link className="tabitem selected" to="/Clients/Rejected" style={{paddingBottom:'16px'}}>RECHAZADOS</Link>
            </div>
            <div class="list-group">
                <a class="list-group-item list-group-item-action" style={{width:'100%', height:'189px', backgroundColor:'#4a8c6e', display:'flex', justifyContent:'center', alignItems:'center'}} id="accepted" hidden>
                    <img src="/images/Accepted.svg" style={{height: '46px',width: '46px'}}/> 
                </a>
                <a class="list-group-item list-group-item-action" style={{width:'100%', height:'189px', backgroundColor:'#6a1415', display:'flex', justifyContent:'center', alignItems:'center'}} id="rejected" hidden>
                    <img src="/images/Rejected.svg" style={{height: '46px',width: '46px'}}/> 
                </a>
                {
                    users.length == 0 ? <BackgroundInfo Type={backgrounInfo}/> :
                    users.map( user =>(
                        <a class="list-group-item list-group-item-action">
                            <div style={{display:'flex'}}>
                                <div style={{marginTop:'19px', marginBottom:'20px'}}>
                                    <a onClick={(e)=>{e.stopPropagation(); showProfilePhoto(user.photo)}} style={{cursor:'pointer'}}>
                                        <img src={
                                                    user.photo != null ? user.photo : "/images/profile.jpg"
                                                 }  style={{height: '100px', width: '80px',margin:'0 8px',borderTopLeftRadius: '50px',borderTopRightRadius: '50px',border: '0.5px solid rgba(0,0,0,0.4)'}}/>
                                    </a>
                                </div>
                                <div style={{display:'flex', textAlign:'initial', marginTop:'20px', marginBottom:'20px', marginLeft:'10px', flexDirection:'column'}}>
                                <div style={{width:'100%', display:'flex', flexWrap:'wrap'}}><label style={{
                                        textAlign:'start',
                                        height: "25px",
                                        fontFamily: "Commuters Sans SemiBold",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.25,
                                        letterSpacing: "normal",
                                        color: "black",
                                        marginRight:'10px'}}>{user.first_name}</label>
                                    <label style={{
                                        textAlign:'start',
                                        height: "25px",
                                        fontFamily: "Commuters Sans SemiBold",
                                        fontSize: "18px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.25,
                                        letterSpacing: "normal",
                                        color: "black",
                                        marginRight:'10px'}}>{user.middle_name}</label>
                                    <label style={{
                                        textAlign:'start',
                                        height: "25px",
                                        fontFamily: "Commuters Sans SemiBold",
                                        fontSize: "18px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.25,
                                        letterSpacing: "normal",
                                        color: "black"}}>{user.last_name}</label>
                                    </div>
                                    <div style={{display:'flex', paddingTop:'10px', paddingBottom:'10px', flexDirection:'row'}}>
                                    {
                                        user.social_medias.length == 0 ? 
                                        <div className="col-6">
                                            Sin redes
                                        </div>
                                        :
                                        user.social_medias.map(socialMedia =>(
                                            <div className="col-2">
                                                <a href={'http://' + socialMedia.url} target='_blank'><img src=
                                                {
                                                    socialMedia.name == "Facebook" ? "/images/fb.svg" :
                                                    socialMedia.name == "LinkedIn" ? "/images/lin.svg":
                                                    socialMedia.name == "Instagram" ? "/images/ins.svg":
                                                    ""
                                                } style={{ width: "16px", height: "15.9px", marginRight:"8px", marginBottom:"2px" }}/></a>
                                            </div>
                                        ))
                                    }
                                    </div>
                                    <div style={{display:'flex', flexDirection:'column'}}>
                                        {
                                            user.collaborator !== null ? <label style={{fontSize:'12px'}}>Colaboración {user.collaborator}</label> : <label></label>
                                        }
                                        {
                                            user.invitations.length !== 0 ? 
                                            <label style={{fontSize:'12px'}}>Invitación de 
                                            {
                                                user.invitations.map(invitation =>(" " + invitation.first_name + " " + invitation.middle_name + " " + (invitation.last_name === null ? '' : invitation.last_name) + ", "))
                                            }
                                            </label>
                                            :
                                            <label></label>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div style={{textAlign:"right", height:"65px", marginTop:"10px",borderBottom:"1px solid rgba(0,0,0,.125)",}}>
                                <button className="puerta21-btn" style={{height:'15px', fontSize:'15px',marginTop:"20px"}} type="button" onClick={() =>{AcceptClient(user.encrypt_id)}}>APROBAR</button>
                                <button className="puerta21-btn" style={{height:'15px', fontSize:'15px',marginTop:"20px"}} type="button" onClick={() =>{RejectClient(user.encrypt_id)}}>RECHAZAR</button>
                            </div>
                        </a>
                    ))
                }
            </div>
            <div className="content-pagination">
                <div className="content-select">
                    <span className="label">
                        Filas:&nbsp;&nbsp;
                    </span>
                    <select onChange={handleRowsChange} className="pagination-select" defaultValue="5">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className="content-select-pagination">
                    <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                    <label className="label">de&nbsp;&nbsp;</label>
                    <label className="number">{total}</label>
                    <button className="button-angle" onClick={handleMovePageDown}>
                        <FontAwesomeIcon icon="angle-left" />
                    </button>
                    <button className="button-angle" onClick={handleMovePageUp}>
                        <FontAwesomeIcon icon="angle-right" />
                    </button>
                </div>
            </div>
            <ModalComponent id="modalprofile" semi2="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', width:'100%'}}>
                            <img src={photo} style={{ maxWidth:'320px', maxHeight:'320px'}}/>
                        </div>
                        <div style={{display:"flex",marginTop:"23px"}}>
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalprofile');}}>CERRAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );

    return (
        isDesktopOrLaptop ? desktop(users) : movile(users)
     )
        
}




export default ClientsPageRejectedList
