import React from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalComponent, { ModalFunctions } from '../components/ModalComponent'
import DropDowComponent from '../components/DropDowComponent'
import TextBoxComponent from '../components/TextboxComponent'
import BackgroundInfo from '../components/BackgroundInfo'
//import offers.scss from scss/offers.scss
import '../scss/offers.scss'
import OffersService from '../services/founder/OffersService'
import AuthenticationService from '../services/AuthenticationService'
import RestaurantsService from '../services/founder/RestaurantsServices'
import ReportsService from '../services/founder/ReportsService'

import Toast from 'react-bootstrap/Toast'
import { toast, ToastContainer } from 'react-toastify'
import { CallReceived } from '@material-ui/icons'
import { useEffect } from 'react';

const OffersPageList = () => {
    
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [total,setTotal] = React.useState(0);
    const [firstRow,setFirstRow] = React.useState(0);
    const [lastRow,setLastRow] = React.useState(0);
    const [lastPage,setLastPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [refreshForce, setRefreshForce] = React.useState([]);
    const [backgrounInfo,setBackgrounInfo] = React.useState("");
    const [order, setOrder] = React.useState("");
    const [photo, setPhoto] = React.useState("");
    //OFFERS
    const [offers, setOffers] = React.useState([]);
    //restaurant
    const [restaurants, setRestaurants] = React.useState([]);
    
      


    //title
    const [title, setTitle] = React.useState("");
    function getRestaurantsList() {
        RestaurantsService.getRestaurantsList(page,rowsPerPage,order).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setRestaurants(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                    setLastPage(r.last_page);
                }
                else
                {
                    setRestaurants([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setLastPage(0);
                    setBackgrounInfo("Restaurant");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
            
        });
    }
    function notify() {
        toast.success('Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    function openSearch()
    {
        document.getElementById("buttonSearch").style.visibility="visible";
        document.getElementById('inputSearch').style.width="95%";
    }

    function closeSearch()
    {
        document.getElementById("buttonSearch").style.visibility="hidden";
        document.getElementById('inputSearch').value = '';
        document.getElementById('inputSearch').style.width="10%";
        setSearch("");
        getListClientApplication();
    }

    function handleMovePageDown(e){
        if(page > 1){
            setPage(page-1);
            setRefreshForce(Math.random());
        }
        
    }
    function DeleteOffer(id){
        alert(id)
    }

    function handleMovePageUp(e){
        if(page != lastPage){
            setPage(page+1);
            setRefreshForce(Math.random());
        }
    }

    function handleSearchChange(e){
        setSearch(e.target.value);
    }
    
    function handleRowsChange(e){
        setPage(1);
        setRowsPerPage(e.target.value);
    }

    function handleSearchOnEnter(e){
        setRefreshForce(Math.random());
    }

    function AcceptClient(encrypt_id){
        OffersService.addClientAccept(encrypt_id).then((r) =>{
            if(r.message == "Información guardada correctamente")
            {
                if(!isDesktopOrLaptop)
                {
                    var element = document.getElementById('accepted');
                    var visible = element.hasAttribute('hidden')
                    if(visible)
                    {
                        Show(element);
                        setTimeout(() => {
                            Hide(element);
                        }, 300);
                    }
                }
                else{
                    notify()
                }
                setRefreshForce(Math.random());
            }
        });
    }
  
    function handleDeleteClick(id) {
        OffersService.addClientDelete(id)
          .then((response) => {
            // Realiza cualquier acción necesaria después de la eliminación exitosa
            console.log('Oferta eliminada:', response);
            setRefreshForce(Math.random());
            notify();
          })
          .catch((error) => {
            // Maneja cualquier error que ocurra durante la eliminación
            console.error('Error al eliminar la oferta:', error);
          });
      }

    function deleteOffers(id, body) {
        OffersService.deleteOffers(id, body)
          .then((response) => {
            // Realiza cualquier acción necesaria después de la eliminación exitosa
            console.log('Oferta eliminada:', response);
            // Actualiza el estado u otras variables según sea necesario
            setRefreshForce(Math.random());
            notify();
          })
          .catch((error) => {
            // Maneja cualquier error que ocurra durante la eliminación
            console.error('Error al eliminar la oferta:', error);
          });
      }

      
    function RejectClient(encrypt_id){
        OffersService.addClientReject(encrypt_id).then((r) =>{
            if(r.message == "Información guardada correctamente")
            {
                if(!isDesktopOrLaptop)
                {
                    var element = document.getElementById('rejected');
                    var visible = element.hasAttribute('hidden')
                    if(visible)
                    {
                        Show(element);
                        setTimeout(() => {
                            Hide(element);
                        }, 300);
                    }
                }
                else{
                    notify()
                }
                setRefreshForce(Math.random());
            }
        });

    }

    function Show(element){
        element.removeAttribute('hidden');
    }
    
    function Hide(element){
        element.setAttribute('hidden',element);
    }

    function getDate(date) {
        let mont =["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ]
        let data = new Date(date);
        return ( (data.getDate() >= 10 ? data.getDate():"0" + data.getDate()) + " " + mont[data.getMonth()] + " " + data.getFullYear() )
    }

    function downloadReport(){
        ReportsService.getReportClientApplication()
        .then(response => response.blob())
        .then(blob => {
            let link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "report.xlsx";
            link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
            link.remove();
            window.URL.revokeObjectURL(link.href);
        })
        .catch(err => console.error(err));
        ModalFunctions.ShowModal('modalReportDownload');
    }

    function orderInfo() {
        if(order === "" || order === "desc"){
            setOrder("asc");
        }
        else{
            setOrder("desc");
        }
        setRefreshForce(Math.random());
    }

    function getListClientApplication() {
        OffersService.getOffersApplication(page,rowsPerPage,order).then((r) =>{
            console.log("SEXOOO");
            console.log(r.data);

            if(r != null)
            {
                if(r.length != 0)
                {
                    var array = [];
                    for (var i = 0; i < r.data.length; i++) {
                        var obj = {
                            id: r.data[i].id,
                            restaurant_id: r.data[i].restaurant_id,
                            start_date: r.data[i].start_date,
                            end_date: r.data[i].end_date,
                            dates: r.data[i].dates,
                            title: r.data[i].title,
                            description: r.data[i].description,
                            type: r.data[i].type,
                            repeats: r.data[i].repeats,
                            sunday: r.data[i].sunday,
                            monday: r.data[i].monday,
                            tuesday: r.data[i].tuesday,
                            wednesday: r.data[i].wednesday,
                            thursday: r.data[i].thursday,
                            friday: r.data[i].friday,
                            saturday: r.data[i].saturday,
                            source: r.data[i].source,
                            created_at: r.data[i].created_at,
                            updated_at: r.data[i].updated_at,
                        };
                        array.push(obj);
                    }
                    
                    setOffers(array);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                    setLastPage(r.last_page);
                }
                else
                {
                    setOffers([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setLastPage(0);
                    setBackgrounInfo("Content");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }
    const renderdates = (dates) => {

        const datesarray = [];
        //fill fates array with da
    
        const parsedjson = JSON.parse(dates);
    
        for (let i = 0; i < parsedjson.length; i += 1) {
          const date = parsedjson[i];
          datesarray.push(date);
        } 
     
        console.log(datesarray);
        return <>
          {datesarray.map((date) => (
            <span className='blackbadge'>
              {date}
            </span>
          ))} 
        </>
      };
    function getListOffersApplicationSearch() {
        OffersService.getOffersApplicationSearchByName(search,page,rowsPerPage,order).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setOffers(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                }
                else
                {
                    setOffers([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setBackgrounInfo("Serch");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }

    function showProfilePhoto(photo) {
        setPhoto(photo != null ? photo : "/images/profile.jpg")
        ModalFunctions.ShowModal('modalprofile');
    }

    React.useEffect(()=>{
        if(search == "")
        {
            getListClientApplication();
        }
        else{
            getListOffersApplicationSearch();
        }
    },[rowsPerPage,refreshForce])

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = (offers) =>(

        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    flexGrow: "1",
                    color: "black"}}>
                    OFERTAS
                </label>
                <Link to="/offers/NewOffer" style={{
                    width:"284px",
                    height: "40px"
                }} className="btn btn-primary" id="btnInvitation">Crear Oferta</Link>
            </div>
            <div style={{display: "flex", alignItems: "center", padding: "30px 40px"}}>
                <div style={{position:'relative', width:'320px'}}>
                    <TextBoxComponent onEnter={handleSearchOnEnter} onChange={handleSearchChange} placeholder="Búsqueda por nombre" type="text"/>  
                    <a style={{position:'absolute', right: "5px", top: "18px"}}>
                        <img src="/images/search.svg"/>
                    </a>
                </div>
            </div>
            <div style={{padding: "14px 40px 29px 40px", overflow: "auto", height: "calc(100% - 303px)",}}>
                <table className="table table-borderless table-hover" style={{
                    textAlign:'initial',
                    fontFamily:'CommutersSans'}}>
                    <thead>
                        <tr style={{ fontFamily:'Commuters Sans Thin', color:'rgba(0, 0, 0, 0.75)'}}>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}} onClick={(e)=>{e.stopPropagation(); orderInfo();}}>#</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>NOMBRE RESTAURANT</th>

                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>TITULO</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>DESCRIPCIÓN</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>TIPO</th>
                            <th scope="col"  style={{fontSize:'10px', paddingBottom:'32px'}}>FECHA</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>ACCIONES</th>
                        </tr>
                    </thead>
                    {
                        <tbody>
                        {
                            offers.map(offer =>(
                                <tr style={{height:'64px', fontFamily: 'Roboto', fontSize: '12px', verticalAlign: 'middle'}}>
                                   
                                    <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{offer.id}</td>
                                    <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{restaurants.name}</td>

                                    <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{offer.title}</td>
                                    <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{offer.description}</td>
                                    <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{offer.type}</td>
                                    <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{(offer.repeats)?(getDate(offer.start_date)+' - '+ getDate(offer.end_date)): renderdates(offer.dates) }</td>
                           
                                            <td style={{ textAlign: "end" }}>
                                                <DropDowComponent
                                                    options={[
                                                    {
                                                        onClick: (e) => {
                                                        document.location = '/offers/Details/' + offer.id;
                                                        e.defaultPrevented();
                                                        e.stopPropagation();
                                                        },
                                                        page: "#",
                                                        text: "EDITAR",
                                                    },
                                                    {
                                                        onClick: (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        handleDeleteClick(offer.id);
                                                        },
                                                        page: "#",
                                                        text: "Eliminar",
                                                    },
                                                    ]}
                                                />
                                            </td>
                                
                                </tr>
                            ))
                        }
                        </tbody>
                    }
                </table>
                {
                    (offers.length == 0) ? <BackgroundInfo Type={backgrounInfo}/> : ""
                }
            </div>
            <div className="content-pagination">
                <div className="content-select">
                    <span className="label">
                        Filas:&nbsp;&nbsp;
                    </span>
                    <select onChange={handleRowsChange} className="pagination-select" defaultValue="5">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className="content-select-pagination">
                    <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                    <label className="label">de&nbsp;&nbsp;</label>
                    <label className="number">{total}</label>
                    <button className="button-angle" onClick={handleMovePageDown}>
                        <FontAwesomeIcon icon="angle-left" />
                    </button>
                    <button className="button-angle" onClick={handleMovePageUp}>
                        <FontAwesomeIcon icon="angle-right" />
                    </button>
                </div>
            </div>
            <ModalComponent id="modalReportDownload" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <img src="/images/iconoDescargaArchivo.svg" style={{
                                                                    height:'120px', 
                                                                    width:'120px'}}/>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"20px",
                                    color:'gray',
                                    marginTop:'60px'}}>
                                Descargamos tu archivo correctamente
                            </label>
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalReportDownload');}}>CERRAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalprofile" semi2="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', width:'100%'}}>
                            <img src={photo} style={{ maxWidth:'450px', maxHeight:'450px'}}/>
                        </div>
                        <div style={{display:"flex",marginTop:"23px"}}>
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalprofile');}}>CERRAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );

    const movile = (offers) => (
        <article>
        <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
            <label style={{
                width: "111px",
                height: "25px",
                fontFamily: "Commuters Sans SemiBold",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.25,
                letterSpacing: "normal",
                textAlign: "left",
                flexGrow: "1",
                color: "black"}}>
                OFERTAS
            </label>
            <Link to="/offers/NewOffer" style={{
                width:"284px",
                height: "40px"
            }} className="btn btn-primary" id="btnInvitation">Crear Oferta</Link>
        </div>
        <div style={{display: "flex", alignItems: "center", padding: "30px 40px"}}>
            <div style={{position:'relative', width:'320px'}}>
                <TextBoxComponent onEnter={handleSearchOnEnter} onChange={handleSearchChange} placeholder="Búsqueda por nombre" type="text"/>  
                <a style={{position:'absolute', right: "5px", top: "18px"}}>
                    <img src="/images/search.svg"/>
                </a>
            </div>
        </div>
        <div style={{padding: "14px 40px 29px 40px", overflow: "auto", height: "calc(100% - 303px)",}}>
            <table className="table table-borderless table-hover" style={{
                textAlign:'initial',
                fontFamily:'CommutersSans'}}>
                <thead>
                    <tr style={{ fontFamily:'Commuters Sans Thin', color:'rgba(0, 0, 0, 0.75)'}}>
                        <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}} onClick={(e)=>{e.stopPropagation(); orderInfo();}}>#</th>
                        <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>NOMBRE RESTAURANT</th>

                        <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>TITULO</th>
                        <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>DESCRIPCIÓN</th>
                        <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>TIPO</th>
                        <th scope="col"  style={{fontSize:'10px', paddingBottom:'32px'}}>FECHA</th>
                        <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>ACCIONES</th>
                    </tr>
                </thead>
                {
                    <tbody>
                    {
                        offers.map(offer =>(
                            <tr style={{height:'64px', fontFamily: 'Roboto', fontSize: '12px', verticalAlign: 'middle'}}>
                               
                                <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{offer.id}</td>
                                <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{restaurants.name}</td>

                                <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{offer.title}</td>
                                <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{offer.description}</td>
                                <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{offer.type}</td>
                                <td style={{textOverflow:'ellipsis', overflow:'hidden'}}>{(offer.repeats)?(getDate(offer.start_date)+' - '+ getDate(offer.end_date)): renderdates(offer.dates) }</td>
                       
                                        <td style={{ textAlign: "end" }}>
                                            <DropDowComponent
                                                options={[
                                                {
                                                    onClick: (e) => {
                                                    document.location = '/offers/Details/' + offer.id;
                                                    e.defaultPrevented();
                                                    e.stopPropagation();
                                                    },
                                                    page: "#",
                                                    text: "EDITAR",
                                                },
                                                {
                                                    onClick: (e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleDeleteClick(offer.id);
                                                    },
                                                    page: "#",
                                                    text: "Eliminar",
                                                },
                                                ]}
                                            />
                                        </td>
                            
                            </tr>
                        ))
                    }
                    </tbody>
                }
            </table>
            {
                (offers.length == 0) ? <BackgroundInfo Type={backgrounInfo}/> : ""
            }
        </div>
        <div className="content-pagination">
            <div className="content-select">
                <span className="label">
                    Filas:&nbsp;&nbsp;
                </span>
                <select onChange={handleRowsChange} className="pagination-select" defaultValue="5">
                    <option value="5">5</option>
                    <option value="25">25</option>
                    <option value="100">100</option>
                </select>
            </div>
            <div className="content-select-pagination">
                <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                <label className="label">de&nbsp;&nbsp;</label>
                <label className="number">{total}</label>
                <button className="button-angle" onClick={handleMovePageDown}>
                    <FontAwesomeIcon icon="angle-left" />
                </button>
                <button className="button-angle" onClick={handleMovePageUp}>
                    <FontAwesomeIcon icon="angle-right" />
                </button>
            </div>
        </div>
        <ModalComponent id="modalReportDownload" semi="1">
                <article style={{padding:"23px"}}>
                    <div style={{display: "flex", alignItems:"center", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                        <img src="/images/iconoDescargaArchivo.svg" style={{
                                                                height:'120px', 
                                                                width:'120px'}}/>
                        <label style={{
                                fontFamily:"Roboto-Light",
                                fontSize:"20px",
                                color:'gray',
                                marginTop:'60px'}}>
                            Descargamos tu archivo correctamente
                        </label>
                    </div>
                    <div style={{display:"flex",marginTop:"115px"}}>
                    <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalReportDownload');}}>CERRAR</button>
                    </div>
                </article>
        </ModalComponent>
        <ModalComponent id="modalprofile" semi2="1">
                <article style={{padding:"23px"}}>
                    <div style={{display: "flex", alignItems:"center", flexDirection:'column', width:'100%'}}>
                        <img src={photo} style={{ maxWidth:'450px', maxHeight:'450px'}}/>
                    </div>
                    <div style={{display:"flex",marginTop:"23px"}}>
                    <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalprofile');}}>CERRAR</button>
                    </div>
                </article>
        </ModalComponent>
    </article>
    );

    return (
       isDesktopOrLaptop ? desktop(offers) : movile(offers)
    )
}


export default OffersPageList
