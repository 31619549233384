import React from 'react'
import { Link } from 'react-router-dom'
import CheckBoxComponent from '../components/CheckBoxComponent'
import { useMediaQuery } from 'react-responsive'
import PriceComponent from '../components/PriceComponent'
import TextBoxComponent from '../components/TextboxComponent'
import CatalogsService from '../services/app/CatalogsService'
import AuthenticationService from '../services/AuthenticationService';
import RestaurantsService from '../services/founder/RestaurantsServices'

import DatePicker from "react-multi-date-picker"
import gregorianes from "react-date-object/locales/gregorian_es";
import { format } from 'date-fns'
import es from 'date-fns/locale/es';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

import 'bootstrap/dist/css/bootstrap.min.css'
import {
    KeyboardTimePicker,
    KeyboardDatePicker,

} from '@material-ui/pickers';
import {
    Button,
    Box,
    Container,
    Grid,
    TextField,
    CircularProgress,
    Hidden,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    NativeSelect,
    Checkbox,
    InputLabel,
    Typography
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';

import OffersService from '../services/founder/OffersService'
import Select from 'react-select';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';
import { toast } from 'react-toastify'
import { Rating } from 'react-simple-star-rating'
import IsSpecialCharacters from '../services/UltiService'

const Newoffer = () => {
    const jsToken = JSON.parse(AuthenticationService.curentUserValue);
    const data = jsToken.accessToken;

    const [offerNameValidation, setofferNameValidation] = React.useState({});
    const [emailValidation, setEmailValidation] = React.useState({});
    const [addresValidation, setAddresValidation] = React.useState(true);
    const [directionValidation, setDirectionValidation] = React.useState(true);
    const [zoneValidation, setZoneValidation] = React.useState(true);
    const [kitchensValidation, setKitchensValidation] = React.useState(true);
    const [token, setToken] = React.useState(data);

    const [offerName, setofferName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [direction, setDirection] = React.useState("");
    const [zone, setZone] = React.useState("");
    const [kitchens, setKitchens] = React.useState([]);
    const [cost, setCost] = React.useState("");
    const [extraServices, setExtraServices] = React.useState([]);
    const [latitude, setLatitude] = React.useState(0);
    const [longitude, setLongitude] = React.useState(0);
    const [costsCatalog, setCostsCatalog] = React.useState([]);
    const [extraServicesCatalog, setExtraServicesCatalog] = React.useState([]);
    const [repeat, setRepeat] = React.useState(false);
    const [hasOffers, setHasOffers] = React.useState(false);
    const [values, setValues] = React.useState([])
    const [startDate, setStartDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [isCalendarStartDateOpen, setIsCalendarStartDateOpen] = React.useState(false);
    const [isCalendarEndDateOpen, setIsCalendarEndDateOpen] = React.useState(false);
    const [restaurants, setRestaurants] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(500);
    //const options
    const [options, setOptions] = React.useState([]);
    //selected restaurants
    const [selectedRestaurants, setSelectedRestaurants] = React.useState([]);

    const [order, setOrder] = React.useState("");

    const [fields, setFields] = React.useState({
        title: '',
        description: '',
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        type: 'oferta',
    });

    const [repeatDays, setRepeatDays] = React.useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    });
    const updateFields = (field, value) => {
        const newFields = {
            ...fields,
        };
        newFields[field] = value;
        setFields(newFields);
    };

    function notify(message) {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

      function error(message) {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    const handleDateStartDateChange = (date) => {
        setStartDate(date);
        const target = format(new Date(date), 'yyyy-MM-dd');
        updateFields('startDate', target);

        // if (dateRegex.test(date)) {
        // }    //validatedates(target, fields.startTime, fields.endDate, fields.endTime);
    };
    const handleDateEndDateChange = (date) => {
        setEndDate(date);
        // if (dateRegex.test(date)) {
        // }
        const target = format(new Date(date), 'yyyy-MM-dd');
        updateFields('endDate', target);
        //validatedates(fields.startDate, fields.startTime, target, fields.endTime);
    };

    const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    function getRestaurantsList() {
        console.log("getRestaurantsList");
        RestaurantsService.getRestaurantsList(page,rowsPerPage,order).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setRestaurants(r.data);
                    r.data.map((item) => {
                        options.push({ value: item.encrypt_id, label: item.name });
                    });
                    console.log(r.data);
                }
                else
                {
               
                }
            }
            else
            {
            }
            
        });
    }

    const renderView = () => (
        <>
            <div className="w-100 mx-2">
                <div className="RestaurantOffer-form-container mr-2">
                    <div style={{ color: 'black', fontFamily: 'roboto', fontSize: '16px' }}>
                        <span>
                            <b>Detalles de la oferta</b>
                            <br />
                        </span>
                    </div>
                    <div className="RestaurantOffer__form">
                        <div className="w100 mb-1">
                            <TextField
                                className="w100"
                                id="title"
                                label="Título"
                                onChange={(e) => updateFields('title', e.target.value)}
                                value={fields.title}
                            />
                        </div>
                        <div className="w100 mb-1">
                            <TextField
                                className="w100"
                                id="description"
                                label="Descripción"
                                onChange={(e) => updateFields('description', e.target.value)}
                                value={fields.description}
                            />
                        </div>
                        <div className="w100 mb-1">
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                        Tipo de oferta
                                    </InputLabel>
                                    <NativeSelect
                                        defaultValue={fields.type}
                                        inputProps={{
                                            name: 'type',
                                            id: 'uncontrolled-native',
                                        }}
                                    >
                                        <option value="offer">Oferta</option>
                                        <option value="promotion">Musica en Vivo</option>

                                    </NativeSelect>
                                </FormControl>
                            </Box>
                        </div>
                        <div className="w-100 mb-2">
                            <div>
                                <h6><b>Configuración del restaurante</b></h6>
                                <p>Selecciona la lista de los restaurantes donde se promocionará la oferta</p>
                            </div>
                            <Select
                                options={options}
                                isMulti
                                placeholder="Selecciona los restaurantes"
                                value={selectedRestaurants}
                                onChange={(e) => { setSelectedRestaurants(e); }}

                            />

                        </div>
                        <div>
                        <h6><b>Rango de fechas</b></h6>
                            <div>Selecciona las fechas en la que se promocionarà la oferta</div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                                <div className="mb-2">
                                    <DatePicker
                                        multiple
                                        value={values}
                                        onChange={setValues}
                                        locale={gregorianes}
                                    /> </div>
                            </MuiPickersUtilsProvider>

                        </div>
                        <div className="w100 mb-1">
                            <div className="RestaurantOffer__form__checkbox">
                                <div>
                                    <FormControlLabel
                                        key={uuid()}
                                        label="Repetir oferta"
                                        control={(
                                            <Checkbox
                                                checked={repeat}
                                                name={'repeat'}
                                                disableRipple
                                                color="primary"
                                                onChange={() => { setRepeat(!repeat); }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>Selecciona el rango de fechas donde se repetira la oferta los dias que escojas</div>
                        <div className="row">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                                <div className="w100">
                                    <Grid container>
                                        <Grid item xs={12} md={5}>
                                            <KeyboardDatePicker
                                                onClick={() => { setIsCalendarStartDateOpen(true); }}
                                                onClose={() => setIsCalendarStartDateOpen(false)}
                                                open={isCalendarStartDateOpen}
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="Fecha de inicio de la oferta"
                                                invalidLabel='Fecha invalida'
                                                invalidDateMessage="No es un formato válido"
                                                value={startDate}
                                                onChange={handleDateStartDateChange}
                                                //header disables
                                                disableToolbar
                                                autoOk
                                            >
                                            </KeyboardDatePicker>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <KeyboardDatePicker
                                                onClick={() => { setIsCalendarEndDateOpen(true); }}
                                                onClose={() => setIsCalendarEndDateOpen(false)}
                                                open={isCalendarEndDateOpen}
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="Fecha de finalización de la oferta"
                                                value={endDate}
                                                onChange={handleDateEndDateChange}
                                                //header disables
                                                disableToolbar
                                                autoOk
                                            >
                                            </KeyboardDatePicker>
                                        </Grid>
                                    </Grid>
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="weekdays-holder">
                            <div className='mt-2'>
                                Selecciona los dias que se repetirá la oferta
                            </div>
                            <div className="weekdays">
                                <FormControlLabel
                                    key={uuid()}
                                    label="Lunes"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.monday}
                                            name={'monday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, monday: !repeatDays.monday }); }}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    key={uuid()}
                                    label="Martes"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.tuesday}
                                            name={'tuesday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, tuesday: !repeatDays.tuesday }); }}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    key={uuid()}
                                    label="Miércoles"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.wednesday}
                                            name={'wednesday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, wednesday: !repeatDays.wednesday }); }}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    key={uuid()}
                                    label="Jueves"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.thursday}
                                            name={'thursday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, thursday: !repeatDays.thursday }); }}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    key={uuid()}
                                    label="Viernes"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.friday}
                                            name={'friday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, friday: !repeatDays.friday }); }}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    key={uuid()}
                                    label="Sábado"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.saturday}
                                            name={'saturday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, saturday: !repeatDays.saturday }); }}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    key={uuid()}
                                    label="Domingo"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.sunday}
                                            name={'sunday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, sunday: !repeatDays.sunday }); }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );





    function handleValet(e) {
        if (e.target.checked) {
            let valet = extraServicesCatalog.find(x => x.name === "Valet parking");
            extraServices.push(valet.encrypt_id);
        }
        else {
            let valet = extraServicesCatalog.find(x => x.name === "Valet parking");
            let temp = extraServices.filter(x => x !== valet.encrypt_id);
            setExtraServices(temp);
        }
    }


    function addNewoffer() {
        if (fields.title === '') {
            error('El título no puede estar vacío');
   
            return;
          }
          if (fields.description === '') {
            error('La descripción no puede estar vacía');
            return;
          }
          let numberhasoffers = 0;
          if (hasOffers) {
            numberhasoffers = 1;
          }
          // validate there is at least one restaurant selected
            if (selectedRestaurants.length === 0) {
                error('Debes seleccionar al menos un restaurante');
                return;
            }

          const body = {};
          body.hasOffers = numberhasoffers;
          body.title = fields.title;
          body.description = fields.description;
          body.startDate = fields.startDate;
          body.endDate = fields.endDate;
          body.type = fields.type;
          const parseddates = [];
          // if values is empty, then we add the startDat
          if (values) {
            // use foreach to iterate over the values array
            values.forEach((date) => {
              // format the date to the correct format
              const parseddate = format(new Date(date), 'yyyy-MM-dd');
              // push the parsed date to the parseddates array
              parseddates.push(parseddate);
            });
            console.log('parseddates');
            console.log(parseddates);
          }
          const jsonVariable = JSON.stringify(parseddates);
      
          body.dates = jsonVariable;
          body.repeat = repeat;
          body.monday = repeatDays.monday;
          body.tuesday = repeatDays.tuesday;
          body.wednesday = repeatDays.wednesday;
          body.thursday = repeatDays.thursday;
          body.friday = repeatDays.friday;
          body.saturday = repeatDays.saturday;
          body.sunday = repeatDays.sunday;
            body.restaurants = selectedRestaurants;
          console.log(body);
          OffersService.postNewOffer(body).then((r) =>{
            if(r != null && r.success == 1){
                notify(r.message)
                setTimeout(() => {
                    document.location = '/Offers';
                }, 1500);
            }
            else{
                error(r.message)
            }
        });
   

    }

    function notify(message) {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
    }

    function error(message) {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
    }






    React.useEffect(() => {
        getRestaurantsList();
    }, []);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = () => (
        <article>
            <div style={{ display: "flex", alignItems: "center", padding: "29px 40px" }}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"
                }}>
                    NUEVA OFERTA
                </label>
            </div>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}>
            </div>
            <div style={{ display: "flex", padding: "29px 36px 29px 40px", height: 'calc(100% - 150px)', overflow: 'auto' }}>
                <div style={{ display: "flex", width: '30%', justifyContent: 'space-between' }}>
                    <div style={{ textAlign: "left" }}>
                        <p style={{
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            color: 'black',
                            letterSpacing: '0.5px',
                            width: '80%',
                            paddingTop: '40px'
                        }}>
                            Completa los datos solicitados para agregar una oferta.
                        </p>
                    </div>
                    <div style={{
                        width: "0px",
                        height: "630px",
                        margin: "56px 0px 70px",
                        opacity: "0.15",
                        border: "solid 0.5px #000001",
                        paddingLeft: "0px",
                        paddingRight: "0px"
                    }}>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '70%' }}>
                    <form style={{
                        textAlign: "left",
                        paddingLeft: "40px",
                        width: '100%'
                    }}>
                        <div className="W100">
                            {renderView()}
                        </div>

                        <div className="d-grid gap-2 d-md-flex justify-content-md-end" style={{ paddingTop: '20px' }}>
                            <Link to="/" className="btn btn-lg mt-5" style={{ width: '162px', boxShadow: 'none', fontFamily: 'Commuters Sans SemiBold' }}>
                                CANCELAR
                            </Link>
                            <button className="btn btn-primary btn-lg mt-5" type="button" style={{ width: '162px' }} onClick={() => { addNewoffer() }}>
                                AGREGAR
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </article>



    );

    const movile = () => (
        <article style={{ overflowX: 'hidden' }}>
            <a style={{ position: "absolute", top: 25, left: '5%' }} onClick={() => window.location = "/offers"}>
                <img width="24" height="24" src="/images/backicon.svg" />
            </a>
            <h5 style={{
                "text-transform": "uppercase",
                fontFamily: 'Commuters Sans SemiBold',
                fontSize: '16px',
                fontWeight: '600',
                position: 'absolute',
                top: 30,
                left: '30%'
            }}>
                NUEVA OFERTA
            </h5>
            <label style={{
                color: 'black',
                display: 'block',
                fontfamily: 'Roboto-Light',
                fontsize: '14px',
                fontweight: '300',
                fontstretch: 'normal',
                fontstyle: 'normal',
                lineheight: '1.14',
                letterspacing: '0.35px',
                textAlign: "left",
                margin: '30px'
            }}>
                Completa los datos solicitados para agregar un oferta.
            </label>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}>
            </div>
            <div className="w100">
                GJH
            </div>

            <div style={{ display: 'flex', width: '100%', marginTop: '3px' }}>
                <label style={{ color: '#f44336', fontSize: '0.75rem', fontFamily: 'Roboto' }} hidden={zoneValidation}>Campo requerido</label>
            </div>
            <div style={{ width: '100%', marginTop: '30px' }}>
                <button className="btn btn-primary btn-lg mt-5" type="button" style={{ width: '100%', height: '40px' }} onClick={() => { addNewoffer() }}>
                    AGREGAR
                </button>
            </div>
        </article>

    );

    return (
        isDesktopOrLaptop ? desktop() : movile()
    )
}


export default Newoffer
