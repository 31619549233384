import React from 'react'
import { Link } from 'react-router-dom'
import CheckBoxComponent from '../components/CheckBoxComponent'
import { useMediaQuery } from 'react-responsive'
import TextBoxComponent from '../components/TextboxComponent'
import ClientsServices from '../services/founder/ClientsServices'
import IsSpecialCharacters from '../services/UltiService';
import { toast } from 'react-toastify'

const SendClientInvitation = () => {
    const[nameValidation,setNameValidation] = React.useState({});
    const[firstLastNameValidation,setFirtsLastNameValidation] = React.useState({});
    const[secondLastNameValidation,setSecondLastNameValidation] = React.useState({});
    const[phoneValidation,setPhoneValidation]= React.useState({});
    const[emailValidation,setEmailValidation]= React.useState({});
    const[colabValidation,setColabValidation]= React.useState({});

    const[name,setName] = React.useState("");
    const[firstLastName,setFirtsLastName] = React.useState("");
    const[secondLastName,setSecondLastName] = React.useState("");
    const[phone,setPhone]= React.useState("");
    const[email,setEmail]= React.useState("");
    const[colab,setColab]= React.useState("");
    const[friend,setFriend] = React.useState(false);

    function error(message) {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
    }

    function handleNameChange(e){
        setName(e.target.value);
        if(e.target.value == "" || e.target.value.includes('  ') || IsSpecialCharacters(e.target.value)){
            setNameValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else if(e.target.value.length > 30){
            setNameValidation({hasValidate:true,hasError:true,error:'Máximo 30 caracteres.'});
        }
        else{
            setNameValidation({hasValidate:true,hasError:false});
        }
    }

    function handleFirtsLastNameChange(e){
        setFirtsLastName(e.target.value);
        if(e.target.value == "" || e.target.value.includes('  ') || IsSpecialCharacters(e.target.value)){
            setFirtsLastNameValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else if(e.target.value.length > 30){
            setFirtsLastNameValidation({hasValidate:true,hasError:true,error:'Máximo 30 caracteres.'});
        }
        else{
            setFirtsLastNameValidation({hasValidate:true,hasError:false})
        }
    }
    
    function handleSecondLastNameChange(e){
        setSecondLastName(e.target.value);
        if(e.target.value.includes('  ') || IsSpecialCharacters(e.target.value)){
            setSecondLastNameValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else if(e.target.value.length > 30){
            setSecondLastNameValidation({hasValidate:true,hasError:true,error:'Máximo 30 caracteres.'});
        }
        else{
            setSecondLastNameValidation({hasValidate:true,hasError:false})
        }
    }

    function handlePhoneChange(e){
        const emailRegex = /(^(\d{8,13})$)|^['+'](\d{8,13})$/;
        setPhone(e.target.value);
        if(!emailRegex.test(e.target.value)){
            setPhoneValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else{
            setPhoneValidation({hasValidate:true,hasError:false})
        }
    }

    function handleEmailChange(e){
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        setEmail(e.target.value);
        if(!emailRegex.test(String(e.target.value).toLowerCase()))
        {
            setEmailValidation({hasValidate:true,hasError:true,error:'El formato del correo no es correcto.'})
            return;
        }
        else if(e.target.value.length > 80){
            setEmailValidation({hasValidate:true,hasError:true,error:'Máximo 80 caracteres.'});
        }
        else
        {
            setEmailValidation({hasValidate:true,hasError:false})
        }
    }

    function handleColabChange(e){
        setColab(e.target.value);
        if(e.target.value == "" || e.target.value.includes('  ') || IsSpecialCharacters(e.target.value)){
            setColabValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else if(e.target.value.length > 50){
            setColabValidation({hasValidate:true,hasError:true,error:'Máximo 50 caracteres.'});
        }
        else{
            setColabValidation({hasValidate:true,hasError:false})
        }
    }

    function handleFriend(e) {
        setFriend(e.target.checked)
    }

    function AcceptClient(){

        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }

        if(nameValidation.hasError == undefined)
        {
            setNameValidation({hasValidate:true,hasError:true,error:'Campo requerido'});
        }

        if(firstLastNameValidation.hasError == undefined)
        {
            setFirtsLastNameValidation({hasValidate:true,hasError:true,error:'Campo requerido'});
        }

        if(emailValidation.hasError == undefined)
        {
            setEmailValidation({hasValidate:true,hasError:true,error:'Campo requerido'});
        }

        if(phoneValidation.hasError == undefined)
        {
            setPhoneValidation({hasValidate:true,hasError:true,error:'Campo requerido'});
        }

        if( !nameValidation.hasError && 
            !firstLastNameValidation.hasError &&
            !secondLastNameValidation.hasError && 
            !emailValidation.hasError &&
            !phoneValidation.hasError
          )
        {
            let model = {};
            model.Email = email;
            model.Name = name;
            model.Lastname = firstLastName;
            model.SecondLastname = secondLastName;
            model.CellPhone = phone;
            model.colab = colab;
            model.friend = Number(friend);
            ClientsServices.sendInvitation(model).then((r) =>{

                console.log(r);

                if(r.data != null && r.data.email[0] != undefined && r.data.email[0] == 'The email has already been taken.'){
                    setEmailValidation({hasValidate:true,hasError:true,error:'El correo ya está registrado'});
                }
                if(r.message == "Información guardada correctamente")
                {
                    toast.success('Información actualizada con éxito', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored"
                    });
                    setTimeout(() => {
                        document.location = '/Clients/Accepted';
                    }, 1500);
                }
            });
        }
    }

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = () =>(
        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    INVITAR CLIENTE
                </label>
            </div>
            <div style={{
                    borderBottom: "2px solid #c8c8c8",
                    height: "0px",
                    textAlign: "left",
                    padding: "0 24px"}}>
            </div>
            <div style={{display: "flex", padding: "0 40px 29px 40px", height:'calc(100% - 150px)', overflow:'auto'}}>
                <div style={{width:"100%", display:'flex', justifyContent:'space-between'}}>
                    <div className="col-5" style={{textAlign: "left"}}>
                        <p style={{
                                    fontFamily:'Roboto',
                                    fontSize:'15px',
                                    color:'black',
                                    letterSpacing:'0.5px',
                                    width:'50%',
                                    paddingTop:'113px'}}>
                            Escribe la información de la persona para que reciba su invitación a Puerta 21.
                        </p>
                    </div>
                    <div style={{
                        width: "0px",
                        height: "499px",
                        margin: "56px 0px 70px",
                        opacity: "0.15",
                        border: "solid 0.5px #000001",
                        paddingLeft:"0px",
                        paddingRight:"0px"
                    }}>
    
                    </div>
                    <div className="col-6">
                        <form style={{
                            textAlign: "left",
                            paddingLeft: "40px"
                        }}>
                            <div style={{paddingTop:'113px'}}>
                                <TextBoxComponent placeholder="Nombre(s)" type="text" {...nameValidation} onChange={handleNameChange}/>
                            </div>
                            <div className="row" style={{paddingTop:'40px'}}>
                                <div className="col-6">
                                    <TextBoxComponent placeholder="Primer apellido" type="text" {...firstLastNameValidation} onChange={handleFirtsLastNameChange}/>
                                </div>
                                <div className="col-6">
                                    <TextBoxComponent placeholder="Segundo apellido" type="text" {...secondLastNameValidation} onChange={handleSecondLastNameChange}/>
                                </div>
                            </div>
                            <div className="row" style={{paddingTop:'40px'}}>
                                <div className="col-6">
                                    <TextBoxComponent placeholder="Teléfono" type="text" {...phoneValidation} onChange={handlePhoneChange}/>
                                </div>
                                <div className="col-6">
                                    <TextBoxComponent placeholder="Correo electrónico" type="text" {...emailValidation} onChange={handleEmailChange}/>
                                </div>
                            </div>
                            <div style={{paddingTop:'40px'}}>
                                <TextBoxComponent placeholder="Colaboración" type="text" {...colabValidation} onChange={handleColabChange}/>
                            </div>
                            <CheckBoxComponent style={{marginTop: "30px"}} text="¿Este usuario es tu amigo?" value={friend} onChange={handleFriend}/>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end" style={{paddingTop:'60px'}}>
                                <Link to="/" className="btn btn-lg mt-5" style={{width:'162px', boxShadow:'none'}}>
                                    CANCELAR
                                </Link>
                                <button className="btn btn-primary btn-lg mt-5" type="button" style={{width:'264px'}} onClick={() => {AcceptClient()}}>
                                    ENVIAR INVITACIÓN
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </article>
    );
    
    const movile = () =>(
        <article style={{overflowX:'hidden'}}>
            <a style={{position: "absolute", top:25, left:'5%' }} onClick={()=> window.location = "/Clients" }>
                <img width="24" height="24" src="/images/backicon.svg"/>
            </a>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '30%'
                         }}>
                INVITAR CLIENTE
            </h5>
             <label style={{
                            color:'black',
                            display:'block',
                            height: '64px',
                            fontfamily: 'Roboto-Light',
                            fontsize: '14px',
                            fontweight: '300',
                            fontstretch: 'normal',
                            fontstyle: 'normal',
                            lineheight: '1.14',
                            letterspacing: '0.35px',
                            textAlign: "left",
                            margin: '30px 30px 15px 30px'}}>
                    Escribe la información de la persona para que reciba su invitación a Puerta 21.
            </label>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"}}>
            </div>
            <div style={{display:'flex', flexDirection:'column', alignItems: "center", padding: "19px 30px 19px 33px"}}>
                <TextBoxComponent placeholder="Nombre(s)" type="text" {...nameValidation} onChange={handleNameChange}/>
                <div style={{display:'flex', width:"100%"}}>
                    <div style={{marginRight:'12px', width:'100%'}}>
                        <TextBoxComponent placeholder="Primer apellido" type="text" {...firstLastNameValidation} onChange={handleFirtsLastNameChange}/>
                    </div>
                    <div style={{marginLeft:'12px', width:'100%'}}>
                        <TextBoxComponent placeholder="Segundo apellido" type="text" {...secondLastNameValidation} onChange={handleSecondLastNameChange}/>
                    </div>
                </div>
                <TextBoxComponent placeholder="Teléfono" type="text" {...phoneValidation} onChange={handlePhoneChange}/>
                <TextBoxComponent placeholder="Correo electrónico" type="text" {...emailValidation} onChange={handleEmailChange}/>
                <TextBoxComponent placeholder="Colaboración" type="text" {...colabValidation} onChange={handleColabChange}/>
                <div style={{display:'flex', alignItems:'flex-start', marginTop:'36px', width:'100%'}}>
                    <CheckBoxComponent text="¿Este usuario es tu amigo?" value={friend} onChange={handleFriend}/>
                </div>
                <div style={{marginTop:'36px', width:'100%'}}>
                    <button className="btn btn-block btn-primary" type="button" style={{height:'40px',width:"100%"}} onClick={() => {AcceptClient()}}>
                        ENVIAR INVITACIÓN
                    </button>
                </div>
                <div style={{marginTop:'16px', width:'100%'}}>
                    <Link to="/" className="btn btn-link regularLink" style={{height:'40px', boxShadow:'none'}}>
                        CANCELAR
                    </Link>
                </div>
            </div>
        </article>
    );

    return (
        isDesktopOrLaptop ?  desktop() : movile()
    )
}

export default SendClientInvitation
