import AuthenticationService from "../AuthenticationService";
import BackendService from '../BackendService';
import { Router } from 'react-router-dom';


const baseApiUrl = BackendService.getBasepath();
class OffersService {
    
    static postNewOffer(model) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',  "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(model)
        };

        return fetch(`${baseApiUrl}/founder/offers`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }
    static getOffersApplication(page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/Offers`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static editOffer(id, updatedFields) {
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
          body: JSON.stringify(updatedFields)
        };
      
        return fetch(`${baseApiUrl}/founder/offers/edit/${id}`, requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data.success == 1) {
              return data;
            } else {
              return null;
            }
          });
      }

      static getOfferById(id) {
        const requestOptions = {
          method: 'GET',
          headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };
        return fetch(`${baseApiUrl}/founder/offers/details/${id}`, requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data.success == 1) {
              return data.data;
            } else {
              return null;
            }
          });
      }
    

    static addClientDelete(idUser) {
        const requestOptions = {
            method: 'DELETE',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };
    
        return fetch(`${baseApiUrl}/founder/Offers/delete/${idUser}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success == 1) {
                    return data;
                } else {
                    return null;
                }
            });
    }

    static getOffersApplicationSearchByName(name, page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/Offers/application/search?name=${name}&page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getOffersRejected(page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/Offers/rejected/all?page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getOffersRejectedSearchByName(name, page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/Offers/rejected/search?name=${name}&page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getOffersAccepted(page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/Offers/accepted/all?page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getOffersAcceptedSearchByName(name, page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/Offers/accepted/search?name=${name}&page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static addClientAccept(idUser)
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify({ client_eid : idUser })
        };
    
        return fetch(`${baseApiUrl}/founder/Offers/accept`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data;
                }
                else
                {
                    return null;
                }
            });
    }

    static addClientReject(idUser)
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify({ client_eid : idUser })
        };
    
        return fetch(`${baseApiUrl}/founder/Offers/reject`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data;
                }
                else
                {
                    return null;
                }
            });
    }

    static addClientDelete(idUser)
    {
        const requestOptions = {
            method: 'DELETE',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };
    
        return fetch(`${baseApiUrl}/founder/Offers/delete/${idUser}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data;
                }
                else
                {
                    return null;
                }
            });
    }

    static sendInvitation(model)
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                { 
                    email : model.Email,
                    name : model.Name,
                    first_lastname : model.Lastname,
                    second_lastname : model.SecondLastname,
                    cell_phone : model.CellPhone,
                    collaborator : model.colab,
                    friend : model.friend
                })
        };
    
        return fetch(`${baseApiUrl}/founder/invitation`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data;
                }
                else
                {
                    return null;
                }
            });
    }

    static addClientFriend(idUser)
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
            { 
                client_eid : idUser, 
                status : 1 
            })
        };
    
        return fetch(`${baseApiUrl}/founder/Offers/friend`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data;
                }
                else
                {
                    return null;
                }
            });
    }

    static getOffersReservation(page, itemsPerPage, clientId, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/Offers/reservation/list/${clientId}/all?page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getOffersReservationSearchByName(name, clientId, itemsPerPage, page, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/Offers/reservation/list/${clientId}/search?name=${name}&page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static putOffersStatus(clientId, status) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                { 
                    client_eid : clientId, 
                    status : status 
                })
        };

        return fetch(`${baseApiUrl}/founder/Offers/active`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }


}
export default OffersService;