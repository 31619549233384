import React from 'react';

const FieldEditable = (props) => {
    return (
        <div style={props.style}>
            <div style={{
                fontFamily:"Roboto",
                fontSize:"14px",
                color:'#808080',
                fontWeight:"300",
                textAlign:"left"}}>
                    {props.text}
            </div>
            <div style={{
                borderBottom:"0.5px solid #818181",
                padding:"16px 0"}}>
                <div style={{
                    display:"inline-block",
                    width:"calc(100% - 80px)",
                    textAlign:"left",
                    paddingRight:"16px",
                    wordBreak:"break-word",
                    fontFamily:"Roboto"}}>
                        {props.value}</div>
                <div style={{
                    display:"inline-block",
                    width:"80px",
                    float:"right"}}>
                    <button className="btn btn-primary"
                            style={{ 
                                width:"80px", 
                                height:"24px", 
                                fontSize:"12px", 
                                textAlign:"center", 
                                paddingBottom:"0px",
                                paddingTop:"0px",
                                paddingLeft:"0px",
                                paddingRight:"0px", 
                                boxShadow:"none"}}
                            onClick={props.onClick}>
                        EDITAR
                    </button>
                </div>
            </div>
            
        </div>
        // <div style={{
        //         display:'flex',
        //         flexDirection:'column',
        //         alignItems:'flex-start',
        //         width:'100%',
        //         marginBottom:'22px'}}>
        //     <label style={{
        //         fontFamily:"Roboto-Light",
        //         fontSize:"14px",
        //         color:'gray'}}>
        //             {props.text}
        //     </label>
        //     <div style={{
        //         minHeight:'65px',
        //         paddingRight:'100px',
        //         textOverflow:'ellipsis',
        //         overflow:'auto',
        //         borderBottomColor:'rgba(200, 200, 200)'}} type="text" className={"puerta21-textbox col-12 d-block"}>{props.value}</div>
        //     <button className="btn btn-primary"
        //             style={{position:'relative', 
        //                     left: 'calc(100% - 84px)', 
        //                     bottom: 95, 
        //                     width:"80px", 
        //                     height:"24px", 
        //                     fontSize:"12px", 
        //                     textAlign:"center", 
        //                     paddingBottom:"0px",
        //                     paddingTop:"0px",
        //                     paddingLeft:"0px",
        //                     paddingRight:"0px", 
        //                     boxShadow:"none"}}
        //             onClick={props.onClick}>
        //         EDITAR
        //     </button>
        // </div>
    )
}

export default FieldEditable
