import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import CheckBoxComponent from '../../components/CheckBoxComponent'
import TimePicker from '../../components/timepicker'
import RestaurantsService from '../../services/founder/RestaurantsServices'
import CatalogsService from '../../services/app/CatalogsService'
import { toast } from 'react-toastify'
import { split } from 'lodash'

const RestaurantHours = () => {

    const model ={
        id: "",
        name: "",
        description: {
            description: "",
            phone: "",
            cheff_name: ""
        },
        menu: "",
        address: {
            id: "",
            latitude: 0,
            longitude: 0,
            address: ""
        },
        cost: {
            id: "",
            name: ""
        },
        kitchens: [],
        extra_services: [],
        photos: [],
        capacity: [],
        schedule: []
    }

    const daymodel = {id:"", opening: "09:00:00", closing: "12:00:00", status: 0, day :{}}

    const [restaurantId] = React.useState(useParams());
    const [restaurantInfo, setRestaurantInfo] = React.useState(model);
    const [daysCatalog, setDaysCatalog] = React.useState([]);


    const [domingo, setDomingo] = React.useState(daymodel);
    const [domingoCheck, setDomingoCheck] = React.useState(false);
    const [lunes, setLunes] = React.useState(daymodel);
    const [lunesCheck, setLunesCheck] = React.useState(false);
    const [martes, setMartes] = React.useState(daymodel);
    const [martesCheck, setMartesCheck] = React.useState(false);
    const [miercoles, setMiercoles] = React.useState(daymodel);
    const [miercolesCheck, setMiercolesCheck] = React.useState(false);
    const [jueves, setJueves] = React.useState(daymodel);
    const [juevesCheck, setJuevesCheck] = React.useState(false);
    const [viernes, setViernes] = React.useState(daymodel);
    const [viernesCheck, setViernesCheck] = React.useState(false);
    const [sabado, setSabado] = React.useState(daymodel);
    const [sabadoCheck, setSabadoCheck] = React.useState(false);

    const [change, setChange] = React.useState(false);




    function getRestaurantInfo(){
        RestaurantsService.getRestaurant(restaurantId.id).then((r) => {
            if(r != null)
            {
                setRestaurantInfo(r);
                let temp = r.schedule.find(x => x.day.name === "Domingo");
                setDomingo(temp === undefined ? daymodel : temp)
                setDomingoCheck(temp === undefined ? false : Boolean(temp.status))
                temp = r.schedule.find(x => x.day.name === "Lunes");
                setLunes(temp === undefined ? daymodel : temp)
                setLunesCheck(temp === undefined ? false : Boolean(temp.status))
                temp = r.schedule.find(x => x.day.name === "Martes");
                setMartes(temp === undefined ? daymodel : temp)
                setMartesCheck(temp === undefined ? false : Boolean(temp.status))
                temp = r.schedule.find(x => x.day.name === "Miércoles");
                setMiercoles(temp === undefined ? daymodel : temp)
                setMiercolesCheck(temp === undefined ? false : Boolean(temp.status))
                temp = r.schedule.find(x => x.day.name === "Jueves");
                setJueves(temp === undefined ? daymodel : temp)
                setJuevesCheck(temp === undefined ? false : Boolean(temp.status))
                temp = r.schedule.find(x => x.day.name === "Viernes");
                setViernes(temp === undefined ? daymodel : temp)
                setViernesCheck(temp === undefined ? false : Boolean(temp.status))
                temp = r.schedule.find(x => x.day.name === "Sábado");
                setSabado(temp === undefined ? daymodel : temp)
                setSabadoCheck(temp === undefined ? false : Boolean(temp.status))
            }
            else
            {

            }
        });
    }

    function getDaysCatalog(){
        CatalogsService.getDays().then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setDaysCatalog(r);
                }
            }
        });
    }

    function handleDomingoCheck(e) {
        setDomingoCheck(e.target.checked)
        setChange(true);
    }

    function handleLunesCheck(e) {
        setLunesCheck(e.target.checked)
        setChange(true);
    }

    function handleMartesCheck(e) {
        setMartesCheck(e.target.checked)
        setChange(true);
    }

    function handleMiercolesCheck(e) {
        setMiercolesCheck(e.target.checked)
        setChange(true);
    }

    function handleJuevesCheck(e) {
        setJuevesCheck(e.target.checked)
        setChange(true);
    }

    function handleViernesCheck(e) {
        setViernesCheck(e.target.checked)
        setChange(true);
    }

    function handleSabadoCheck(e) {
        setSabadoCheck(e.target.checked)
        setChange(true);
    }

    function putServiceDay() {
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(change){
            let model = {};
            let temp = {};
            model.id = restaurantId.id;
            model.days = [];
            if(domingoCheck)
            {
                temp = daysCatalog.find(x => x.name === "Domingo");
                model.days.push({day_eid: temp.encrypt_id, opening: domingo.opening, closing: domingo.closing, status : true});
            }
            else
            {
                temp = daysCatalog.find(x => x.name === "Domingo");
                model.days.push({day_eid: temp.encrypt_id, opening: domingo.opening, closing: domingo.closing, status : false});
            }

            if(lunesCheck)
            {
                temp = daysCatalog.find(x => x.name === "Lunes");
                model.days.push({day_eid: temp.encrypt_id, opening: lunes.opening, closing: lunes.closing, status : true});
            }
            else 
            {
                temp = daysCatalog.find(x => x.name === "Lunes");
                model.days.push({day_eid: temp.encrypt_id, opening: lunes.opening, closing: lunes.closing, status : false});
            }

            if(martesCheck)
            {
                temp = daysCatalog.find(x => x.name === "Martes");
                model.days.push({day_eid: temp.encrypt_id, opening: martes.opening, closing: martes.closing, status : true});
            }
            else
            {
                temp = daysCatalog.find(x => x.name === "Martes");
                model.days.push({day_eid: temp.encrypt_id, opening: martes.opening, closing: martes.closing, status : false});
            }

            if(miercolesCheck)
            {
                temp = daysCatalog.find(x => x.name === "Miércoles");
                model.days.push({day_eid: temp.encrypt_id, opening: miercoles.opening, closing: miercoles.closing, status : true});
            }
            else
            {
                temp = daysCatalog.find(x => x.name === "Miércoles");
                model.days.push({day_eid: temp.encrypt_id, opening: miercoles.opening, closing: miercoles.closing, status : false});
            }

            if(juevesCheck)
            {
                temp = daysCatalog.find(x => x.name === "Jueves");
                model.days.push({day_eid: temp.encrypt_id, opening: jueves.opening, closing: jueves.closing, status : true});
            }
            else
            {
                temp = daysCatalog.find(x => x.name === "Jueves");
                model.days.push({day_eid: temp.encrypt_id, opening: jueves.opening, closing: jueves.closing, status : false});
            }

            if(viernesCheck)
            {
                temp = daysCatalog.find(x => x.name === "Viernes");
                model.days.push({day_eid: temp.encrypt_id, opening: viernes.opening, closing: viernes.closing, status : true});
            }
            else
            {
                temp = daysCatalog.find(x => x.name === "Viernes");
                model.days.push({day_eid: temp.encrypt_id, opening: viernes.opening, closing: viernes.closing, status : false});
            }

            if(sabadoCheck)
            {
                temp = daysCatalog.find(x => x.name === "Sábado");
                model.days.push({day_eid: temp.encrypt_id, opening: sabado.opening, closing: sabado.closing, status : true});
            }
            else
            {
                temp = daysCatalog.find(x => x.name === "Sábado");
                model.days.push({day_eid: temp.encrypt_id, opening: sabado.opening, closing: sabado.closing, status : false});
            }
            
            RestaurantsService.putServiceDay(model).then(r => {
                r.success === 1 ? 
                notify()
                :
                error()
                setChange(false)
            });

        }
    }

    function validHour (cierre, apertura) {
        let hCierre = cierre.split(":",1)
        let hApertura = apertura.split(":",1)
        let nCierre = Number(hCierre)
        let nApertura = Number(hApertura)+3
        return nApertura <= nCierre ? true : false;
    }

    function setCierreDefault (apertura) {
        let temp = "";
        let hApertura = apertura.split(":",1)
        let hComplemeto = apertura.slice(2)
        let nApertura = Number(hApertura)
        if(nApertura > 21){
            switch(nApertura){
                case 22:
                    nApertura = 1;
                    break;
                case 23:
                    nApertura = 2;
                    break;
                case 24:
                    nApertura = 3;
                    break;
            }
        }
        else
        {
            nApertura += 3;
        }
        if(nApertura < 10){
            temp = "0"+String(nApertura)
        }
        else{
            temp = String(nApertura);
        }
        return temp+hComplemeto;
    }

    function notify() {
        toast.success('Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

      function error(message) {
        toast.error(message === undefined ? 'No se pudo actualizar' : message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    React.useEffect(()=>{
        getRestaurantInfo()
        getDaysCatalog()
    },[]);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = () =>(
        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    RESTAURANTE
                </label>
            </div>
            <div style={{
                borderBottom: "1px solid rgba(200, 200, 200)",
                height: "31px",
                textAlign: "left",
                padding: "0 24px"
            }}>
                    <Link className="tabitem" to={`/Restaurants/Details/${restaurantId.id}`}>DESCRIPCIÓN</Link>
                    <Link className="tabitem" to={`/Restaurants/Photographs/${restaurantId.id}`}>FOTOGRAFÍAS</Link>
                    <Link className="tabitem" to={`/Restaurants/Capacity/${restaurantId.id}`}>CAPACIDAD</Link>
                    <Link className="tabitem" to={`/Restaurants/Menu/${restaurantId.id}`}>MENÚ</Link>
                    <Link className="tabitem selected" to={`/Restaurants/Hours/${restaurantId.id}`}>HORARIO</Link>
            </div>
            <div className="row" style={{display: "flex", padding: "29px 40px", height:'calc(100% - 150px)', overflow:'auto'}}>
                <div className="col-5" style={{textAlign: "left"}}>
                    <label style={{
                                    width: "358px",
                                    height: "25px",
                                    fontFamily: "Commuters Sans SemiBold",
                                    fontSize: "16px",
                                    fontWeight: 300,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.25,
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    "flex-grow": "1",
                                    color: "black",
                                    margin: '41px 57px 20px 0px'}}>
                        HORARIO DE ATENCIÓN
                    </label>
                    <label style={{
                                color:'black',
                                width: '450px',
                                height: '64px',
                                margin: '20px 58px 0px 0Px',
                                fontfamily: 'Roboto-Light',
                                fontsize: '14px',
                                fontweight: '300',
                                fontstretch: 'normal',
                                fontstyle: 'normal',
                                lineheight: '1.14',
                                letterspacing: '0.35px'}}>
                        Elige los días y el horario en que estarás brindando atención a los comensales.
                    </label>
                </div>
                <div style={{
                        width: "0px",
                        height: "600px",
                        margin: "45px 0px 70px",
                        opacity: "0.15",
                        border: "solid 0.5px rgba(200, 200, 200)",
                        paddingLeft:"0px",
                        paddingRight:"0px"
                    }}>
    
                </div>
                <div className="col-6" style={{marginTop:'105px', marginLeft:'48px',textAlign:'initial'}}>
                    <div className="row" style={{paddingBottom:'32px'}}>
                        <div className="col-6">
                            <label style={{ fontFamily:'Commuters Sans Light', color:'rgba(0, 0, 0, 0.5)', fontSize:'12px'}}>DÍA</label>
                        </div>
                        <div className="col-3">
                            <label style={{ fontFamily:'Commuters Sans Light', color:'rgba(0, 0, 0, 0.5)', fontSize:'12px'}}>APERTURA</label>
                        </div>
                        <div className="col-3">
                            <label style={{ fontFamily:'Commuters Sans Light', color:'rgba(0, 0, 0, 0.5)', fontSize:'12px'}}>CIERRRE</label>
                        </div>
                    </div>
                    <div className="row" style={{paddingBottom:'18px'}}>
                        <div className="col-6">
                            <CheckBoxComponent checked={domingoCheck} onChange={handleDomingoCheck} text="Domingo"/>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={domingo.opening}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                style={{margin:'0px'}}
                                active = {domingoCheck}
                                onSet={ (val) => {
                                    let valid = validHour(domingo.closing, val.format24)
                                    setChange(true);
                                    setDomingo({id : domingo.id, opening : val.format24 + ':00', closing: valid ? domingo.closing : setCierreDefault(val.format24 + ':00'), status: domingo.status, day : domingo.day});
                                } }/>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={domingo.closing}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {domingoCheck}
                                onSet={ (val) => {
                                    let valid = validHour(val.format24, domingo.opening)
                                    if(valid){
                                        setChange(true);
                                        setDomingo({id : domingo.id, opening : domingo.opening, closing: val.format24 + ':00', status: domingo.status, day : domingo.day});
                                    }
                                    else{
                                        setDomingo({id : domingo.id, opening : domingo.opening, closing: setCierreDefault(domingo.opening), status: domingo.status, day : domingo.day});
                                        error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                                    }
                                } }/>
                            </div>
                        </div>
                        <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left",
                            marginLeft:"12px"
                        }}></div>
                    </div>
                    <div className="row" style={{paddingBottom:'18px'}}>
                        <div className="col-6">
                            <CheckBoxComponent checked={lunesCheck} onChange={handleLunesCheck} text="Lunes"/>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={lunes.opening}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {lunesCheck}
                                onSet={ (val) => {
                                    let valid = validHour(lunes.closing, val.format24)
                                    setChange(true);
                                    setLunes({id : lunes.id, opening : val.format24 + ':00', closing: valid ? lunes.closing :  setCierreDefault(val.format24 + ':00'), status: lunes.status, day : lunes.day});
                                } }/>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={lunes.closing}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {lunesCheck}
                                onSet={ (val) => {
                                    let valid = validHour(val.format24, lunes.opening)
                                    if(valid){
                                        setChange(true);
                                        setLunes({id : lunes.id, opening : lunes.opening, closing: val.format24 + ':00', status: lunes.status, day : lunes.day});
                                    }
                                    else{
                                        setLunes({id : lunes.id, opening : lunes.opening, closing: setCierreDefault(lunes.opening), status: lunes.status, day : lunes.day});
                                        error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                                    }
                                    
                                } }/>
                            </div>
                        </div>
                        <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left",
                            marginLeft:"12px"
                        }}></div>
                    </div>
                    <div className="row" style={{paddingBottom:'18px'}}>
                        <div className="col-6">
                            <CheckBoxComponent checked={martesCheck} onChange={handleMartesCheck} text="Martes"/>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={martes.opening}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {martesCheck}
                                onSet={ (val) => {
                                    let valid = validHour(martes.closing, val.format24)
                                    setChange(true);
                                    setMartes({id : martes.id, opening : val.format24 + ':00', closing: valid ? martes.closing : setCierreDefault(val.format24 + ':00'), status: martes.status, day : martes.day});
                                } }/>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={martes.closing}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {martesCheck}
                                onSet={ (val) => {
                                    let valid = validHour(val.format24, martes.opening)
                                    if(valid){
                                        setChange(true);
                                        setMartes({id : martes.id, opening : martes.opening, closing: val.format24 + ':00', status: martes.status, day : martes.day});
                                    }
                                    else{
                                        setLunes({id : martes.id, opening : martes.opening, closing: setCierreDefault(martes.opening), status: martes.status, day : martes.day});
                                        error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                                    }
                                    
                                } }/>
                            </div>
                        </div>
                        <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left",
                            marginLeft:"12px"
                        }}></div>
                    </div>
                    <div className="row" style={{paddingBottom:'18px'}}>
                        <div className="col-6">
                            <CheckBoxComponent checked={miercolesCheck} onChange={handleMiercolesCheck} text="Miércoles"/>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={miercoles.opening}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {miercolesCheck}
                                onSet={ (val) => {
                                    let valid = validHour(miercoles.closing, val.format24)
                                    setChange(true);
                                    setMiercoles({id : miercoles.id, opening : val.format24 + ':00', closing: valid ? miercoles.closing : setCierreDefault(val.format24 + ':00'), status: miercoles.status, day : miercoles.day});
                                } }/>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={miercoles.closing}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {miercolesCheck}
                                onSet={ (val) => {
                                    let valid = validHour(val.format24, miercoles.opening)
                                    if(valid){
                                        let valid = validHour(domingo.closing, val.format24)
                                        setChange(true);
                                        setMiercoles({id : miercoles.id, opening : miercoles.opening, closing: val.format24 + ':00', status: miercoles.status, day : miercoles.day});
                                    }
                                    else{
                                        setLunes({id : miercoles.id, opening : miercoles.opening, closing: setCierreDefault(miercoles.opening), status: miercoles.status, day : miercoles.day});
                                        error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                                    }
                                    
                                } }/>
                            </div>
                        </div>
                        <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left",
                            marginLeft:"12px"
                        }}></div>
                    </div>
                    <div className="row" style={{paddingBottom:'18px'}}>
                        <div className="col-6">
                            <CheckBoxComponent checked={juevesCheck} onChange={handleJuevesCheck} text="Jueves"/>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={jueves.opening}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {juevesCheck}
                                onSet={ (val) => {
                                    let valid = validHour(jueves.closing, val.format24)
                                    setChange(true);
                                    setJueves({id : jueves.id, opening : val.format24 + ':00', closing: valid ? jueves.closing : setCierreDefault(val.format24 + ':00'), status: jueves.status, day : jueves.day});
                                } }/>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={jueves.closing}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {juevesCheck}
                                onSet={ (val) => {
                                    let valid = validHour(val.format24, jueves.opening)
                                    if(valid){
                                        setChange(true);
                                        setJueves({id : jueves.id, opening : jueves.opening, closing: val.format24 + ':00', status: jueves.status, day : jueves.day});
                                    }
                                    else{
                                        setLunes({id : jueves.id, opening : jueves.opening, closing: setCierreDefault(jueves.opening), status: jueves.status, day : jueves.day});
                                        error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                                    }
                                    
                                } }/>
                            </div>
                        </div>
                        <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left",
                            marginLeft:"12px"
                        }}></div>
                    </div>
                    <div className="row" style={{paddingBottom:'18px'}}>
                        <div className="col-6">
                            <CheckBoxComponent checked={viernesCheck} onChange={handleViernesCheck} text="Viernes"/>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={viernes.opening}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {viernesCheck}
                                onSet={ (val) => {
                                    let valid = validHour(viernes.closing, val.format24)
                                    setChange(true);
                                    setViernes({id : viernes.id, opening : val.format24 + ':00', closing: valid ? viernes.closing : setCierreDefault(val.format24 + ':00'), status: viernes.status, day : viernes.day});
                                } }/>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={viernes.closing}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {viernesCheck}
                                onSet={ (val) => {
                                    let valid = validHour(val.format24, viernes.opening)
                                    if(valid){
                                        setChange(true);
                                        setViernes({id : viernes.id, opening : viernes.opening, closing: val.format24 + ':00', status: viernes.status, day : viernes.day});
                                    }
                                    else{
                                        setLunes({id : viernes.id, opening : viernes.opening, closing: setCierreDefault(viernes.opening), status: viernes.status, day : viernes.day});
                                        error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                                    }
                                    
                                } }/>
                            </div>
                        </div>
                        <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left",
                            marginLeft:"12px"
                        }}></div>
                    </div>
                    <div className="row" style={{paddingBottom:'18px'}}>
                        <div className="col-6">
                            <CheckBoxComponent checked={sabadoCheck} onChange={handleSabadoCheck} text="Sábado"/>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={sabado.opening}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {sabadoCheck}
                                onSet={ (val) => {
                                    let valid = validHour(sabado.closing, val.format24)
                                    setChange(true);
                                    setSabado({id : sabado.id, opening : val.format24 + ':00', closing: valid ? sabado.closing : setCierreDefault(val.format24 + ':00'), status: sabado.status , day : sabado.day});
                                } }/>
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{display:'flex'}}>
                                <TimePicker
                                time={sabado.closing}
                                className="input-field picker"
                                theme="Founders"
                                placeholder="Start Time"
                                active = {sabadoCheck}
                                onSet={ (val) => {
                                    let valid = validHour(val.format24, sabado.opening)
                                    if(valid){
                                        setChange(true);
                                        setSabado({id : sabado.id, opening : sabado.opening, closing: val.format24 + ':00', status: sabado.status, day : sabado.day});
                                    }
                                    else{
                                        setLunes({id : sabado.id, opening : sabado.opening, closing: setCierreDefault(sabado.opening), status: sabado.status, day : sabado.day});
                                        error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                                    }
                                    
                                } }/>
                            </div>
                        </div>
                        <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left",
                            marginLeft:"12px"
                        }}></div>
                    </div>
                    <div style={{paddingTop:'20px', display:'flex', justifyContent:'flex-end'}}>
                        <button className="btn btn-lg mt-5" style={{width:'162px', boxShadow: 'none', fontFamily:'Commuters Sans SemiBold'}} onClick={() => {setChange(false); getRestaurantInfo()}}>
                            CANCELAR
                        </button>
                        <button className="btn btn-primary btn-lg mt-5" type="button" style={{width:'190px'}} onClick={() => {putServiceDay()}}>
                            GUARDAR
                        </button>
                    </div>
                    {/* <div>
                        <button className="btn btn-primary" style={{marginTop:'25px'}} onClick={() =>(putServiceDay())}>ACTUALIZAR HORARIO</button>
                    </div> */}
                </div>
            </div>
        </article>
    );
        
    const movile = () =>(
        <article>
            <a style={{position: "absolute", top:25, left:'5%' }} onClick={()=> window.location = "/Restaurants" }>
                <img width="24" height="24" src="/images/backicon.svg"/>
            </a>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '35%'
                         }}>
                RESTAURANTE
            </h5>
            <div className="div-tab-item">
                <Link className="tabitem" to={`/Restaurants/Details/${restaurantId.id}`} style={{paddingBottom:'16px'}}>DESCRIPCIÓN</Link>
                <Link className="tabitem" to={`/Restaurants/Photographs/${restaurantId.id}`} style={{paddingBottom:'16px'}}>FOTOGRAFÍAS</Link>
                <Link className="tabitem" to={`/Restaurants/Capacity/${restaurantId.id}`} style={{paddingBottom:'16px'}}>CAPACIDAD</Link>
                <Link className="tabitem" to={`/Restaurants/Menu/${restaurantId.id}`} style={{paddingBottom:'16px'}}>MENÚ</Link>
                <Link className="tabitem selected" to={`/Restaurants/Hours/${restaurantId.id}`} style={{paddingBottom:'16px'}}>HORARIO</Link>
            </div>
            <div style={{display:'flex', flexDirection:'column', padding: "29px 40px"}}>
                <label style={{
                                width: "358px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "16px",
                                fontWeight: 300,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                margin: '38px 57px 20px 0px'}}>
                    HORARIO DE ATENCIÓN
                </label>
                <label style={{
                            color:'black',
                            width: '358px',
                            height: '64px',
                            margin: '20px 58px 0px 0px',
                            fontfamily: 'Roboto-Light',
                            fontsize: '14px',
                            fontweight: '300',
                            fontstretch: 'normal',
                            fontstyle: 'normal',
                            lineheight: '1.14',
                            letterspacing: '0.35px',
                            textAlign: "left"}}>
                    Selecciona los días de atención y el horario de apertura y cierre.
                </label>
                <div style={{display:'flex', padding: "15px 0px 15px 0px"}}>
                    <div style={{"flex-grow": ".60", textAlign:'initial'}}>
                        <label>Día</label>
                    </div>
                    <div style={{"flex-grow": ".30", textAlign:'initial'}}>
                        <label>APERTURA</label>
                    </div>
                    <div style={{"flex-grow": ".30", textAlign:'initial'}}>
                        <label>CIERRE</label>
                    </div>
                </div>
                <div style={{display:'flex', padding: "15px 0px 15px 0px", alignItems:'center'}}>
                    <div style={{"flex-grow": ".30"}}>
                        <CheckBoxComponent checked={domingoCheck} onChange={handleDomingoCheck} text="Domingo"/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                            time={domingo.opening}
                            className="input-field picker"
                            theme="Founders"
                            placeholder="Start Time"
                            style={{margin:'0px'}}
                            active = {domingoCheck}
                            onSet={ (val) => {
                                let valid = validHour(domingo.closing, val.format24)
                                setChange(true);
                                setDomingo({id : domingo.id, opening : val.format24 + ':00', closing: valid ? domingo.closing : setCierreDefault(val.format24 + ':00'), status: domingo.status, day : domingo.day});
                            } }/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                        time={domingo.closing}
                        className="input-field picker"
                        theme="Founders"
                        placeholder="Start Time"
                        active = {domingoCheck}
                        onSet={ (val) => {
                            let valid = validHour(val.format24, domingo.opening)
                            if(valid){
                                setChange(true);
                                setDomingo({id : domingo.id, opening : domingo.opening, closing: val.format24 + ':00', status: domingo.status, day : domingo.day});
                            }
                            else{
                                setDomingo({id : domingo.id, opening : domingo.opening, closing: setCierreDefault(domingo.opening), status: domingo.status, day : domingo.day});
                                error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                            }
                        } }/>
                    </div>
                </div>
                <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left"
                        }}></div>
                <div style={{display:'flex', padding: "15px 0px 15px 0px", alignItems:'center'}}>
                    <div style={{"flex-grow": ".30"}}>
                        <CheckBoxComponent checked={lunesCheck} onChange={handleLunesCheck} text="Lunes"/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                            time={lunes.opening}
                            className="input-field picker"
                            theme="Founders"
                            placeholder="Start Time"
                            active = {lunesCheck}
                            onSet={ (val) => {
                                let valid = validHour(lunes.closing, val.format24)
                                setChange(true);
                                setLunes({id : lunes.id, opening : val.format24 + ':00', closing: valid ? lunes.closing :  setCierreDefault(val.format24 + ':00'), status: lunes.status, day : lunes.day});
                            } }/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                            time={lunes.closing}
                            className="input-field picker"
                            theme="Founders"
                            placeholder="Start Time"
                            active = {lunesCheck}
                            onSet={ (val) => {
                                let valid = validHour(val.format24, lunes.opening)
                                if(valid){
                                    setChange(true);
                                    setLunes({id : lunes.id, opening : lunes.opening, closing: val.format24 + ':00', status: lunes.status, day : lunes.day});
                                }
                                else{
                                    setLunes({id : lunes.id, opening : lunes.opening, closing: setCierreDefault(lunes.opening), status: lunes.status, day : lunes.day});
                                    error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                                }
                                
                            } }/>
                    </div>
                </div>
                <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left"
                        }}></div>
                <div style={{display:'flex', padding: "15px 0px 15px 0px", alignItems:'center'}}>
                    <div style={{"flex-grow": ".30"}}>
                        <CheckBoxComponent checked={martesCheck} onChange={handleMartesCheck} text="Martes"/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                            time={martes.opening}
                            className="input-field picker"
                            theme="Founders"
                            placeholder="Start Time"
                            active = {martesCheck}
                            onSet={ (val) => {
                                let valid = validHour(martes.closing, val.format24)
                                setChange(true);
                                setMartes({id : martes.id, opening : val.format24 + ':00', closing: valid ? martes.closing : setCierreDefault(val.format24 + ':00'), status: martes.status, day : martes.day});
                            } }/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                        time={martes.closing}
                        className="input-field picker"
                        theme="Founders"
                        placeholder="Start Time"
                        active = {martesCheck}
                        onSet={ (val) => {
                            let valid = validHour(val.format24, martes.opening)
                            if(valid){
                                setChange(true);
                                setMartes({id : martes.id, opening : martes.opening, closing: val.format24 + ':00', status: martes.status, day : martes.day});
                            }
                            else{
                                setLunes({id : martes.id, opening : martes.opening, closing: setCierreDefault(martes.opening), status: martes.status, day : martes.day});
                                error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                            }
                            
                        } }/>
                    </div>
                </div>
                <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left"
                        }}></div>
                <div style={{display:'flex', padding: "15px 0px 15px 0px", alignItems:'center'}}>
                    <div style={{"flex-grow": ".30"}}>
                        <CheckBoxComponent checked={miercolesCheck} onChange={handleMiercolesCheck} text="Miércoles"/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                            time={miercoles.opening}
                            className="input-field picker"
                            theme="Founders"
                            placeholder="Start Time"
                            active = {miercolesCheck}
                            onSet={ (val) => {
                                let valid = validHour(miercoles.closing, val.format24)
                                setChange(true);
                                setMiercoles({id : miercoles.id, opening : val.format24 + ':00', closing: valid ? miercoles.closing : setCierreDefault(val.format24 + ':00'), status: miercoles.status, day : miercoles.day});
                            } }/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                            time={miercoles.closing}
                            className="input-field picker"
                            theme="Founders"
                            placeholder="Start Time"
                            active = {miercolesCheck}
                            onSet={ (val) => {
                                let valid = validHour(val.format24, miercoles.opening)
                                if(valid){
                                    let valid = validHour(domingo.closing, val.format24)
                                    setChange(true);
                                    setMiercoles({id : miercoles.id, opening : miercoles.opening, closing: val.format24 + ':00', status: miercoles.status, day : miercoles.day});
                                }
                                else{
                                    setLunes({id : miercoles.id, opening : miercoles.opening, closing: setCierreDefault(miercoles.opening), status: miercoles.status, day : miercoles.day});
                                    error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                                }
                                
                            } }/>
                    </div>
                </div>
                <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left"
                        }}></div>
                <div style={{display:'flex', padding: "15px 0px 15px 0px", alignItems:'center'}}>
                    <div style={{"flex-grow": ".30"}}>
                        <CheckBoxComponent checked={juevesCheck} onChange={handleJuevesCheck} text="Jueves"/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                            time={jueves.opening}
                            className="input-field picker"
                            theme="Founders"
                            placeholder="Start Time"
                            active = {juevesCheck}
                            onSet={ (val) => {
                                let valid = validHour(jueves.closing, val.format24)
                                setChange(true);
                                setJueves({id : jueves.id, opening : val.format24 + ':00', closing: valid ? jueves.closing : setCierreDefault(val.format24 + ':00'), status: jueves.status, day : jueves.day});
                            } }/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                            time={jueves.closing}
                            className="input-field picker"
                            theme="Founders"
                            placeholder="Start Time"
                            active = {juevesCheck}
                            onSet={ (val) => {
                                let valid = validHour(val.format24, jueves.opening)
                                if(valid){
                                    setChange(true);
                                    setJueves({id : jueves.id, opening : jueves.opening, closing: val.format24 + ':00', status: jueves.status, day : jueves.day});
                                }
                                else{
                                    setLunes({id : jueves.id, opening : jueves.opening, closing: setCierreDefault(jueves.opening), status: jueves.status, day : jueves.day});
                                    error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                                }
                                
                            } }/>
                    </div>
                </div>
                <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left"
                        }}></div>
                <div style={{display:'flex', padding: "15px 0px 15px 0px", alignItems:'center'}}>
                    <div style={{"flex-grow": ".30"}}>
                        <CheckBoxComponent checked={viernesCheck} onChange={handleViernesCheck} text="Viernes"/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                            time={viernes.opening}
                            className="input-field picker"
                            theme="Founders"
                            placeholder="Start Time"
                            active = {viernesCheck}
                            onSet={ (val) => {
                                let valid = validHour(viernes.closing, val.format24)
                                setChange(true);
                                setViernes({id : viernes.id, opening : val.format24 + ':00', closing: valid ? viernes.closing : setCierreDefault(val.format24 + ':00'), status: viernes.status, day : viernes.day});
                            } }/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                            time={viernes.closing}
                            className="input-field picker"
                            theme="Founders"
                            placeholder="Start Time"
                            active = {viernesCheck}
                            onSet={ (val) => {
                                let valid = validHour(val.format24, viernes.opening)
                                if(valid){
                                    setChange(true);
                                    setViernes({id : viernes.id, opening : viernes.opening, closing: val.format24 + ':00', status: viernes.status, day : viernes.day});
                                }
                                else{
                                    setLunes({id : viernes.id, opening : viernes.opening, closing: setCierreDefault(viernes.opening), status: viernes.status, day : viernes.day});
                                    error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                                }
                                
                            } }/>
                    </div>
                </div>
                <div style={{
                            borderBottom: "1px solid rgba(200, 200, 200)",
                            height: "20px",
                            textAlign: "left"
                        }}></div>
                <div style={{display:'flex', padding: "15px 0px 15px 0px", alignItems:'center'}}>
                    <div style={{"flex-grow": ".30"}}>
                        <CheckBoxComponent checked={sabadoCheck} onChange={handleSabadoCheck} text="Sábado"/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                            time={sabado.opening}
                            className="input-field picker"
                            theme="Founders"
                            placeholder="Start Time"
                            active = {sabadoCheck}
                            onSet={ (val) => {
                                let valid = validHour(sabado.closing, val.format24)
                                setChange(true);
                                setSabado({id : sabado.id, opening : val.format24 + ':00', closing: valid ? sabado.closing : setCierreDefault(val.format24 + ':00'), status: sabado.status , day : sabado.day});
                            } }/>
                    </div>
                    <div style={{"flex-grow": ".30",display:'flex'}}>
                        <TimePicker
                            time={sabado.closing}
                            className="input-field picker"
                            theme="Founders"
                            placeholder="Start Time"
                            active = {sabadoCheck}
                            onSet={ (val) => {
                                let valid = validHour(val.format24, sabado.opening)
                                if(valid){
                                    setChange(true);
                                    setSabado({id : sabado.id, opening : sabado.opening, closing: val.format24 + ':00', status: sabado.status, day : sabado.day});
                                }
                                else{
                                    setLunes({id : sabado.id, opening : sabado.opening, closing: setCierreDefault(sabado.opening), status: sabado.status, day : sabado.day});
                                    error('Debe haber minimo tres horas de diferencia entre el apertura y cierre')
                                }
                                
                            } }/>
                    </div>
                </div>
                <div style={{paddingTop:'20px'}}>
                    <button className="btn btn-lg mt-5" style={{width:'162px', boxShadow: 'none', fontFamily:'Commuters Sans SemiBold'}} onClick={() => {setChange(false); getRestaurantInfo()}}>
                        CANCELAR
                    </button>
                    <button className="btn btn-primary btn-lg mt-5" type="button" style={{width:'190px'}} onClick={() => {putServiceDay()}}>
                        GUARDAR
                    </button>
                </div>
            </div>
        </article>
    );

    return (
        isDesktopOrLaptop ? desktop() : movile()
    )
}



export default RestaurantHours
