import React from 'react'

const BackgroundInfo = (props) => {
    return (
        props.Type === "Content" ? 
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <img
                    alt="Group61"
                    src="/images/Group61.svg"
                    style={{
                    height:'120px', 
                    width:'120px'}}/>

                <label style={{
                    textAlign:'center',
                    width: '338px',
                    height: '38px',
                    fontfamily: 'Roboto',
                    fontsize: '16px',
                    fontweight: '300',
                    fontstretch: 'normal',
                    fontstyle: 'normal',
                    lineheight: '1.19',
                    letterspacing: '0.4px',
                    textalign: 'center'}}>Todavía no tienes ninguna solicitud</label>

                <a href="/Clients/SendInvitation" style={{
                    width: '300px',
                    height: '15px',
                    marginTop: '39px',
                    fontfamily: 'CommutersSans',
                    fontsize: '12px',
                    fontweight: '600',
                    fontstretch: 'normal',
                    fontstyle: 'normal',
                    lineheight: '1.25',
                    letterspacing: '1.2px',
                    textAlign: 'center',
                    textDecoration:'none',
                    color:'black'
                }}>INVITAR A UN CLIENTE</a>
            </div>
        : props.Type === "Serch" ?
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <img
                    alt="iconoLupa"
                    src="/images/iconoLupa.svg"
                    style={{
                    height:'120px', 
                    width:'120px'}}/>

                <label style={{
                    textAlign:'center',
                    width: '338px',
                    height: '38px',
                    fontfamily: 'Roboto',
                    fontsize: '16px',
                    fontweight: '300',
                    fontstretch: 'normal',
                    fontstyle: 'normal',
                    lineheight: '1.19',
                    letterspacing: '0.4px',
                    textalign: 'center'}}>No se encontraron resultados</label>
            </div>
        : props.Type === "Restaurant" ?
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <img alt="Group62" src="/images/Group62.svg" style={{
                    height:'120px', 
                    width:'120px'}}/>

                <label style={{
                    textAlign:'center',
                    width: '338px',
                    height: '38px',
                    fontfamily: 'Roboto',
                    fontsize: '16px',
                    fontweight: '300',
                    fontstretch: 'normal',
                    fontstyle: 'normal',
                    lineheight: '1.19',
                    letterspacing: '0.4px',
                    textalign: 'center'}}>Todavía no haz agregado restaurantes</label>

                <a href="/Restaurants/NewRestaurant" style={{
                    width: '300px',
                    height: '15px',
                    marginTop: '39px',
                    fontfamily: 'CommutersSans',
                    fontsize: '12px',
                    fontweight: '600',
                    fontstretch: 'normal',
                    fontstyle: 'normal',
                    lineheight: '1.25',
                    letterspacing: '1.2px',
                    textAlign: 'center',
                    textDecoration:'none',
                    color:'black'
                }}>AGREGAR RESTAURANTE</a>
            </div>
        : props.Type === "Service" ?
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <img alt="Group59" src="/images/Group59.svg" style={{
                    height:'120px', 
                    width:'120px'}}/>

                <label style={{
                    textAlign:'center',
                    width: '338px',
                    height: '38px',
                    fontfamily: 'Roboto',
                    fontsize: '16px',
                    fontweight: '300',
                    fontstretch: 'normal',
                    fontstyle: 'normal',
                    lineheight: '1.19',
                    letterspacing: '0.4px',
                    textalign: 'center'}}>Lo sentimos, no podemos encontrar la información. Verifica tu conexión a internet.</label>

                <a href="/" style={{
                    width: '300px',
                    height: '15px',
                    marginTop: '39px',
                    fontfamily: 'CommutersSans',
                    fontsize: '12px',
                    fontweight: '600',
                    fontstretch: 'normal',
                    fontstyle: 'normal',
                    lineheight: '1.25',
                    letterspacing: '1.2px',
                    textAlign: 'center',
                    textDecoration:'none',
                    color:'black'
                }}>INTENTAR DE NUEVO</a>
            </div>
        : props.Type === "Payments" ?
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <img alt="Group59" src="/images/cobrosIcon.svg" style={{
                    height:'120px', 
                    width:'120px'}}/>

                <label style={{
                    textAlign:'center',
                    width: '338px',
                    height: '38px',
                    fontfamily: 'Roboto',
                    fontsize: '16px',
                    fontweight: '300',
                    fontstretch: 'normal',
                    fontstyle: 'normal',
                    lineheight: '1.19',
                    letterspacing: '0.4px',
                    textalign: 'center'}}>Todavía no tienes cobros realizados</label>

                {/* <a href="/" style={{
                    width: '300px',
                    height: '15px',
                    marginTop: '39px',
                    fontfamily: 'CommutersSans',
                    fontsize: '12px',
                    fontweight: '600',
                    fontstretch: 'normal',
                    fontstyle: 'normal',
                    lineheight: '1.25',
                    letterspacing: '1.2px',
                    textAlign: 'center',
                    textDecoration:'none',
                    color:'black'
                }}>INTENTAR DE NUEVO</a> */}
            </div>
        : props.Type === "Reservations" ? 
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <img
                    alt="Group61"
                    src="/images/Group61.svg"
                    style={{
                    height:'120px', 
                    width:'120px'}}/>

                <label style={{
                    textAlign:'center',
                    width: '338px',
                    height: '38px',
                    fontfamily: 'Roboto',
                    fontsize: '16px',
                    fontweight: '300',
                    fontstretch: 'normal',
                    fontstyle: 'normal',
                    lineheight: '1.19',
                    letterspacing: '0.4px',
                    textalign: 'center'}}>Todavía no tiene ninguna reservación.</label>
            </div>
        : props.Type === "ContentReport" ? 
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <img
                    alt="Group61"
                    src="/images/Group61.svg"
                    style={{
                    height:'120px', 
                    width:'120px'}}/>

                <label style={{
                    textAlign:'center',
                    width: '338px',
                    height: '38px',
                    fontfamily: 'Roboto',
                    fontsize: '16px',
                    fontweight: '300',
                    fontstretch: 'normal',
                    fontstyle: 'normal',
                    lineheight: '1.19',
                    letterspacing: '0.4px',
                    textalign: 'center'}}>Aun no tienen ninguna publicación o story reportada.</label>
            </div>
        :
        <div>
            
        </div>
    )
}

export default BackgroundInfo
