import React from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import ReportDetails from './ReportContent/reportDetail'
import ReportsList from './ReportContent/reportsList'

const ReportContentpage = () => {
    return (
        <Router>
            <Switch>
                <Route path="/ReportContent/Details/:id" component={ReportDetails}></Route>
                <Route component={ReportsList}></Route>
            </Switch>
        </Router>
    )
}

export default ReportContentpage
