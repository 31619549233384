import React, { useEffect, useState } from 'react'
import {useParams, Link } from 'react-router-dom'
import CheckBoxComponent from '../components/CheckBoxComponent'
import { useMediaQuery } from 'react-responsive'
import PriceComponent from '../components/PriceComponent'
import ModalComponent, { ModalFunctions } from '../components/ModalComponent'
import TextBoxComponent from '../components/TextboxComponent'
import RestaurantsService from '../services/founder/RestaurantsServices'
import CatalogsService from '../services/app/CatalogsService'
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import DatePicker from "react-multi-date-picker"
import gregorianes from "react-date-object/locales/gregorian_es";
import { v4 as uuid } from 'uuid';
import {
    KeyboardTimePicker,
    KeyboardDatePicker,

} from '@material-ui/pickers';
import moment from 'moment';
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
  } from 'react-places-autocomplete';
import { toast } from 'react-toastify'
import IsSpecialCharacters from '../services/UltiService'
import IsSpecialCharactersNotDot from '../services/ValidateCaracters'
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {

    TextField,
    
} from '@material-ui/core';

const CreateBannerComponent = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [position, setPosition] = useState(0);
    const [link, setLink] = useState('');
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);
  
    const [fields, setFields] = React.useState({
      title: '',
      description: '',
      posicion: '',
      imagen: '',
      enlace:'',
      startDate: format(new Date(), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
  
  });
  const updateFields = (field, value) => {
      const newFields = {
          ...fields,
      };
      newFields[field] = value;
      setFields(newFields);
  };
  
  function notify() {
      toast.success('Información actualizada con éxito', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored"
      });
    }
  
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        try {
          const body = {
            title: fields.title,
            description: fields.description,
            posicion: fields.posicion,
            imagen: fields.imagen,
            enlace: fields.enlace
          };
      
          RestaurantsService.createdBanner(body)
            .then((r) => {
              if (r != null && r.success == 1) {
                notify(r.message);
                setTimeout(() => {
                  document.location = '/Banners';
                }, 1500);
              } else {
                setError(r.message);
              }
            })
            .catch((err) => {
              setError('Ocurrió un error al crear el banner. Por favor, inténtalo de nuevo.');
              console.error('Error al crear el banner:', err);
            });
        } catch (err) {
          setError('Ocurrió un error al crear el banner. Por favor, inténtalo de nuevo.');
          console.error('Error al crear el banner:', err);
        }
      };
  
    return (
      <div>
       
        <div style={{ display: "flex", alignItems: "center", padding: "29px 40px" }}>
            <label style={{
                width: "111px",
                height: "25px",
                fontFamily: "Commuters Sans SemiBold",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.25,
                letterSpacing: "normal",
                textAlign: "left",
                "flex-grow": "1",
                color: "black"
            }}>
                CREAR BANNER
            </label>
        </div>
        <div style={{
            borderBottom: "2px solid #c8c8c8",
            height: "0px",
            textAlign: "left",
            padding: "0 24px"
        }}>
        </div>
        <div style={{ display: "flex", padding: "29px 36px 29px 40px", height: 'calc(100% - 150px)', overflow: 'auto' }}>
            <div style={{ display: "flex", width: '30%', justifyContent: 'space-between' }}>
                <div style={{ textAlign: "left" }}>
                    <p style={{
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        color: 'black',
                        letterSpacing: '0.5px',
                        width: '80%',
                        paddingTop: '40px'
                    }}>
                        Crear los datos de banner.
                    </p>
                </div>
                <div style={{
                    width: "0px",
                    height: "630px",
                    margin: "56px 0px 70px",
                    opacity: "0.15",
                    border: "solid 0.5px #000001",
                    paddingLeft: "0px",
                    paddingRight: "0px"
                }}>
                </div>
            </div>
            <div  style={{ display: 'flex', width: '100%', overflow: 'auto'}}>
             
        <form onSubmit={handleSubmit} style={{ display: 'flex', width: '100%' }} >
        <div className="w-100 mx-2">
                  <div className="RestaurantOffer-form-container mr-2"  style={{ height: 'calc(100% - 250px)', overflow: 'auto' }}>
                      <div style={{ color: 'black', fontFamily: 'roboto', fontSize: '16px' }}>
                          <span>
                              <b>Detalles del banner</b>
                              <br />
                          </span>
                      </div>
                      
                      <div className="RestaurantOffer__form" >
                          <div className="w100 mb-1">
                              <TextField
                                  className="w100"
                                  id="title"
                                  label="Título"
                                  value={fields.title}
                                  onChange={(e) => updateFields('title', e.target.value)}
                                
                              />
                          </div>
                          <div className="w100 mb-1">
                              <TextField
                                  className="w100"
                                  id="description"
                                  label="Descripción"
                                  onChange={(e) => updateFields('description', e.target.value)}
                                  value={fields.description}
                              />
                          </div>
  
                          <div className="w100 mb-1">
                              <TextField
                                  className="w100"
                                  id="posicion"
                                  label="Posición"
                                  onChange={(e) => updateFields('posicion', e.target.value)}
                                  value={fields.posicion}
                              />
                          </div>
                        
                          <div className="w100 mb-1">
                          <label htmlFor="imagen" style={{
                            backgroundColor: 'black',
                            color: 'white',
                            border: 'none',
                            padding: '10px 20px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            fontSize: '16px'
                            }}>
                            Cargar banner
                            </label>
                            <input
                            type="file"
                            className="w100"
                            id="imagen"
                            label="Imagen"
                            onChange={(e) => updateFields('imagen', e.target.files[0])}
                            style={{
                                opacity: 0,
                                position: 'absolute',
                                zIndex: -1
                            }}
                            />
                           
                              {fields.imagen && (
                                <div>
                                <p>Archivo seleccionado:</p>
                                <img src={URL.createObjectURL(fields.imagen)} alt={fields.imagen.name} style={{ maxWidth: '200px' }} />
                                <p>{fields.imagen.name}</p>
                              </div>
                              )}
                              </div>
                          <div className="w100 mb-1">
                              <TextField
                                  className="w100"
                                  id="enlace"
                                  label="enclace"
                                  onChange={(e) => updateFields('enlace', e.target.value)}
                                  value={fields.enlace}
                              />
                          </div>
  
                      </div>
                         <button type="submit" className="btn btn-primary" style={{ padding: '0.5rem 1rem', fontSize: '14px', height: '45px' }}>
                Guardar
                </button>
                  </div>
               
              </div>
           
        </form>
        </div> </div>
      </div>
    );
  };
  
  export default CreateBannerComponent;