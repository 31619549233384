import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackgroundInfo from '../../components/BackgroundInfo';
import ModalComponent, { ModalFunctions } from '../../components/ModalComponent';
import TextBoxComponent from '../../components/TextboxComponent';
import ReportContent from '../../services/founder/ReportContent';

const ReportsList = () => {
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [total,setTotal] = React.useState(0);
    const [firstRow,setFirstRow] = React.useState(0);
    const [lastRow,setLastRow] = React.useState(0);
    const [lastPage,setLastPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [reportsList, setReportsList] = React.useState([]);
    const [refreshForce, setRefreshForce] = React.useState([]);
    const [backgrounInfo,setBackgrounInfo] = React.useState("");
    const [removeReport,setRemoveReport] = React.useState("");
    const [userName,setUserName] = React.useState("");
    const [order, setOrder] = React.useState("");

    function notify() {
        toast.success('Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    // function orderInfo() {
    //     if(order === "" || order === "desc"){
    //         setOrder("asc");
    //     }
    //     else{
    //         setOrder("desc");
    //     }
    //     setRefreshForce(Math.random());
    // }

    function handleMovePageDown(e){
        if(page > 1){
            setPage(page-1);
            setRefreshForce(Math.random());
        }
        
    }

    function handleMovePageUp(e){
        if(page != lastPage){
            setPage(page+1);
            setRefreshForce(Math.random());
        }
    }

    function handleSearchChange(e){
        setSearch(e.target.value);
    }
    
    function handleRowsChange(e){
        setPage(1);
        setRowsPerPage(e.target.value);
    }

    function handleSearchOnEnter(e){
        setRefreshForce(Math.random());
    }

    function getDate(date) {
        let mont =["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ]
        let data = new Date(date);
        return ( (data.getDate() >= 10 ? data.getDate():"0" + data.getDate()) + " " + mont[data.getMonth()] + " " + data.getFullYear() + " " + (data.getHours() >= 10 ? data.getHours() : "0" + data.getHours())  + ":" + (data.getMinutes() >= 10 ? data.getMinutes() : "0" + data.getMinutes()))
    }

    function DeleteContent(id, report) {
        var name = (report.first_name !== null ? report.first_name.toUpperCase() : " ") + " " + (report.middle_name !== null ? report.middle_name.toUpperCase() : " ") + " " + (report.last_name !== null ? report.last_name.toUpperCase() : " ");
        setRemoveReport(id);
        setUserName(name);
        ModalFunctions.ShowModal('modalDeleteContent');
    }

    function deleteContentService() {
        ModalFunctions.HideModal('modalDeleteContent');
        ReportContent.deleteContent(removeReport).then((r) =>{
            if(r.message == "Información eliminada correctamente")
            {
                notify()
                setRemoveReport("");
                setUserName("");
                setRefreshForce(Math.random());
            }
        });
    }

    function getReportList() {
        ReportContent.getReportContentLits(page, rowsPerPage).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setReportsList(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                }
                else
                {
                    setReportsList([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setBackgrounInfo("ContentReport");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }

    function getReportListSearch() {
        ReportContent.getReportContentListSearchByName(search,page,rowsPerPage).then((r) =>{
            if(r != null)
            {
                
                if(r.data.length != 0)
                {
                    setReportsList(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                    setLastPage(r.last_page);
                }
                else
                {
                    setReportsList([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setLastPage(0);
                    setBackgrounInfo("Serch");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }

    function openSearch()
    {
        document.getElementById("buttonSearch").style.visibility="visible";
        document.getElementById('inputSearch').style.width="95%";
    }

    function closeSearch()
    {
        document.getElementById("buttonSearch").style.visibility="hidden";
        document.getElementById('inputSearch').value = '';
        document.getElementById('inputSearch').style.width="10%";
        setSearch("");
        getReportList();
    }

    React.useEffect(()=>{
        if(search == "")
        {
            getReportList()
        }
        else{
            getReportListSearch()
        }
    },[rowsPerPage,refreshForce])

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = (reportsList) => (
        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    CONTENIDO REPORTADO
                </label>
            </div>
            <div style={{display: "flex", alignItems: "center", padding: "10px 40px 30px 40px"}}>
                <div style={{position:'relative', width:'320px'}}>
                    <TextBoxComponent onEnter={handleSearchOnEnter} onChange={handleSearchChange} placeholder="Búsqueda por nombre" type="text"/>    
                    <a style={{position:'absolute', right: "5px", top: "18px"}}>
                        <img src="/images/search.svg"/>
                    </a>
                </div>
            </div>
            <div style={{padding: "14px 40px 29px 40px", overflow: "auto", height: "calc(100% - 250px)",}}>
                <table className="table table-borderless table-hover" style={{
                    textAlign:'initial',
                    fontFamily:'CommutersSans'}}>
                    <thead>
                        <tr style={{ fontFamily:'Commuters Sans Thin', color:'rgba(0, 0, 0, 0.75)'}}>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px', width:'15%'}}>USUARIO REPORTADO</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px', width:'15%'}}>PUBLICACIÓN</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px', width:'10%'}}>TIPO</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px', width:'10%'}}>REPORTES</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px', width:'15%'}}>FECHA DE REPORTE</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px', width:'15%'}}>ACCIONES</th>
                        </tr>
                    </thead>
                    {
                        reportsList.length == 0 ? <tbody style={{display:'table-caption'}}><BackgroundInfo Type={backgrounInfo}/></tbody>:
                        <tbody>
                        {
                            reportsList.map(report =>(
                                <tr id={report.encrypt_id} style={{height:'76px', fontFamily: 'Roboto', fontSize: '12px', verticalAlign: 'middle'}} onClick={ (e) => { document.location = '/ReportContent/Details/' + report.encrypt_id; e.defaultPrevented(); e.stopPropagation();}}>
                                    <td>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <a>
                                                <img src = {
                                                                report.photo_client != null ? report.photo_client : "/images/profile.jpg"
                                                        } 
                                                    style={{height: '34px',width: '26px',margin:'0 8px',borderTopLeftRadius: '17px',borderTopRightRadius: '17px',border: '0.5px solid rgba(0,0,0,0.4)'}}/>
                                            </a>
                                            <label htmlFor="">{report.first_name} {report.middle_name} {report.last_name}</label>
                                        </div>
                                    </td>
                                    <td>
                                        <img src={report.photo} style={{height: '35px',width: '35px'}}/>
                                    </td>
                                    <td>
                                        {report.publication_type}
                                    </td>
                                    <td>
                                        {report.number_reports}
                                    </td>
                                    <td>
                                        {getDate(report.last_date)}
                                    </td>
                                    <td onClick={(e)=>{e.preventDefault(); e.stopPropagation();}}>
                                        <button className="puerta21-btn" type="button" style={{textAlign:'initial'}} onClick={() =>{DeleteContent(report.encrypt_id,report)}}>ELIMINAR PUBLICACIÓN</button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    }
                </table>
            </div>
            <div className="content-pagination">
                <div className="content-select">
                    <span className="label">
                        Filas:&nbsp;&nbsp;
                    </span>
                    <select onChange={handleRowsChange} className="pagination-select">
                        <option selected={rowsPerPage===5} value="5">5</option>
                        <option selected={rowsPerPage===25} value="25">25</option>
                        <option selected={rowsPerPage===100} value="100">100</option>
                    </select>
                </div>
                <div className="content-select-pagination">
                    <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                    <label className="label">de&nbsp;&nbsp;</label>
                    <label className="number">{total}</label>
                    <button className="button-angle" onClick={handleMovePageDown}>
                        <FontAwesomeIcon icon="angle-left" />
                    </button>
                    <button className="button-angle" onClick={handleMovePageUp}>
                        <FontAwesomeIcon icon="angle-right" />
                    </button>
                </div>
            </div>
            <ModalComponent id="modalDeleteContent" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <img src="/images/iconoDescargaArchivo.svg" style={{
                                                                    height:'120px', 
                                                                    width:'120px'}}/>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"20px",
                                    color:'gray',
                                    marginTop:'60px'}}>
                                SE ELIMINARA LA PUBLICACIÓN EN EL PERFIL DE {userName}
                            </label>
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalDeleteContent')
                                }} style={{color:"black", margin: "15px"}}>MANTENER</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {deleteContentService();}}>ELIMINAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );

    const movile = (reportsList) => (
        <article>
            <div  class="search-input">
                <input onClick={openSearch} type="search" id="inputSearch" name="search" placeholder="Buscar" onKeyUp={(ev) => {
                    setSearch(ev.target.value);
                    setRefreshForce(Math.random());
                  }}/>
                <button onClick={closeSearch} id="buttonSearch" style={{visibility:"hidden"}} >x</button>
            </div>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '25%'
                         }}>
                PUBLICACIÓN REPORTADA
            </h5>
            <div class="list-group">
                {
                    reportsList.length == 0 ? <BackgroundInfo Type={backgrounInfo}/> :
                    reportsList.map( report =>(
                        <a style={{margin:"0", padding:"20px 24px"}}>
                            <div style={{textAlign:"left",height:"100px", display:"flex"}} onClick={ (e) => { document.location = '/ReportContent/Details/' + report.encrypt_id; e.defaultPrevented(); e.stopPropagation();}}>
                                <img src={report.photo} style={{height: '100px', width: '100px', marginRight:'10px'}} onClick={ (e) => { document.location = '/ReportContent/Details/' + report.encrypt_id; e.defaultPrevented(); e.stopPropagation();}}/>
                                <img src={report.photo_client != null ? report.photo_client : "/images/profile.jpg"}
                                        style={{height: '60px', width: '50px', display:"inline-table", borderTopLeftRadius: '50px',borderTopRightRadius: '50px', border: '0.5px solid rgba(0,0,0,0.4)'}}/>
                                <div style={{width:'calc(100% - 80px)', height:"100px", padding:"0 10px" }} onClick={ (e) => { document.location = '/ReportContent/Details/' + report.encrypt_id; e.defaultPrevented(); e.stopPropagation();}}>
                                     <span style={{
                                        fontFamily: "Commuters Sans SemiBold",
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        wordWrap: "none",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        letterSpacing: "normal",
                                        color: "black",
                                        textTransform:"uppercase",
                                        display:"inline-block",
                                        overflow: "hidden",
                                        height: "20px"
                                        }}>{report.first_name} {report.middle_name} {report.last_name}</span>
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <label style={{fontFamily:"Roboto", display:"block", fontSize:'13px'}}>Reportes: {report.number_reports}</label>
                                    </div>
                                    <div style={{marginTop:"6px"}}>
                                        <label style={{fontFamily:"Roboto", display:"block", fontSize:'13px'}}>Tipo: {report.publication_type}</label>
                                    </div>
                                    <div style={{marginTop:"6px"}}>
                                        <label style={{fontFamily:"Roboto", display:"block", fontSize:'13px'}}>Ultimo reporte: {getDate(report.last_date)}</label>
                                    </div>
                                </div>
                            </div>
                            <div style={{textAlign:"right", height:"65px", marginTop:"10px",borderBottom:"1px solid rgba(0,0,0,.125)"}}>
                                <button className="puerta21-btn" style={{height:'15px', fontSize:'15px',marginTop:"20px"}} type="button" onClick={() =>{DeleteContent(report.encrypt_id)}}>ELIMINAR PUBLICACIÓN</button>
                            </div>
                        </a>
                    ))
                }
            </div>
            <div className="content-pagination">
                <div className="content-select">
                    <span className="label">
                        Filas:&nbsp;&nbsp;
                    </span>
                    <select onChange={handleRowsChange} className="pagination-select" defaultValue="5">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className="content-select-pagination">
                    <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                    <label className="label">de&nbsp;&nbsp;</label>
                    <label className="number">{total}</label>
                    <button className="button-angle" onClick={handleMovePageDown}>
                        <FontAwesomeIcon icon="angle-left" />
                    </button>
                    <button className="button-angle" onClick={handleMovePageUp}>
                        <FontAwesomeIcon icon="angle-right" />
                    </button>
                </div>
            </div>
            <ModalComponent id="modalDeleteContent" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <img src="/images/iconoDescargaArchivo.svg" style={{
                                                                    height:'120px', 
                                                                    width:'120px'}}/>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"20px",
                                    color:'gray',
                                    marginTop:'60px'}}>
                                SE ELIMINARA LA PUBLICACIÓN EN EL PERFIL DE {userName}
                            </label>
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalDeleteContent')
                                }} style={{color:"black", margin: "15px"}}>MANTENER</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {deleteContentService();}}>ELIMINAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );
    
    return (
        isDesktopOrLaptop ? desktop(reportsList) : movile(reportsList)
    )
}

export default ReportsList
