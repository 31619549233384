import { BehaviorSubject } from 'rxjs';
import CipherService from '../services/CipherService';
import BackendService from '../services/BackendService';

const currentUserSubject = new BehaviorSubject(serializeUser());

const AuthenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get curentUserValue() { return currentUserSubject.value },
    get curentAccessToken() { return JSON.parse(currentUserSubject.value).accessToken }
};

function serializeUser()
{
    return CipherService.decrypt(localStorage.getItem('foundersData'));
}

function login(username,password) {
    return BackendService.login(username,password).then((r)=>{
        if(r != null)
        {
            var newValue = CipherService.encrypt(JSON.stringify({
                accessToken: r.token
            }));
            localStorage.setItem('foundersData',newValue);
            currentUserSubject.next(newValue);
            document.location = '/';
            return true;
        }
        return false;
    });
}

function logout(){
    localStorage.removeItem('foundersData');
    currentUserSubject.next(null);
    document.location.reload();
}

export default AuthenticationService;