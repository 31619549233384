import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import BackgroundInfo from '../../components/BackgroundInfo'
import ChargesMontComponent from '../../components/ChargesMontComponent'
import StatusSelectComponent from '../../components/StatusSelectComponent'
import SwitchComponent from '../../components/SwitchComponent'
import TextBoxComponent from '../../components/TextboxComponent'
import ModalComponent, { ModalFunctions } from '../../components/ModalComponent'
import PdfComponent from '../../components/PdfComponent'
import PaymentsService from '../../services/founder/PaymentsService'
import { toast } from 'react-toastify'
import ReportsService from '../../services/founder/ReportsService'
import { useParams } from 'react-router-dom';
import RestaurantsService from '../../services/founder/RestaurantsServices'
import { Clear } from '@material-ui/icons'

const ChargesRestaurantsList = () => {

    const [restaurant] = React.useState(useParams());
    const [isloading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total,setTotal] = React.useState(0);
    const [firstRow,setFirstRow] = React.useState(0);
    const [lastRow,setLastRow] = React.useState(0);
    const [lastPage,setLastPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [restaurants, setRestaurants] = React.useState([]);
    const [refreshForce, setRefreshForce] = React.useState([]);
    const [backgrounInfo,setBackgrounInfo] = React.useState("");

    const [datePayment,setDatePayment] = React.useState(getDatePayment());
    const [commissionsPayments, setCommissionsPayments] = React.useState(0);    
    const [totalPayments, setTotalPayments] = React.useState(0);
    const [paymentsCatalogStatus, setPaymentsCatalogStatus] = React.useState([]);
    const [yearPayment, setYearPayment] = React.useState(0);
    const [open,setOpen] = React.useState("");

    function handleMovePageDown(e){
        if(page > 1){
            setPage(page-1);
            setRefreshForce(Math.random());
        }
        
    }

    function handleMovePageUp(e){
        if(page != lastPage){
            setPage(page+1);
            setRefreshForce(Math.random());
        }
    }

    function handleSearchChange(e){
        setSearch(e.target.value);
    }
    
    function handleRowsChange(e){
        setPage(1);
        setRowsPerPage(e.target.value);
    }

    function handleSearchOnEnter(e){
        setRefreshForce(Math.random());
        setBackgrounInfo("Serch");
    }

    function getReport(){
        ReportsService.getReportPayments(datePayment)
        .then(response => response.blob())
        .then(blob => {
            let link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = datePayment + "_PaymentsReport.xlsx";
            link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
            link.remove();
            window.URL.revokeObjectURL(link.href);
        })
        .catch(err => console.error(err));
        ModalFunctions.ShowModal('modalReportDownload');
    }

    function getPaymentsList(dateSerch) {
        let dateService = dateSerch === undefined ? datePayment : dateSerch
        PaymentsService.getPaymentsList(page,rowsPerPage,dateService).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setRestaurants(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                    getTotal(dateService);
                    setLastPage(r.last_page);
                }
                else
                {
                    setRestaurants([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setLastPage(0);
                    setBackgrounInfo("Payments");
                    getTotal(dateService);
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }

    function getPaymentsListSeacrh() {
        PaymentsService.getPaymentsSearchByName(search,page,rowsPerPage,datePayment).then((r) =>{
            if(r != null)
            {
                
                if(r.data.length != 0)
                {
                    setRestaurants(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                    setBackgrounInfo("");
                }
                else
                {
                    setRestaurants([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setBackgrounInfo("Serch");
                }
                setLoading(false);
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }

    function getPaymentStatusCatalog() {
        PaymentsService.getPaymentStatus().then((r) =>{
            if(r != null){
                if(r.length != 0){
                    setPaymentsCatalogStatus(r);
                }
            }
        });
    }

    function getDatePayment() {
        let data = new Date();
        let year = data.getFullYear();
        let month = data.getMonth()+1;
        return year + "-" + (month <= 9 ? "0" + month : month);
    }

    function getTotal(date) {
        PaymentsService.getPaymentsTotal(date).then((r) =>{
            if(r != null){
                setTotalPayments(r.total);
                setCommissionsPayments(r.total_commissions)
            }
        });
    }

    function ClearData()
    {
        setLoading(true);
        setRestaurants([]);
        setFirstRow(0);
        setLastRow(0);
        setTotal(0);
        setBackgrounInfo("");
    }

    function getPaymentsMonthUp(monthstep) {
        let data = new Date();
        let year = yearPayment === 0 ? data.getFullYear() : yearPayment;
        ClearData();
        if(monthstep < 12){
            let month = monthstep + 1;
            setDatePayment(year + "-" + (month <= 9 ? "0" + month : month));
            getPaymentsList(year + "-" + (month <= 9 ? "0" + month : month));
        }
        else{
            setYearPayment(year + 1);
            setDatePayment((year + 1) + "-01");
            getPaymentsList((year + 1) + "-01");
        }
        setLoading(false);
    }

    function getPaymentsMonthDown(monthstep) {
        let data = new Date();
        let year = yearPayment === 0 ? data.getFullYear() : yearPayment;
        ClearData();
        if(monthstep > 0)
        {
            setDatePayment(year + "-" + (monthstep <= 9 ? "0" + monthstep : monthstep));
            getPaymentsList(year + "-" + (monthstep <= 9 ? "0" + monthstep : monthstep));
        }
        else{
            setYearPayment(year - 1);
            setDatePayment((year - 1) + "-12");
            getPaymentsList((year - 1) + "-12");
        }
        setLoading(false);
    }

    function UpdatePaymentsStatus(statusName, paymentId){
        let status = paymentsCatalogStatus.find(x => x.name === statusName);
        let payment = restaurants.find(x => x.payment_eid === paymentId);
        if (payment.status !== statusName) {
            PaymentsService.putPaymentChangeStatus(paymentId,status.encrypt_id).then((r) =>{
                if(r != null){
                    notify(r.message);
                    getPaymentsList();
                }
                else{
                    error()
                    document.location.reload();
                }
            });
            setLoading(false);
        }
    }

    function handleStatusRestaurant(checked, restaurantId) {
        if(checked){
            enabledRestaurant(restaurantId);
        }
        else{
            disableRestaurant(restaurantId);
        }
    }

    function disableRestaurant(encrypt_id){
        RestaurantsService.putRestaurantStatus(encrypt_id, 0).then((r) =>{
            if(r.message == "Información guardada correctamente")
            {
                if(!isDesktopOrLaptop)
                {

                }
                else{
                }
                setRefreshForce(Math.random());
            }
        });
    }

    function enabledRestaurant(encrypt_id){
        RestaurantsService.putRestaurantStatus(encrypt_id, 1).then((r) =>{
            if(r.message == "Información guardada correctamente")
            {
                setRefreshForce(Math.random());
            }
        });
    }

    function notify(message) {
        toast.success(message != undefined ? message : 'Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

      function error(message) {
        toast.error(message != undefined ? message : 'No se pudo actualizar', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    function Formatcurrency(params) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
            })

            return formatter.format(params)
    }

    function DateNow() {
        let now = getDatePayment();
        let nowMonth = now.substring(5);
        let nowYearh = now.substring(0,4);
        let paymetnMonth = datePayment.substring(5);
        let paymetnYearh = datePayment.substring(0,4);
        if( Number(paymetnYearh) >= Number(nowYearh) && Number(paymetnMonth) >= Number(nowMonth) ){
            return false
        }
        return true
    }

    function openSearch()
    {
        document.getElementById("buttonSearch").style.visibility="visible";
        document.getElementById('inputSearch').style.width="95%";
    }

    function closeSearch()
    {
        document.getElementById("buttonSearch").style.visibility="hidden";
        document.getElementById('inputSearch').value = '';
        document.getElementById('inputSearch').style.width="10%";
        setSearch("");
        getPaymentStatusCatalog();
    }

    React.useEffect(() => {
        getPaymentStatusCatalog();
        if(search == "")
        {
            let date = restaurant.paymentDate !== undefined ? restaurant.paymentDate : undefined;
            getPaymentsList(date)
        }
        else{
            getPaymentsListSeacrh()
        }
    },[rowsPerPage, refreshForce]);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = (restaurants) =>(
        <article>
            <ChargesMontComponent total={totalPayments} date={restaurant.paymentDate} commissions={commissionsPayments} down={getPaymentsMonthDown} up={getPaymentsMonthUp}/>
            <div style={{
                borderBottom: "1px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}>
            </div>
            <div style={{display: "flex", alignItems: "center", padding: "30px 40px"}}>
                <div style={{position:'relative', width:'320px'}}>
                    <TextBoxComponent onEnter={handleSearchOnEnter} onChange={handleSearchChange} placeholder="Búsqueda por nombre" type="text"/>    
                    <a style={{position:'absolute', right: "5px", top: "18px"}}>
                        <img src="/images/search.svg"/>
                    </a>
                </div>
                <div style={{"flex-grow": "1", textAlign:'end'}}>
                    <button className="puerta21-btn" type="button" style={{fontsize: "16px"}} onClick={() => {getReport();}}>DESCARGAR REPORTE</button>
                </div>
            </div>
            <div style={{
                borderBottom: "1px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}>
            </div>
            <div style={{padding: "14px 40px 29px 40px", overflow: "auto", height: "calc(100% - 303px)",}}>
                <table className="table table-borderless table-hover" style={{
                    textAlign:'initial',
                    fontFamily:'Commuters Sans SemiBold'}}>
                    <thead>
                        <tr style={{ fontFamily:'Commuters Sans Light', color:'rgba(0, 0, 0, 0.75)'}}>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>RESTAURANTE</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>TOTAL A PAGAR</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>ESTATUS DE PAGO</th>
                        </tr>
                    </thead>
                    {
                        isloading ? <div></div> : restaurants.length == 0 ? <tbody style={{display:'table-caption'}}><BackgroundInfo Type={backgrounInfo}/></tbody>:
                        <tbody id="body-data">
                        {
                            restaurants.map(restaurant =>(
                                <tr style={{height:'64px', fontFamily: 'Roboto', fontSize: '12px', verticalAlign: 'middle'}} onClick={ (e) => { document.location = '/ChargeTo/Restaurant/' + restaurant.restaurant_eid + '/' + restaurant.name.toUpperCase() + '/' + datePayment; e.defaultPrevented(); e.stopPropagation();}}>
                                    <td>{restaurant.name}</td>
                                    <td>{Formatcurrency(restaurant.amount)} USD</td>
                                    <td onClick={(e)=>{e.stopPropagation(); }}>
                                        <StatusSelectComponent status={restaurant.status} id={restaurant.payment_eid} changestatus={UpdatePaymentsStatus} open={open} setOpen={setOpen} enabled={DateNow()}/>
                                    </td>
                                    <td style={{textAlign:"end"}} onClick={(e)=>{e.stopPropagation(); }}>
                                        <SwitchComponent handle={handleStatusRestaurant} idRestaurant={restaurant.restaurant_eid} checked={restaurant.active}/>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    }
                </table>
            </div>
            <div className="content-pagination">
                <div className="content-select">
                    <span className="label">
                        Filas:&nbsp;&nbsp;
                    </span>
                    <select onChange={handleRowsChange} className="pagination-select">
                        <option selected={rowsPerPage===5} value="5">5</option>
                        <option selected={rowsPerPage===25} value="25">25</option>
                        <option selected={rowsPerPage===100} value="100">100</option>
                    </select>
                </div>
                <div className="content-select-pagination">
                    <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                    <label className="label">de&nbsp;&nbsp;</label>
                    <label className="number">{total}</label>
                    <button className="button-angle" onClick={handleMovePageDown}>
                        <FontAwesomeIcon icon="angle-left" />
                    </button>
                    <button className="button-angle" onClick={handleMovePageUp}>
                        <FontAwesomeIcon icon="angle-right" />
                    </button>
                </div>
            </div>
            <ModalComponent id="modalReportDownload" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <img src="/images/iconoDescargaArchivo.svg" style={{
                                                                    height:'120px', 
                                                                    width:'120px'}}/>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"20px",
                                    color:'gray',
                                    marginTop:'60px'}}>
                                Descargamos tu archivo correctamente
                            </label>
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalReportDownload');}}>CERRAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );
    
    const movile = (restaurants) =>(
        <article>
            <div  class="search-input">
                <input onClick={openSearch} type="search" id="inputSearch" name="search" placeholder="Buscar" onKeyUp={(ev) => {
                    setSearch(ev.target.value);
                    setRefreshForce(Math.random());
                  }}/>
                <button onClick={closeSearch} id="buttonSearch" style={{visibility:"hidden"}} >x</button>
            </div>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '40%'
                         }}>
                COBROS
            </h5>
            <div class="downloadReport">
                <a onClick={(e) => {e.stopPropagation(); getReport();}}>
                    <img width="24" height="24" src="/images/download.svg"/>
                </a>
            </div>
            <ChargesMontComponent total={totalPayments} date={restaurant.paymentDate} commissions={commissionsPayments} down={getPaymentsMonthDown} up={getPaymentsMonthUp}/>
            <div style={{
                borderBottom: "1px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}></div> 
            <div class="list-group">
                {
                    restaurants.length == 0 ? <BackgroundInfo Type={backgrounInfo}/> :
                    restaurants.map( restaurant =>(
                        <a class="list-group-item list-group-item-action" onClick={ (e) => { document.location = '/ChargeTo/Restaurant/' + restaurant.restaurant_eid + '/' + restaurant.name.toUpperCase() + '/' + datePayment; e.defaultPrevented(); e.stopPropagation();}}>
                            <div class="d-flex w-100 justify-content-between">
                                <label>{restaurant.name}</label>
                                <a onClick={(e)=>{e.stopPropagation(); }}><StatusSelectComponent status={restaurant.status} id={restaurant.payment_eid} changestatus={UpdatePaymentsStatus} open={open} setOpen={setOpen} enabled={DateNow()}/></a>
                                <a onClick={(e)=>{e.stopPropagation(); }}><SwitchComponent handle={handleStatusRestaurant} idRestaurant={restaurant.restaurant_eid} checked={restaurant.active}/></a>
                            </div>
                            <div class="d-flex w-100 justify-content-between">
                                <p>{restaurant.total}</p>
                            </div>
                        </a>
                    ))
                }
            </div>
            <div className="content-pagination">
                <div className="content-select">
                    <span className="label">
                        Filas:&nbsp;&nbsp;
                    </span>
                    <select onChange={handleRowsChange} className="pagination-select" defaultValue="5">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className="content-select-pagination">
                    <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                    <label className="label">de&nbsp;&nbsp;</label>
                    <label className="number">{total}</label>
                    <button className="button-angle" onClick={handleMovePageDown}>
                        <FontAwesomeIcon icon="angle-left" />
                    </button>
                    <button className="button-angle" onClick={handleMovePageUp}>
                        <FontAwesomeIcon icon="angle-right" />
                    </button>
                </div>
            </div>
            <ModalComponent id="modalReportDownload" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <img src="/images/iconoDescargaArchivo.svg" style={{
                                                                    height:'120px', 
                                                                    width:'120px'}}/>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"20px",
                                    color:'gray',
                                    marginTop:'60px'}}>
                                Descargamos tu archivo correctamente
                            </label>
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalReportDownload');}}>CERRAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );

    return (
        isDesktopOrLaptop ? desktop(restaurants) : movile(restaurants)
    )
}

export default ChargesRestaurantsList
