import AuthenticationService from "../AuthenticationService";
import BackendService from '../../services/BackendService';
const baseApiUrl = BackendService.getBasepath();
class ClientsService {
    
    static getClientsApplication(page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/clients/application/all?page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getClientsApplicationSearchByName(name, page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/clients/application/search?name=${name}&page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getClientsRejected(page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/clients/rejected/all?page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getClientsRejectedSearchByName(name, page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/clients/rejected/search?name=${name}&page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getClientsAccepted(page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/clients/accepted/all?page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getClientsAcceptedSearchByName(name, page, itemsPerPage, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/clients/accepted/search?name=${name}&page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static addClientAccept(idUser)
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify({ client_eid : idUser })
        };
    
        return fetch(`${baseApiUrl}/founder/clients/accept`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data;
                }
                else
                {
                    return null;
                }
            });
    }

    static addClientReject(idUser)
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify({ client_eid : idUser })
        };
    
        return fetch(`${baseApiUrl}/founder/clients/reject`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data;
                }
                else
                {
                    return null;
                }
            });
    }

    static addClientDelete(idUser)
    {
        const requestOptions = {
            method: 'DELETE',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };
    
        return fetch(`${baseApiUrl}/founder/clients/delete/${idUser}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data;
                }
                else
                {
                    return null;
                }
            });
    }

    static sendInvitation(model)
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                { 
                    email : model.Email,
                    name : model.Name,
                    first_lastname : model.Lastname,
                    second_lastname : model.SecondLastname,
                    cell_phone : model.CellPhone,
                    collaborator : model.colab,
                    friend : model.friend
                })
        };
    
        return fetch(`${baseApiUrl}/founder/invitation`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data;
                }
                else
                {
                    return null;
                }
            });
    }

    static addClientFriend(idUser)
    {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
            { 
                client_eid : idUser, 
                status : 1 
            })
        };
    
        return fetch(`${baseApiUrl}/founder/clients/friend`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data;
                }
                else
                {
                    return null;
                }
            });
    }

    static getClientsReservation(page, itemsPerPage, clientId, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/clients/reservation/list/${clientId}/all?page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getClientsReservationSearchByName(name, clientId, itemsPerPage, page, order = "") {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/clients/reservation/list/${clientId}/search?name=${name}&page=${page}&pagesize=${itemsPerPage}&filter=name&order=${order}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static putClientsStatus(clientId, status) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                { 
                    client_eid : clientId, 
                    status : status 
                })
        };

        return fetch(`${baseApiUrl}/founder/clients/active`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }


}
export default ClientsService;