import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';

import './fonts/fonts.jsx'
import './scss/site.scss'

Sentry.init({
  dsn: "https://0f46f7f3c5e346cd9e099aa9cc4c9b69@o1066355.ingest.sentry.io/6056806",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
