import React from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect, Link} from 'react-router-dom'
import ClientsPageRequestedList from './clientspageRequestedList'
import ClientsPageRejectedList from './clientspageRejectedList'
import ClientsAcceptedList from './clientspageAcceptedList'
import SendClientInvitation from './SendClientInvitation'
import ClientsPageReservations from './clientspageReservations'

const ClientsPage = () => {
    return (
        <Router>
            <Switch>
                <Route path="/Clients/Accepted" component={ClientsAcceptedList}/>
                <Route path="/Clients/Rejected" component={ClientsPageRejectedList}/>
                <Route path="/Clients/SendInvitation" component={SendClientInvitation}/>
                <Route path="/Clients/Reservations/:name/:id" component={ClientsPageReservations}/>
                <Route component={ClientsPageRequestedList}/>
            </Switch>
        </Router>
    )
}

export default ClientsPage
