import AuthenticationService from '../../services/AuthenticationService';
import BackendService from '../../services/BackendService';
const baseApiUrl = BackendService.getBasepath();
const token = '';
class CatalogsService {


    static async getAllergy() {
        let alergy = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/allergy`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            alergy = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return alergy;
    }

    static async getCategories() {
        let categories = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/category`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            categories = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return categories;
    }


    static async getClientStatus() {
        let clientStatus = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/client-status`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            clientStatus = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return clientStatus;
    }

    static async getCollaborator() {
        let collaborator = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/collaborator`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            collaborator = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return collaborator;
    }

    static async getCost(token) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${token}` }
        };

        return fetch(`${baseApiUrl}/catalogs/cost`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static async getCountry() {
        let country = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/country`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            country = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return country;
    }

    static async getDays() {

        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/catalogs/day`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }


    static async getDrink() {
        let drink = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/drink`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            drink = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return drink;
    }

    static async getExtraService(token) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${token}` }
        };

        return fetch(`${baseApiUrl}/catalogs/extra-service`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static async getFood() {
        let food = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/food`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            food = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return food;
    }


    static async getGender() {
        let gender = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/gender`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            gender = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return gender;
    }

    static async getIndustry() {
        let industry = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/industry`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            industry = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return industry;
    }


    static async getKitchen(token) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${token}` }
        };

        return fetch(`${baseApiUrl}/catalogs/kitchen`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static async getZone(token) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${token}` }
        };

        return fetch(`${baseApiUrl}/catalogs/zone`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static async getNutritionalRequirement() {
        let nutritionalRequirement = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/nutritional-requirement`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            nutritionalRequirement = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return nutritionalRequirement;
    }

    static async getProfession() {
        let profession = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/profession`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            profession = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return profession;
    }

    static async getReservationStatus() {
        let reservationStatus = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/reservation-status`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            reservationStatus = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return reservationStatus;
    }

    static async getRole() {
        let role = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/role`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            role = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return role;
    }

    static async getSocialMedia() {
        let socialMedia = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/social-media`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            socialMedia = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return socialMedia;
    }

    static async getTable() {
        let table = [];
        try {
            const response = await fetch(`${baseApiUrl}/catalogs/table`,
                {
                    method: "GET",
                    headers: { "Authorization": `Bearer ${token}` }
                }
            );
            table = await response.json();
        }
        catch (error) {
            console.error(error);
        }
        return table;
    }

    static async getTableType() {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/catalogs/table-type`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }
}
export default CatalogsService;