import React from 'react'
import { useMediaQuery } from 'react-responsive'

const ChargesMontComponent = (props) => {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const [monthName, setMonthName] = React.useState("");
    const [monthStep, setMonthStep] = React.useState(0);

    function upMonth(){
        if(monthStep < 11)
        {
            let temp = monthStep + 1;
            let name = getMonthName(temp);
            setMonthName(name);
            setMonthStep(temp);
            props.up(monthStep + 1)
        }
        else{
            setMonthName("ENERO");
            setMonthStep(0);
            props.up(monthStep+1);
        }
    }
    
    function downMonth(){
        if(monthStep >= 1 )
        {
            let temp = monthStep - 1;
            let name = getMonthName(temp);
            setMonthName(name);
            setMonthStep(temp);
            props.down(monthStep)
        }
        else{
            setMonthName("DICIEMBRE");
            setMonthStep(11);
            props.down(monthStep - 1);
        }
    }

    function getMonthName(monthNumber){
        let months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
        return months[monthNumber];
    }

    function Formatcurrency(params) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
          })

          return formatter.format(params)
    }


    React.useEffect(() => {
        let newDate = new Date();
        let month = 0; 
        if(props.date !== undefined)
        {
            let tempMonth = props.date.substring(5, 7);
            month = Number(tempMonth) - 1;
        }
        else
        {
            month = newDate.getMonth();
        }
        let monthNow = getMonthName(month);
        setMonthStep(month);
        setMonthName(monthNow);
    },[])

    const desktop = (props)=>(
        <div style={{display: "flex", alignItems: "center", justifyContent:'space-between', padding: "29px 40px"}}>
            <div style={{display:'flex', width:'900px',alignItems: "center"}}>
                <label style={{
                    paddingRight: "40px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "black"}}>
                    {"COBROS " + (props.name !== undefined ? props.name+" " : " " ) + monthName} 
                </label>
                <div style={{display:'flex',alignItems:'center', justifyContent:'space-between', width:'60px'}}>
                    <div style={{}}>
                        <label onClick={()=>{downMonth();}}>
                            <img src="/images/backicon.svg" alt="backicon" style={{width:'30px', height:'30px'}}/>
                        </label>
                    </div>
                    <div style={{transform:'rotate(180deg)'}}>
                        <label onClick={()=>(upMonth())}>
                            <img src="/images/backicon.svg" alt="backicon" style={{width:'30px', height:'30px'}}/>
                        </label>
                    </div>
                </div>
            </div>
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', fontSize:'20px', fontFamily:'Commuters Sans SemiBold', width:'auto'}}>
                <label style={{height:'25px'}}>{props.name !== undefined ? "INGRESO TOTAL :" : "TOTAL :"}</label>
                <label style={{height:'25px', color:'#808080', width:'max-content'}}>COMISIONES TOTALES :</label>
            </div>
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', fontSize:'20px', fontFamily:'Commuters Sans SemiBold', width:'auto'}}>
                <label style={{height:'25px'}}>{Formatcurrency(props.total)}</label>
                <label style={{height:'25px', color:'#808080'}}>{Formatcurrency(props.commissions)}</label>
            </div>
        </div>
    );
    
    const movile = (props) =>(
        <div style={{paddingTop:'26px', paddingBottom:'20px', paddingLeft:'9px', paddingRight:'24px'}}>
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <img src="/images/chevron-left.svg" alt="chevron-left" style={{width:'24.4px', height:'48.8px'}} onClick={()=>(downMonth())}/>
                <label style={{
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    color: "black"}}>
                    {(props.name !== undefined ? "COBROS " + props.name : monthName)} 
                </label>
                <img src="/images/chevron-right.svg" alt="chevron-right" style={{width:'24px', height:'48px'}} onClick={()=>(upMonth())}/>
            </div>
            <div style={{width:'100%', paddingTop:'18px', fontSize:'20px', fontFamily:'Commuters Sans SemiBold', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                    <label style={{height:'25px', marginLeft:'5px'}}>INGRESO TOTAL:</label>
                    <label style={{width:'263px', height:'25px', color:'#808080'}}>COMISIONES TOTALES:</label>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                    <label style={{height:'25px', color:'#808080'}}>${props.total}.00</label>
                    <label style={{ height:'25px'}}>${props.commissions}.00</label>
                </div>
            </div>
        </div>
    );

    return (
        isDesktopOrLaptop ? desktop(props) : movile(props)
    )
}

export default ChargesMontComponent
