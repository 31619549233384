import AuthenticationService from '../services/AuthenticationService';
import { toast } from 'react-toastify'

const BackendService = {
    login,
    forgotPassword,
    resetPassword,
    getBasepath
};

const roleFounders = ["admin"];

function error(message) {
    toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
}

function getBasepath(){
    switch (process.env.REACT_APP_ENV) {
        case "PROD":
            return process.env.REACT_APP_BACKEND_PROD;
        case "QA":
            return process.env.REACT_APP_BACKEND_QA;
        default:
            return process.env.REACT_APP_BACKEND_DEV;
    }
}

function getCurrentToken(){
    return AuthenticationService.currentUser.accessToken;
}

function login(email,password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    if(!navigator.onLine)
    {
        error("No se detecta una red estable");
        throw new Error("Offline");
    }

    return fetch(getBasepath() + '/login', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.success == 1 && roleFounders.includes(data.data.role))
            {
                return data.data;
            }
            else
            {
                return null;
            }
        });
}

function forgotPassword(email){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };
    
    if(!navigator.onLine)
    {
        error("No se detecta una red estable");
        throw new Error("Offline");
    }

    return fetch(getBasepath() + '/forgot-password', requestOptions)
        .then(response => response.json())
        .then(data => {
            return data;
        });
}

function resetPassword(reset_token, password, password_confirmation){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ reset_token, password, password_confirmation })
    };
    
    if(!navigator.onLine)
    {
        error("No se detecta una red estable");
        throw new Error("Offline");
    }

    return fetch(getBasepath() + '/reset-password', requestOptions)
        .then(response => response.json())
        .then(data => {
            return data;
        });
}

export default BackendService;