import React from 'react'
import { Link } from 'react-router-dom'
import CheckBoxComponent from '../components/CheckBoxComponent'
import DropDowComponent from '../components/DropDowComponent'
import { useMediaQuery } from 'react-responsive'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextBoxComponent from '../components/TextboxComponent'
import BackgroundInfo from '../components/BackgroundInfo'
import ModalComponent, { ModalFunctions } from '../components/ModalComponent'
import ClientsServices from '../services/founder/ClientsServices'
import AuthenticationService from '../services/AuthenticationService'
import ReportsService from '../services/founder/ReportsService'

import Toast from 'react-bootstrap/Toast'
import { toast } from 'react-toastify'

const ClientsPageAcceptedList = () => {
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [total,setTotal] = React.useState(0);
    const [firstRow,setFirstRow] = React.useState(0);
    const [lastRow,setLastRow] = React.useState(0);
    const [lastPage,setLastPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [users, setUsers] = React.useState([]);
    const [refreshForce, setRefreshForce] = React.useState([]);
    const [backgrounInfo,setBackgrounInfo] = React.useState("");
    const [removeClient,setRemoveClient] = React.useState("");
    const [order, setOrder] = React.useState("");
    const [photo, setPhoto] = React.useState("");

    function notify() {
        toast.success('Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    function openSearch()
    {
    	document.getElementById("buttonSearch").style.visibility="visible";
	    document.getElementById('inputSearch').style.width="95%";
    }

    function closeSearch()
    {
        document.getElementById("buttonSearch").style.visibility="hidden";
        document.getElementById('inputSearch').value = '';
        document.getElementById('inputSearch').style.width="10%";
        setSearch("");
        getClientsAccepted();
    }

    function handleMovePageDown(e){
        if(page > 1){
            setPage(page-1);
            setRefreshForce(Math.random());
        }
        
    }

    function handleMovePageUp(e){
        if(page != lastPage){
            setPage(page+1);
            setRefreshForce(Math.random());
        }
    }

    function handleSearchChange(e){
        setSearch(e.target.value);
    }
    
    function handleRowsChange(e){
        setPage(1)
        setRowsPerPage(e.target.value);
    }

    function handleSearchOnEnter(e){
        setRefreshForce(Math.random());
    }

    function sowVisits(id, reservations){
        var element = document.getElementById("visits"+id);
        var visible = element.hasAttribute('hidden')
        if(!visible)
        {
            Hide(element)
        }
        else if(reservations !== 0)
        {
            Show(element)
        }
    }

    function Show(element){
        element.removeAttribute('hidden');
    }
    
    function Hide(element){
        element.setAttribute('hidden',element);
    }

    function showModal(client,id){
        if(!isDesktopOrLaptop){
            sowVisits(id);
        }
        setRemoveClient(client);
        ModalFunctions.ShowModal('modalRemoveClient');
    }

    function disableClient(encrypt_id){
        ClientsServices.putClientsStatus(encrypt_id, 0).then((r) =>{
            if(r.message == "Información guardada correctamente")
            {
                if(!isDesktopOrLaptop)
                {

                }
                else{
                    notify()
                }
                setRefreshForce(Math.random());
            }
        });
    }

    function enabledClient(encrypt_id){
        ClientsServices.putClientsStatus(encrypt_id, 1).then((r) =>{
            if(r.message == "Información guardada correctamente")
            {
                if(!isDesktopOrLaptop)
                {
                    
                }
                else{
                    notify()
                }
                setRefreshForce(Math.random());
            }
        });
    }

    function downloadReport(){
        ReportsService.getReportClientAccepted()
        .then(response => response.blob())
        .then(blob => {
            let link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "report.xlsx";
            link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
            link.remove();
            window.URL.revokeObjectURL(link.href);
        })
        .catch(err => console.error(err));
        ModalFunctions.ShowModal('modalReportDownload');
    }

    function getClientsAccepted() {
        ClientsServices.getClientsAccepted(page,rowsPerPage, order).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setUsers(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                    setLastPage(r.last_page);
                }
                else
                {
                    setUsers([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setLastPage(0);
                    setBackgrounInfo("Content");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
            
        });
    }

    function getClientsAcceptedSearch() {
        ClientsServices.getClientsAcceptedSearchByName(search,page,rowsPerPage,order).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setUsers(r.data);
                    setPage(r.data.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                }
                else
                {
                    setUsers([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setBackgrounInfo("Serch");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
            
        });
    }

    function orderInfo() {
        if(order === "" || order === "desc"){
            setOrder("asc");
        }
        else{
            setOrder("desc");
        }
        setRefreshForce(Math.random());
    }

    function showProfilePhoto(photo) {
        setPhoto(photo != null ? photo : "/images/profile.jpg")
        ModalFunctions.ShowModal('modalprofile');
    }

    React.useEffect(()=>{
        if(search == "")
        {
            getClientsAccepted()
        }
        else{
            getClientsAcceptedSearch()
        }
    },[rowsPerPage,refreshForce])

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = (users) =>(
        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    CLIENTES
                </label>
                <Link to="/Clients/SendInvitation" style={{
                    width:"284px",
                    height: "40px"
                }} className="btn btn-primary">ENVIAR INVITACIÓN</Link>
            </div>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "31px",
                textAlign: "left",
                padding: "0 24px"
            }}>
                <Link className="tabitem" to="/Clients">Solicitudes</Link>
                <Link className="tabitem selected" to="/Clients/Accepted">Aceptados</Link>
                <Link className="tabitem" to="/Clients/Rejected">Rechazados</Link>
            </div>
            <div style={{display: "flex", alignItems: "center", padding: "30px 40px"}}>
                <div style={{position:'relative', width:'320px'}}>
                    <TextBoxComponent onEnter={handleSearchOnEnter} onChange={handleSearchChange} placeholder="Búsqueda por nombre" type="text"/>    
                    <a style={{position:'absolute', right: "5px", top: "18px"}}>
                        <img src="/images/search.svg"/>
                    </a>
                </div>
                <div style={{"flex-grow": "1", textAlign:'end'}}>
                    <button className="puerta21-btn" type="button" style={{fontsize: "16px"}} onClick={downloadReport}>DESCARGAR REPORTE</button>
                </div>
            </div>
            <div style={{padding: "14px 40px 29px 40px", overflow: "auto", height: "calc(100% - 303px)",}}>
                <table className="table table-borderless table-hover" style={{
                    textAlign:'initial',
                    fontFamily:'CommutersSans'}}>
                    <thead>
                        <tr style={{ fontFamily:'Commuters Sans Thin', color:'rgba(0, 0, 0, 0.75)'}}>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px', width:'15%'}} onClick={(e)=>{e.stopPropagation(); orderInfo();}}>CLIENTE</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px', width:'15%'}}>NÚMERO DE RESERVAS</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px', width:'20%'}}>RESTAURANTES VISITADOS</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px', width:'15%'}}>COLABORACIÓN</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px', width:'15%'}}>INVITACIÓN</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px', width:'15%'}}>AMIGO</th>
                        </tr>
                    </thead>
                    {
                        users.length == 0 ? <tbody style={{display:'table-caption'}}><BackgroundInfo Type={backgrounInfo}/></tbody>:
                        <tbody>
                        {
                            users.map(user =>(
                                <tr id={user.encrypt_id} style={{height:'64px', fontFamily: 'Roboto', fontSize: '12px', verticalAlign: 'middle', color: user.active === 0 ? 'rgba(0, 0, 0, 0.4)' : '' }} onClick={ (e) => { document.location = '/Clients/Reservations/'+ user.first_name + " " + user.middle_name + " " + user.last_name + '/' + user.encrypt_id; e.defaultPrevented(); e.stopPropagation();}}>
                                    <td>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                            <a onClick={(e)=>{e.stopPropagation(); showProfilePhoto(user.photo)}} style={{cursor:'pointer'}}>
                                                <img src = {
                                                                user.photo != null ? user.photo : "/images/profile.jpg"
                                                        } 
                                                    style={{height: '34px',width: '26px',margin:'0 8px',borderTopLeftRadius: '17px',borderTopRightRadius: '17px',border: '0.5px solid rgba(0,0,0,0.4)'}}/>
                                            </a>
                                            <label htmlFor="">{user.first_name} {user.middle_name} {user.last_name}</label>
                                        </div>
                                    </td>
                                    <td>{user.reservations}</td>
                                    <td>
                                    {
                                        user.restaurants.map(visit =>(visit + ", "))
                                    }
                                    </td>
                                    <td>{user.collaborator}</td>
                                    <td>
                                    {
                                        user.invitations.map(invitation =>(invitation.first_name + " " + (invitation.last_name === null ? '': invitation.last_name) + ", "))
                                    }
                                    </td>
                                    <td onClick={(e)=>{e.stopPropagation();}}>
                                        <CheckBoxComponent style={{marginBottom:"20px"}} checked={Boolean(user.friend)}/>
                                    </td>
                                    <td style={{textAlign:"end"}} onClick={(e)=>{e.preventDefault(); e.stopPropagation();}}>
                                        <DropDowComponent idDrop={"dropUser"+user.encrypt_id} options={
                                            user.active === 0 ?
                                            [{
                                                onClick: (e) => {e.preventDefault(); e.stopPropagation(); enabledClient(user.encrypt_id);},
                                                page: "#",
                                                text: "HABILITAR"
                                            }]
                                            :
                                            [{
                                                onClick: (e) => {e.preventDefault(); e.stopPropagation(); disableClient(user.encrypt_id);},
                                                page: "#",
                                                text: "DESHABILITAR"
                                            }]
                                            }/>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    }
                </table>
            </div>
            <div className="content-pagination">
                <div className="content-select">
                    <span className="label">
                        Filas:&nbsp;&nbsp;
                    </span>
                    <select onChange={handleRowsChange} className="pagination-select">
                        <option selected={rowsPerPage===5} value="5">5</option>
                        <option selected={rowsPerPage===25} value="25">25</option>
                        <option selected={rowsPerPage===100} value="100">100</option>
                    </select>
                </div>
                <div className="content-select-pagination">
                    <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                    <label className="label">de&nbsp;&nbsp;</label>
                    <label className="number">{total}</label>
                    <button className="button-angle" onClick={handleMovePageDown}>
                        <FontAwesomeIcon icon="angle-left" />
                    </button>
                    <button className="button-angle" onClick={handleMovePageUp}>
                        <FontAwesomeIcon icon="angle-right" />
                    </button>
                </div>
            </div>
            <ModalComponent id="modalReportDownload" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <img src="/images/iconoDescargaArchivo.svg" style={{
                                                                    height:'120px', 
                                                                    width:'120px'}}/>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"20px",
                                    color:'gray',
                                    marginTop:'60px'}}>
                                Descargamos tu archivo correctamente
                            </label>
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalReportDownload');}}>CERRAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalprofile" semi2="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', width:'100%'}}>
                            <img src={photo} style={{ maxWidth:'450px', maxHeight:'450px'}}/>
                        </div>
                        <div style={{display:"flex",marginTop:"23px"}}>
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalprofile');}}>CERRAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );

    const movile = (users) =>(
        <article>
            <div  class="search-input">
                <input onClick={openSearch} type="search" id="inputSearch" name="search" placeholder="Buscar" onKeyUp={(ev) => {
                    setSearch(ev.target.value);
                    setRefreshForce(Math.random());
                  }}/>
                <button onClick={closeSearch} id="buttonSearch" style={{visibility:"hidden"}} >x</button>
            </div>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '40%'
                         }}>
                CLIENTES
            </h5>
            <a style={{position: "absolute", top:15, right:'5%' }} onClick={()=> window.location = "/Clients/SendInvitation"}>
                <img width="40" height="40" src="/images/plus-circle-fill.svg"/>
            </a>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "39px",
                textAlign: "center",
                padding: "0px 24px",
                marginTop:"8px"
            }}>
                <Link className="tabitem" to="/Clients" style={{paddingBottom:'16px'}}>SOLICITUDES</Link>
                <Link className="tabitem selected" to="/Clients/Accepted" style={{paddingBottom:'16px'}}>ACEPTADOS</Link>
                <Link className="tabitem" to="/Clients/Rejected" style={{paddingBottom:'16px'}}>RECHAZADOS</Link>
            </div>
            <div class="list-group">
                {
                    users.length === 0 ? <BackgroundInfo Type={backgrounInfo}/> :
                    users.map( user =>(
                        <a class="list-group-item list-group-item-action" onClick={ (e) => { document.location = '/Clients/Reservations/'+ user.first_name + " " + user.middle_name + " " + user.last_name + '/' + user.encrypt_id; e.defaultPrevented(); e.stopPropagation();}}>
                            <div style={{display:'flex', width:'100%'}}>
                                <div style={{marginTop:'19px', marginBottom:'20px', marginRight:'inherit'}}>
                                    <a onClick={(e)=>{e.stopPropagation(); showProfilePhoto(user.photo)}} style={{cursor:'pointer'}}>
                                        <img src={
                                                    user.photo != null ? user.photo : "/images/profile.jpg"
                                                 }  style={{height: '100px', width: '80px',margin:'0 8px',borderTopLeftRadius: '50px',borderTopRightRadius: '50px',border: '0.5px solid rgba(0,0,0,0.4)'}}/>
                                    </a>
                                </div>
                                <div style={{display:'flex', flexDirection:'column', marginTop:'30px', marginBottom:'20px', marginLeft:'1px', width:'100%', alignItems:'flex-start'}}>
                                    <div style={{display:'flex', width:'100%'}}>
                                        <div style={{display:'flex', width:'95%', flexWrap:'wrap'}}>
                                            <label style={{
                                                textAlign:'start',
                                                height: "25px",
                                                fontFamily: "Commuters Sans SemiBold",
                                                fontSize: "18px",
                                                fontWeight: 600,
                                                fontStretch: "normal",
                                                fontStyle: "normal",
                                                lineHeight: 1.25,
                                                letterSpacing: "normal",
                                                color: "black",
                                                marginRight:'10px'}}>{user.first_name}</label>
                                            <label style={{
                                                textAlign:'start',
                                                height: "25px",
                                                fontFamily: "Commuters Sans SemiBold",
                                                fontSize: "18px",
                                                fontWeight: 600,
                                                fontStretch: "normal",
                                                fontStyle: "normal",
                                                lineHeight: 1.25,
                                                letterSpacing: "normal",
                                                color: "black",
                                                marginRight:'10px'}}>{user.middle_name}</label>
                                            <label style={{
                                                textAlign:'start',
                                                height: "25px",
                                                fontFamily: "Commuters Sans SemiBold",
                                                fontSize: "18px",
                                                fontWeight: 600,
                                                fontStretch: "normal",
                                                fontStyle: "normal",
                                                lineHeight: 1.25,
                                                letterSpacing: "normal",
                                                color: "black"}}>{user.last_name}</label>
                                        </div>
                                        <a onClick={(e)=>{e.stopPropagation(); sowVisits(user.encrypt_id, user.reservations)}} style={{position:'relative', 
                                                                                                                    right: 0, 
                                                                                                                    bottom: 0}}><img src="/images/chevron-down.svg"/>
                                        </a>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}} onClick={(e)=>{e.stopPropagation();}}>
                                        <CheckBoxComponent text="Amigo" style={{}} checked={user.friend}/>
                                        <span style={{backgroundColor:'black', height: '6px', width:'6px', borderRadius: '6px', display: 'inline-block', verticalAlign:'middle', marginLeft:'10px', marginRight:'10px'}}>&nbsp;</span>
                                        <label style={{fontSize:'12px'}}>{user.reservations} Reservaciones</label>
                                    </div>
                                    <div>
                                        {
                                            user.collaborator === null ? <label></label> :
                                            <label style={{fontSize:'12px'}}>Colaboración {user.collaborator}</label>
                                        }
                                    </div>
                                    <div>
                                        {
                                            user.invitations.length === 0 ? <label></label> :                                         
                                            <label style={{fontSize:'12px'}}>Invitación de 
                                            {
                                                user.invitations.map(invitation =>(" " + invitation.first_name + " " + invitation.middle_name + " " + (invitation.last_name === null ? '' : invitation.last_name) + ", "))
                                            }
                                            </label>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div id={"visits"+user.encrypt_id} hidden>
                                <div style={{display:'flex', marginLeft:'9px'}}>
                                    <div style={{
                                            width: "0px",
                                            height: "100px",
                                            border: "solid 1px #000001",
                                            paddingLeft:"0px",
                                            paddingRight:"0px"
                                        }}>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'column', marginLeft:'20px', alignItems:'flex-start'}}>
                                        <label style={{marginBottom:'10px'}}>Restaurante visitado:</label>
                                        {
                                            user.restaurants.map(visit =>(
                                                <label>{visit}</label>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div style={{display:'flex', justifyContent:'flex-end'}}>
                                    <button className="btn" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); showModal(user.client, user.encrypt_id)}}>ELIMINAR</button>
                                </div>
                            </div>
                        </a>
                    ))
                }
            </div>
            <div className="content-pagination">
                <div className="content-select">
                    <span className="label">
                        Filas:&nbsp;&nbsp;
                    </span>
                    <select onChange={handleRowsChange} className="pagination-select" defaultValue="5">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className="content-select-pagination">
                    <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                    <label className="label">de&nbsp;&nbsp;</label>
                    <label className="number">{total}</label>
                    <button className="button-angle" onClick={handleMovePageDown}>
                        <FontAwesomeIcon icon="angle-left" />
                    </button>
                    <button className="button-angle" onClick={handleMovePageUp}>
                        <FontAwesomeIcon icon="angle-right" />
                    </button>
                </div>
            </div>
            <ModalComponent id="modalRemoveClient" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                width: "250px",
                                height: "100px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                marginBottom:'20px'}}>
                                ¿QUIERES ELIMINAR A {removeClient}?
                            </label>
                        </div>
                        <div style={{display:"flex",marginTop:"10px"}}>
                        <Link className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalRemoveClient')
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalRemoveClient');}}>SÍ, ELIMINAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ModalComponent id="modalprofile" semi2="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', width:'100%'}}>
                            <img src={photo} style={{ maxWidth:'320px', maxHeight:'320px'}}/>
                        </div>
                        <div style={{display:"flex",marginTop:"23px"}}>
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalprofile');}}>CERRAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );

    return (
        isDesktopOrLaptop ? desktop(users) : movile(users)
    )
}

export default ClientsPageAcceptedList
