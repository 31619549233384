import React from 'react';

export const ModalFunctions = {
  toggleModal,
  hideModal,
  showModal
};

class ModalComponent extends React.Component {
  render() {
    const { isOpen, onRequestClose, title, image, description } = this.props;

    return (
      <div className={`modal-container ${isOpen ? '' : 'd-none'}`} id="modal">
        <h2>{title}</h2>
        <img src={image} alt="Imagen del dato" />
        <p>{description}</p>
        <button onClick={onRequestClose}>Cerrar</button>
      </div>
    );
  }
}

function toggleModal(id) {
  const rootElement = document.getElementById('root');
  const showModal = rootElement.hasAttribute('showModal');

  if (showModal) {
    hideModal(id);
  } else {
    showModal(id);
  }
}

function hideModal(id) {
  const rootElement = document.getElementById('root');
  rootElement.removeAttribute('showModal');
  document.getElementById(id).classList.add('d-none');
}

function showModal(id) {
  const rootElement = document.getElementById('root');
  rootElement.setAttribute('showModal', id);
  document.getElementById(id).classList.remove('d-none');
}

export default ModalComponent;