import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import CheckBoxComponent from '../../components/CheckBoxComponent'
import RestaurantsService from '../../services/founder/RestaurantsServices'
import CatalogsService from '../../services/app/CatalogsService'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify'

const Puerta21Tooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }}/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#184371',
      color: 'white',
      maxWidth: 187,
      borderRadius:"0",
      fontFamily:"Roboto",
      fontSize:"12px",
      fontWeight:"300",
      padding:"11px 16px",
      border: '1px solid #dadde9',
    },
  }));

const RestaurantCapacity = () => {
    const [showTooltipBar, setShowTooltipBar] = React.useState(false);

    const handleTooltipClose = () => {
        setShowTooltipBar(false);
    };
  
    const handleTooltipOpen = () => {
        setShowTooltipBar(true);
    };


    const model ={
        id: "",
        name: "",
        description: {
            description: "",
            phone: "",
            cheff_name: ""
        },
        menu: "",
        address: {
            id: "",
            latitude: 0,
            longitude: 0,
            address: ""
        },
        cost: {
            id: "",
            name: ""
        },
        kitchens: [],
        extra_services: [],
        photos: [],
        capacity: [],
        schedule: []
    }

    const [bar,setBar] = React.useState(0);
    const [barCheck,setBarCheck] = React.useState(false);
    const [barra,setBarra] = React.useState(0);
    const [barraCheck,setBarraCheck] = React.useState(false);
    const [salon,setSalon] = React.useState(0);
    const [salonCheck,setSalonCheck] = React.useState(false);
    const [interior,setInterior] = React.useState(0);
    const [interiorCheck,setInteriorCheck] = React.useState(false);
    const [terraza,setTerraza] = React.useState(0);
    const [terrazaCheck,setTerrazaCheck] = React.useState(false);

    const [tableCatalog,setTableCatalog] = React.useState([]);
    const [restaurantId] = React.useState(useParams());
    const [restaurantInfo, setRestaurantInfo]= React.useState(model);
    const [change, setChange] = React.useState(false);

    function getRestaurantInfo(){
        RestaurantsService.getRestaurant(restaurantId.id).then((r) => {
            if(r != null)
            {
                setRestaurantInfo(r);
                let temp = r.capacity.find(x => x.type.name === "Bar");
                setBar(temp === undefined ? 0 : temp.capacity);
                setBarCheck(temp === undefined ? false : true);
                temp = r.capacity.find(x => x.type.name === "Barra");
                setBarra(temp === undefined ? 0 : temp.capacity);
                setBarraCheck(temp === undefined ? false : true);
                temp = r.capacity.find(x => x.type.name === "Salón privado");
                setSalon(temp === undefined ? 0 : temp.capacity);
                setSalonCheck(temp === undefined ? false : true);
                temp = r.capacity.find(x => x.type.name === "Interior");
                setInterior(temp === undefined ? 0 : temp.capacity);
                setInteriorCheck(temp === undefined ? false : true);
                temp = r.capacity.find(x => x.type.name === "Terraza");
                setTerraza(temp === undefined ? 0 : temp.capacity);
                setTerrazaCheck(temp === undefined ? false : true);
            }
            else
            {

            }
        });
    }

    function handleBar(e){
        if(barCheck && e.target.value > 0)
        {
            let val = Number(e.target.value);
            setBar(val);
        }
        else{
            setBar(0);
        }
        setChange(true);
    }

    function handleBarCheck(e) {
        setBarCheck(e.target.checked)
        setBar(0);
        setChange(true);
    }

    function handleBarra(e){
        if(barraCheck && e.target.value > 0)
        {
            let val = Number(e.target.value);
            setBarra(val);
        }
        else
        {
            setBarra(0);
        }
        setChange(true);
    }

    function handleBarraCheck(e) {
        setBarraCheck(e.target.checked)
        setBarra(0);
        setChange(true);
    }

    function handleSalon(e){
        if(salonCheck && e.target.value > 0)
        {
            let val = Number(e.target.value);
            setSalon(val);
        }
        else
        {
            setSalon(0);
        }
        setChange(true);
    }

    function handleSalonCheck(e) {
        setSalonCheck(e.target.checked)
        setSalon(0);
        setChange(true);
    }

    function handleInterior(e){
        if(interiorCheck && e.target.value > 0)
        {
            let val = Number(e.target.value);
            setInterior(val);
        }
        else
        {
            setInterior(0);
        }
        setChange(true);
    }

    function handleInteriorCheck(e) {
        setInteriorCheck(e.target.checked)
        setInterior(0);
        setChange(true);
    }

    function handleTerraza(e){
        if(terrazaCheck && e.target.value > 0)
        {
            let val = Number(e.target.value);
            setTerraza(val);
        }
        else
        {
            setTerraza(0);
        }
        setChange(true);
    }

    function handleTerrazaCheck(e) {
        setTerrazaCheck(e.target.checked)
        setTerraza(0);
        setChange(true);
    }

    function sumTotal()
    {
        var sum = bar+barra+salon+interior+terraza
        return sum
    }

    function putCapacity() {
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(change)
        {
            let model = {id:"", capacity:[]};
            let temp = {};
            model.id = restaurantId.id;
            if(bar != 0)
            {
                temp = tableCatalog.find(x => x.name === "Bar");
                model.capacity.push({table_type_eid: temp.encrypt_id, capacity: bar});
            }
            if(barra != 0)
            {
                temp = tableCatalog.find(x => x.name === "Barra");
                model.capacity.push({table_type_eid: temp.encrypt_id, capacity: barra});
            }
            if(salon != 0)
            {
                temp = tableCatalog.find(x => x.name === "Salón privado");
                model.capacity.push({table_type_eid: temp.encrypt_id, capacity: salon});
            }
            if(interior != 0)
            {
                temp = tableCatalog.find(x => x.name === "Interior");
                model.capacity.push({table_type_eid: temp.encrypt_id, capacity: interior});
            }
            if(terraza != 0)
            {
                temp = tableCatalog.find(x => x.name === "Terraza");
                model.capacity.push({table_type_eid: temp.encrypt_id, capacity: terraza});
            }
            RestaurantsService.putCapacity(model).then((r) => {
                if(r.success === 1 && r.message === "OK"){
                    notify()
                }
                else{
                    error("La capacidad es requerida")
                }
                setChange(false);
                getRestaurantInfo();
            });
        }
        
    }

    function notify() {
        toast.success('Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

      function error(message) {
        toast.error(message != undefined ? message : 'No se pudo actualizar', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    function getTableCatalog(){
        CatalogsService.getTableType().then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setTableCatalog(r);
                }
            }
        });
    }

    React.useEffect(()=>{
        getRestaurantInfo();
        getTableCatalog();
    },[]);

    const desktop = () =>(
        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    RESTAURANTE
                </label>
            </div>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "31px",
                textAlign: "left",
                padding: "0 24px"
            }}>
                <Link className="tabitem" to={`/Restaurants/Details/${restaurantId.id}`}>DESCRIPCIÓN</Link>
                <Link className="tabitem" to={`/Restaurants/Photographs/${restaurantId.id}`}>FOTOGRAFÍAS</Link>
                <Link className="tabitem selected" to={`/Restaurants/Capacity/${restaurantId.id}`}>CAPACIDAD</Link>
                <Link className="tabitem" to={`/Restaurants/Menu/${restaurantId.id}`}>MENÚ</Link>
                <Link className="tabitem" to={`/Restaurants/Hours/${restaurantId.id}`}>HORARIO</Link>
            </div>
            <div className="row" style={{display: "flex", padding: "29px 40px", height:'calc(100% - 150px)', overflow:'auto'}}>
                <div className="col-5" style={{textAlign: "left"}}>
                    <label style={{
                                    width: "358px",
                                    height: "25px",
                                    fontFamily: "Commuters Sans SemiBold",
                                    fontSize: "16px",
                                    fontWeight: 300,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.25,
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    "flex-grow": "1",
                                    color: "black",
                                    margin: '41px 57px 20px 0px'}}>
                        INGRESAR LA CAPACIDAD ACTUAL
                    </label>
                    <label style={{
                                color:'black',
                                width: '450px',
                                height: '64px',
                                margin: '20px 58px 0px 0Px',
                                fontfamily: 'Roboto-Light',
                                fontsize: '14px',
                                fontweight: '300',
                                fontstretch: 'normal',
                                fontstyle: 'normal',
                                lineheight: '1.14',
                                letterspacing: '0.35px'}}>
                        Elige las secciones que hay en el restaurante e indica cuántas personas pueden estar en el área.
                    </label>
                </div>
                <div style={{
                        width: "0px",
                        height: "499px",
                        margin: "45px 0px 70px",
                        opacity: "0.15",
                        border: "solid 0.5px rgba(200, 200, 200)",
                        paddingLeft:"0px",
                        paddingRight:"0px"
                    }}>
    
                </div>
                <div className="col-6" style={{paddingLeft:'40px', paddingTop:'74px', width:"58%", display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                    <div style={{display:'flex', paddingTop:'15px', paddingBottom:'15px', alignItems:'center'}}>
                        <div style={{"flex-grow": "1"}}>
                            <CheckBoxComponent checked={barCheck} onChange={handleBarCheck} text="Bar"/>
                        </div>
                        <div style={{display:'flex'}}>
                            <input type="number" min="0" placeholder="0" style={{width:'40px', height:'40px', textAlign:'center'}} value={bar} onChange={(e) =>{handleBar(e);}} readOnly/>
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', marginLeft:'16px'}}>
                                <img src="/images/chevron-up.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleBar({target:{value:bar+1}})}}/>
                                <img src="/images/chevron-down.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleBar({target:{value:bar-1}})}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', paddingTop:'15px', paddingBottom:'15px', alignItems:'center'}}>
                        <div style={{"flex-grow": "1", textAlign:"left"}}>
                            <CheckBoxComponent style={{display:"inline-block"}} checked={barraCheck} onChange={handleBarraCheck} text="Barra de bar"/>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <div style={{display:"inline-block", marginLeft:"10px"}}>
                                    <Puerta21Tooltip
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        onClose={handleTooltipClose}
                                        open={showTooltipBar}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        placement="top"
                                        title={
                                            <div>La barra de bar se encuentra frente al barman</div>
                                        }
                                    >
                                        <img src="/images/alert-circle-black.svg" alt="alert-circle-black" onClick={handleTooltipOpen}></img>
                                    </Puerta21Tooltip>
                                </div>
                            </ClickAwayListener>
                        </div>
                        <div style={{display:'flex'}}>
                            <input type="number" min="0" placeholder="0" style={{width:'40px', height:'40px', textAlign:'center'}} value={barra} onChange={(e) =>{handleBarra(e);}} readOnly/>
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', marginLeft:'16px'}}>
                                <img src="/images/chevron-up.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleBarra({target:{value:barra+1}})}}/>
                                <img src="/images/chevron-down.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleBarra({target:{value:barra-1}})}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', paddingTop:'15px', paddingBottom:'15px', alignItems:'center'}}>
                        <div style={{"flex-grow": "1"}}>
                            <CheckBoxComponent checked={salonCheck} onChange={handleSalonCheck} text="Salón privado"/>
                        </div>
                        <div style={{display:'flex'}}>
                            <input type="number" min="0" placeholder="0" style={{width:'40px', height:'40px', textAlign:'center'}} value={salon} onChange={(e) =>{handleSalon(e);}} readOnly/>
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', marginLeft:'16px'}}>
                                <img src="/images/chevron-up.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleSalon({target:{value:salon+1}})}}/>
                                <img src="/images/chevron-down.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleSalon({target:{value:salon-1}})}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', paddingTop:'15px', paddingBottom:'15px', alignItems:'center'}}>
                        <div style={{"flex-grow": "1"}}>
                            <CheckBoxComponent checked={interiorCheck} onChange={handleInteriorCheck} text="Interior"/>
                        </div>
                        <div style={{display:'flex'}}>
                            <input type="number" min="0" placeholder="0" style={{width:'40px', height:'40px', textAlign:'center'}} value={interior} onChange={(e) =>{handleInterior(e);}} readOnly/>
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', marginLeft:'16px'}}>
                                <img src="/images/chevron-up.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleInterior({target:{value:interior+1}})}}/>
                                <img src="/images/chevron-down.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleInterior({target:{value:interior-1}})}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', paddingTop:'15px', paddingBottom:'15px', alignItems:'center'}}>
                        <div style={{"flex-grow": "1"}}>
                            <CheckBoxComponent checked={terrazaCheck} onChange={handleTerrazaCheck} text="Terraza"/>
                        </div>
                        <div style={{display:'flex'}}>
                            <input type="number" min="0" placeholder="0" style={{width:'40px', height:'40px', textAlign:'center'}} value={terraza} onChange={(e) =>{handleTerraza(e);}} readOnly/>
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', marginLeft:'16px'}}>
                                <img src="/images/chevron-up.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleTerraza({target:{value:terraza+1}})}}/>
                                <img src="/images/chevron-down.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleTerraza({target:{value:terraza-1}})}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        borderBottom: "1px solid",
                        borderBottomColor:'rgba(200, 200, 200)',
                        height: "31px",
                        textAlign: "left",
                        padding: "0 24px"
                    }}></div>
                    <div style={{display:'flex', justifyContent:'flex-end', paddingTop:'35px', textAlign:'end'}}>
                        <label id="total" style={{fontSize:'37px', fontfamily:'Roboto-Light', marginRight:'10px'}}>Total:</label>
                        <label style={{fontSize:'40px'}}>{sumTotal()}</label>
                    </div>
                    <div style={{paddingTop:'20px', display:'flex', justifyContent:'flex-end'}}>
                        <button className="btn btn-lg mt-5" style={{width:'162px', boxShadow: 'none', fontFamily:'Commuters Sans SemiBold'}} onClick={() => {setChange(false); getRestaurantInfo()}}>
                            CANCELAR
                        </button>
                        <button className="btn btn-primary btn-lg mt-5" type="button" style={{width:'190px'}} onClick={() => {putCapacity()}}>
                            GUARDAR
                        </button>
                    </div>
                    {/* <div>
                        <button className="btn btn-primary" style={{marginTop:'25px'}} onClick={() =>(putCapacity())}>ACTUALIZAR CAPACIDAD</button>
                    </div> */}
                </div>
            </div>
        </article>
    );
    
    const movile = () =>(
        <article>
            <a style={{position: "absolute", top:25, left:'5%' }} onClick={()=> window.location = "/Restaurants" }>
                <img width="24" height="24" src="/images/backicon.svg"/>
            </a>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '35%'
                         }}>
                RESTAURANTE
            </h5>
            <div className="div-tab-item">
                <Link className="tabitem" to={`/Restaurants/Details/${restaurantId.id}`} style={{paddingBottom:'16px'}}>DESCRIPCIÓN</Link>
                <Link className="tabitem" to={`/Restaurants/Photographs/${restaurantId.id}`} style={{paddingBottom:'16px'}}>FOTOGRAFÍAS</Link>
                <Link className="tabitem selected" to={`/Restaurants/Capacity/${restaurantId.id}`} style={{paddingBottom:'16px'}}>CAPACIDAD</Link>
                <Link className="tabitem" to={`/Restaurants/Menu/${restaurantId.id}`} style={{paddingBottom:'16px'}}>MENÚ</Link>
                <Link className="tabitem" to={`/Restaurants/Hours/${restaurantId.id}`} style={{paddingBottom:'16px'}}>HORARIO</Link>
            </div>
            <div style={{display:'flex', flexDirection:'column', padding: "0px 40px"}}>
                <label style={{
                                width: "358px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "16px",
                                fontWeight: 300,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                margin: '38px 57px 20px 0px'}}>
                    INGRESAR LA CAPACIDAD ACTUAL
                </label>
                <label style={{
                            color:'black',
                            width: '358px',
                            height: '64px',
                            margin: '20px 58px 0px 0px',
                            fontfamily: 'Roboto-Light',
                            fontsize: '14px',
                            fontweight: '300',
                            fontstretch: 'normal',
                            fontstyle: 'normal',
                            lineheight: '1.14',
                            letterspacing: '0.35px',
                            textAlign: "left"}}>
                    Elige las secciones que hay en el restaurante e indica cuántas personas pueden estar en el área.
                </label>
            </div>
            <div style={{display:'flex', padding: "30px 40px 15px 40px", alignItems:'center'}}>
                <div style={{"flex-grow": "1"}}>
                    <CheckBoxComponent checked={barCheck} onChange={handleBarCheck} text="Bar"/>
                </div>
                <div style={{display:'flex'}}>
                    <input type="number" min="0" placeholder="0" style={{width:'40px', height:'40px', textAlign:'center'}} value={bar} onChange={(e) =>{handleBar(e);}} readOnly/>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', marginLeft:'16px'}}>
                        <img src="/images/chevron-up.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleBar({target:{value:bar+1}})}}/>
                        <img src="/images/chevron-down.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleBar({target:{value:bar-1}})}}/>
                    </div>
                </div>
            </div>
            <div style={{display:'flex', padding: "15px 40px 15px 40px", alignItems:'center'}}>
                <div style={{"flex-grow": "1", textAlign:"left"}}>
                                <CheckBoxComponent style={{display:"inline-block"}} checked={barraCheck} onChange={handleBarraCheck} text="Barra de bar"/>
                                <ClickAwayListener onClickAway={handleTooltipClose}>
                                    <div style={{display:"inline-block", marginLeft:"10px"}}>
                                        <Puerta21Tooltip
                                            PopperProps={{
                                                disablePortal: true,
                                            }}
                                            onClose={handleTooltipClose}
                                            open={showTooltipBar}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            placement="top"
                                            title={
                                                <div>La barra de bar se encuentra frente al barman</div>
                                            }
                                        >
                                            <img src="/images/alert-circle-black.svg" alt="alert-circle-black" onClick={handleTooltipOpen}></img>
                                        </Puerta21Tooltip>
                                    </div>
                                </ClickAwayListener>
                            </div>
                <div style={{display:'flex'}}>
                    <input type="number" min="0" placeholder="0" style={{width:'40px', height:'40px', textAlign:'center'}} value={barra} onChange={(e) =>{handleBarra(e);}} readOnly/>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', marginLeft:'16px'}}>
                        <img src="/images/chevron-up.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleBarra({target:{value:barra+1}})}}/>
                        <img src="/images/chevron-down.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleBarra({target:{value:barra-1}})}}/>
                    </div>
                </div>
            </div>
            <div style={{display:'flex', padding: "15px 40px 15px 40px", alignItems:'center'}}>
                <div style={{"flex-grow": "1"}}>
                    <CheckBoxComponent checked={salonCheck} onChange={handleSalonCheck} text="Salón privado"/>
                </div>
                <div style={{display:'flex'}}>
                    <input type="number" min="0" placeholder="0" style={{width:'40px', height:'40px', textAlign:'center'}} value={salon} onChange={(e) =>{handleSalon(e);}} readOnly/>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', marginLeft:'16px'}}>
                        <img src="/images/chevron-up.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleSalon({target:{value:salon+1}})}}/>
                        <img src="/images/chevron-down.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleSalon({target:{value:salon-1}})}}/>
                    </div>
                </div>
            </div>
            <div style={{display:'flex', padding: "15px 40px 15px 40px", alignItems:'center'}}>
                <div style={{"flex-grow": "1"}}>
                    <CheckBoxComponent checked={interiorCheck} onChange={handleInteriorCheck} text="Interior"/>
                </div>
                <div style={{display:'flex'}}>
                    <input type="number" min="0" placeholder="0" style={{width:'40px', height:'40px', textAlign:'center'}} value={interior} onChange={(e) =>{handleInterior(e);}} readOnly/>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', marginLeft:'16px'}}>
                        <img src="/images/chevron-up.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleInterior({target:{value:interior+1}})}}/>
                        <img src="/images/chevron-down.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleInterior({target:{value:interior-1}})}}/>
                    </div>
                </div>
            </div>
            <div style={{display:'flex', padding: "15px 40px 15px 40px", alignItems:'center'}}>
                <div style={{"flex-grow": "1"}}>
                    <CheckBoxComponent checked={terrazaCheck} onChange={handleTerrazaCheck} text="Terraza"/>
                </div>
                <div style={{display:'flex'}}>
                    <input type="number" min="0" placeholder="0" style={{width:'40px', height:'40px', textAlign:'center'}} value={terraza} onChange={(e) =>{handleTerraza(e);}} readOnly/>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', marginLeft:'16px'}}>
                        <img src="/images/chevron-up.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleTerraza({target:{value:terraza+1}})}}/>
                        <img src="/images/chevron-down.svg" style={{width:'15px', height:'15px'}} onClick={() => {handleTerraza({target:{value:terraza-1}})}}/>
                    </div>
                </div>
            </div>
            <div style={{
                        borderBottom: "2px solid #c8c8c8",
                        height: "31px",
                        textAlign: "left",
                        padding: "0 24px"
                    }}></div>
            <div style={{display:'flex', justifyContent:'flex-end', paddingTop:'35px', textAlign:'end', paddingRight:'50px'}}>
                <label style={{fontSize:'37px', fontfamily:'Roboto-Light', marginRight:'10px'}}>Total:</label>
                <label style={{fontSize:'40px'}}>{sumTotal()}</label>
            </div>
            <div style={{paddingTop:'20px', marginBottom:'25px'}}>
                <button className="btn btn-lg mt-5" style={{width:'162px', boxShadow: 'none', fontFamily:'Commuters Sans SemiBold'}} onClick={() => {setChange(false); getRestaurantInfo()}}>
                    CANCELAR
                </button>
                <button className="btn btn-primary btn-lg mt-5" type="button" style={{width:'190px'}} onClick={() => {putCapacity()}}>
                    GUARDAR
                </button>
            </div>
            {/* <div>
                <button className="btn btn-primary" style={{marginTop:'25px'}} onClick={() =>(putCapacity())}>ACTUALIZAR CAPACIDAD</button>
            </div> */}
        </article>
    );

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    return (
        isDesktopOrLaptop ? desktop() : movile()
    )
}

export default RestaurantCapacity
