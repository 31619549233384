import React, { useState } from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect, Link} from 'react-router-dom'
import CheckBoxComponent from '../components/CheckBoxComponent';
import TextBoxComponent from '../components/TextboxComponent';
import AuthenticationService from '../services/AuthenticationService';
import ModalComponent, { ModalFunctions } from '../components/ModalComponent'
import { useMediaQuery } from 'react-responsive'
import BackendService from '../services/BackendService';

const LoginPage = () => {
    return (
        <div className="login-container">
            <Router>
                <Switch>
                    <Route path="/login/Recovery" component={RecoveryForm}/>
                    <Route path="/" component={LoginForm} />
                </Switch>
            </Router>
            <Splash style={{zIndex:"1000"}}/>
        </div>
    )
}

const Splash = () => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const [isLoading, setIsLoading] = React.useState(true);
    setTimeout(() => {
        setIsLoading(false);
    }, 2600);
    return (
        isDesktopOrLaptop ?
            <div className={isLoading?"splash":"splash loaded"}>
                <div className="splash-left-panel">
                </div>
                <div className="splash-right-panel">
                </div>
                <div className="splash-separator"></div>
                <div className="render-img">
                    <img src="/images/logoSlash.png" title="logo" height="40"/>
                </div>
                <div className="splash-separator"></div>
            </div> :
            <div className={isLoading?"splash":"splash loaded"} style={{background: "black", justifyContent: "center", alignItems: "center"}}>
                <img src="/images/logoSlash.png" title="logo"/>
            </div>
        )
    }

const LoginForm = () => {
    let [email,setEmail] = useState("");
    let [emailValidation,setEmailValidation] = useState({});
    let [password,setPassword] = useState("");
    let [passwordValidation,setPasswordValidation] = useState({});
    let [passwordShower,setPasswordShower] = useState("password");
    let [errorLogin,setErrorLogin] = useState(false);

    function togglePassword(e){
        setPasswordShower(passwordShower == "password" ? "text" : "password");
    }

    function handleEmailChange(e){
        setEmail(e.target.value);
    }

    function handlePasswordChange(e){
        setPassword(e.target.value);
    }

    function handleLoginClick(e){
        setErrorLogin(false);
        if(emailValidation.hasError == undefined)
        {
            setEmailValidation({hasValidate:true,hasError:true,error:'Correo electrónico es requerido'});
        }

        if(passwordValidation.hasError == undefined)
        {
            setPasswordValidation({hasValidate:true,hasError:true,error:'Contraseña es requerida'});
        }

        if(email.length == 0 || password.length == 0)
        {
            return;
        }

        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let passwordError = true;
        let emailError = true;

        if(!emailRegex.test(String(email).toLowerCase()))
        {
            setEmailValidation({hasValidate:true,hasError:true,error:'El formato del correo no es correcto.'})
        }
        else
        {
            setEmailValidation({hasValidate:true,hasError:false})
            emailError = false;
        }

        if(password.length < 8)
        {
            setPasswordValidation({hasValidate:true,hasError:true,error:'La contraseña son por lo menos 8 caracteres.'})
        }
        else
        {
            setPasswordValidation({hasValidate:true,hasError:false})
            passwordError = false;
        }

        if(passwordError || emailError)
        {
            return;
        }

        AuthenticationService.login(email,password).then((r)=>{
            if(!r)
            {
                setEmailValidation({hasValidate:false})
                setPasswordValidation({hasValidate:false});
                setErrorLogin(true);
            }
        })
    }

    return (
        <div className="centred-form">
            <form>
                <img src="/images/puerta21_wordmark_02 (2)@2x.png" height="55" />
                <div style={{marginTop: "90px", width: "100%", flexGrow: "1"}}>
                    <TextBoxComponent style={{marginBottom:"15px"}} value={email} NoSpaces="true" {...emailValidation} onChange={handleEmailChange} onEnter={handleLoginClick} placeholder="Correo electrónico" type="email"/>
                    <TextBoxComponent value={password} NoSpaces="true" showEye="true" showEyeClick={togglePassword} {...passwordValidation} onEnter={handleLoginClick} onChange={handlePasswordChange} placeholder="Contraseña" type={passwordShower}/>
                    <CheckBoxComponent style={{marginTop: "30px"}} text="Mantener iniciada la sesión"/>
                </div>
                <div style={{display:errorLogin?"block":"none"}}>
                    <label class="text-danger" style={{fontSize:"0.8rem", marginBottom:"30px", marginTop:"20px"}}>El correo o la contraseña es incorrecta</label>
                </div>
                <div>
                    <button className="btn btn-primary" style={{marginBottom:"40px"}} type="button" onClick={handleLoginClick}>Ingresar</button>
                    <Link className="btn-link d-block regularLink" style={{fontSize:"11px", textAlign:"center"}} to="/login/recovery">¿Olvidáste tu contraseña?</Link>
                </div>
            </form>
        </div>
    );
}

const RecoveryForm = () => {
    let [email,setEmail] = useState("");
    let [emailPartial,setEmailPartial] = useState("");
    let [emailValidation,setEmailValidation] = useState({});

    function handleEmailChange(e){
        setEmail(e.target.value);
    }

    function handleRecoverClick(e){

        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(!emailRegex.test(String(email).toLowerCase()))
        {
            setEmailValidation({hasValidate:true,hasError:true,error:'El formato del correo no es correcto.'})
            return;
        }
        else
        {
            setEmailValidation({hasValidate:true,hasError:false})

            const firstAndLast = str => {
                const firstChar = str.substring(0,3);
                const lastChar = str.slice(-1);
                return [firstChar, lastChar]
            }
            
            const firstThird = str => {
                const strArray = str.split(".");
                const result = []
                for (let i = 0; i < (strArray[0].length + 1)/3; i++) {
                    result.push(str[i])
                }
                return [result.join(''), strArray[1]]
            }
            
            const strArray = email.split("@");
            const [userFirst, userLast] = firstAndLast(strArray[0])
            const [domainFirst, domainLast] = firstThird(strArray[1])
            setEmailPartial(`${userFirst}****${userLast}@${domainFirst}***.${domainLast}`);
            
            BackendService.forgotPassword(email).then((r)=>{
                console.log(r);
                if(r.data === null)
                {
                    setEmailValidation({hasValidate:true,hasError:true,error:r.message})
                }
                else{
                    ModalFunctions.ShowModal('mod_recoveryModal');
                }
            });
        }
    }

    function handleAcceptModalClick(e){
        window.location = "/";
    }

    return (
        <div className="centred-form">
            <form>
                <img src="/images/puerta21_wordmark_02 (2)@2x.png" height="55" />
                <div>
                    <label className="title" style={{ marginBottom: "60px" }}>RECUPERAR TU CONTRASEÑA</label>
                    <TextBoxComponent value={email} {...emailValidation} onEnter={handleRecoverClick} onChange={handleEmailChange} placeholder="Correo electrónico" type="email"/>
                </div>
                <div>
                    <button className="btn btn-primary" type="button" style={{marginBottom:"40px"}} onClick={handleRecoverClick}>Recuperar</button>
                    <Link className="btn-link d-block regularLink" to="/login">Ir al inicio de sesión</Link>
                </div>
            </form>
            <ModalComponent id="mod_recoveryModal" semi="1">
                <article>
                    <img src="/images/iconoMail.svg"/>
                    <div style={{marginBottom:"40px"}}>
                        <p>Enviamos las instrucciones para <br/> recuperar tu contraseña a {emailPartial}</p>
                    </div>
                    <div>
                        <button type="button" className="btn btn-primary" onClick={handleAcceptModalClick}>Aceptar</button>
                    </div>
                </article>
            </ModalComponent>
        </div>
    )
}

export default LoginPage
