import AuthenticationService from '../AuthenticationService.jsx';
import BackendService from '../../services/BackendService';
const baseApiUrl = BackendService.getBasepath();
class ReportsService {

    static getReportClientAccepted() {
        return fetch(`${baseApiUrl}/founder/clients/report/accepted/all`,
            {
                method: "GET",
                headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
            }
        );
    }

    static async getReportClientApplication() {
        return fetch(`${baseApiUrl}/founder/clients/report/application/all`,
            {
                method: "GET",
                headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
            }
        );
    }

    static async getReportClientRejected() {
        return fetch(`${baseApiUrl}/founder/clients/report/rejected/all`,
            {
                method: "GET",
                headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
            }
        );
    }

    static async getReportPayments(period) {
        return fetch(`${baseApiUrl}/founder/payments/report/${period}`,
            {
                method: "GET",
                headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
            }
        );
    }

    static async getReportPaymentsDetail(period, restaurantId) {
        return fetch(`${baseApiUrl}/founder/payments/report/detail/${restaurantId}/${period}`,
            {
                method: "GET",
                headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
            }
        );
    }

}
export default ReportsService;