import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import BackgroundInfo from '../../components/BackgroundInfo';
import ChargesMontComponent from '../../components/ChargesMontComponent'
import TextBoxComponent from '../../components/TextboxComponent';
import ModalComponent, { ModalFunctions } from '../../components/ModalComponent'
import PaymentsService from '../../services/founder/PaymentsService';
import { useParams } from 'react-router-dom';
import ReportsService from '../../services/founder/ReportsService';

const ChargeList = () => {

    const [restaurant] = React.useState(useParams());
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total,setTotal] = React.useState(0);
    const [firstRow,setFirstRow] = React.useState(0);
    const [lastRow,setLastRow] = React.useState(0);
    const [lastPage,setLastPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [charges, setCharges] = React.useState([]);
    const [refreshForce, setRefreshForce] = React.useState([]);
    const [backgrounInfo,setBackgrounInfo] = React.useState("");

    const [datePayment,setDatePayment] = React.useState(getDatePayment());
    const [commissionsPaymentsDetail, setCommissionsPaymentsDetail] = React.useState(0);    
    const [totalPaymentsDetail, setTotalPaymentsDetail] = React.useState(0);
    const [commissionRestaurant, setCommissionRestaurant] = React.useState(0);
    const [yearPaymentDetail, setYearPaymentDetail] = React.useState(0);

    function handleMovePageDown(e){
        if(page > 1){
            setPage(page-1);
            setRefreshForce(Math.random());
        }
        
    }

    function handleMovePageUp(e){
        if(page != lastPage){
            setPage(page+1);
            setRefreshForce(Math.random());
        }
    }

    function handleSearchChange(e){
        setSearch(e.target.value);
    }
    
    function handleRowsChange(e){
        setPage(1);
        setRowsPerPage(e.target.value);
    }

    function handleSearchOnEnter(e){
        setRefreshForce(Math.random());
    }

    function getDatePayment() {
        let data = new Date();
        let year = data.getFullYear();
        let month = data.getMonth()+1;
        return year + "-" + (month <= 9 ? "0" + month : month);
    }

    function getDetailTotal(date) {
        PaymentsService.getPaymentsDetailTotal(date, restaurant.restaurantId).then((r) =>{
            if(r != null){
                setTotalPaymentsDetail(r.total);
                setCommissionsPaymentsDetail(r.total_commissions)
            }
        });
    }

    function getPaymentsDetailMonthUp(monthstep) {
        let data = new Date();
        let year = yearPaymentDetail === 0 ? data.getFullYear() : yearPaymentDetail;
        if(monthstep < 12){
            let month = monthstep + 1;
            setDatePayment(year + "-" + (month <= 9 ? "0" + month : month));
            getPaymentsDetailList(year + "-" + (month <= 9 ? "0" + month : month));
        }
        else{
            setYearPaymentDetail(year + 1);
            setDatePayment((year + 1) + "-01");
            getPaymentsDetailList((year + 1) + "-01");
        }
    }

    function getPaymentsDetailMonthDown(monthstep) {
        let data = new Date();
        let year = yearPaymentDetail === 0 ? data.getFullYear() : yearPaymentDetail;
        if(monthstep > 0)
        {
            setDatePayment(year + "-" + (monthstep <= 9 ? "0" + monthstep : monthstep));
            getPaymentsDetailList(year + "-" + (monthstep <= 9 ? "0" + monthstep : monthstep));
        }
        else{
            setYearPaymentDetail(year - 1);
            setDatePayment((year - 1) + "-12");
            getPaymentsDetailList((year - 1) + "-12");
        }
    }

    function getDate(date) {
        let mont =["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ]
        let data = new Date(date.replace(/\s/, 'T'));
        if(data === undefined)
        {
            data = new Date();
        }
        return ( (data.getDate() >= 10 ? data.getDate():"0" + data.getDate()) + " " + mont[data.getMonth()] + " " + data.getFullYear() )
    }

    function getReportDetail(){
        ReportsService.getReportPaymentsDetail(datePayment)
        .then(response => response.blob())
        .then(blob => {
            let link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = datePayment + "_PaymentsDetailReport.xlsx";
            link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
            link.remove();
            window.URL.revokeObjectURL(link.href);
        })
        .catch(err => console.error(err));
        ModalFunctions.ShowModal('modalReportDownload');
    }

    function getPaymentsDetailList(dateSerch) {
        let dateService = dateSerch === undefined ? datePayment : dateSerch
        PaymentsService.getPaymentsDetailList(page,rowsPerPage,dateService,restaurant.restaurantId).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setCharges(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                    getDetailTotal(dateService);
                    setCommissionRestaurant(2)
                    setLastPage(r.last_page);
                }
                else
                {
                    setCharges([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setLastPage(0);
                    setBackgrounInfo("Payments");
                    getDetailTotal(dateService);
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }

    function getPaymentsDetailListSearch() {
        PaymentsService.getPaymentsDetailSearchByName(search,page,rowsPerPage,restaurant.paymentDate,restaurant.restaurantId).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setCharges(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                }
                else
                {
                    setCharges([]);;
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setBackgrounInfo("Serch");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }

    function Formatcurrency(params) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
            })

            return formatter.format(params)
    }

    React.useEffect(() => {
        if(search == "")
        {
            getPaymentsDetailList(restaurant.paymentDate);
        }
        else{
            getPaymentsDetailListSearch();
        }
    },[rowsPerPage,refreshForce]);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = (charges) =>(
        <article>
            <div style={{position:'absolute', left:'40px',top:'95px'}}>
                <a onClick={()=> window.location = "/ChargeTo/" + restaurant.paymentDate}>
                    <img width="24" height="24" src="/images/backicon.svg"/>
                    <label className="puerta21-btn" style={{fontFamily:'roboto', fontSize:'12px', marginLeft:'8px'}}>Volver</label>
                </a>
            </div>
            <ChargesMontComponent name={restaurant.restaurantName} date={restaurant.paymentDate} total={totalPaymentsDetail} commissions={commissionsPaymentsDetail} down={getPaymentsDetailMonthDown} up={getPaymentsDetailMonthUp}/>
            <div style={{
                borderBottom: "1px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}>
            </div>
            <div style={{display: "flex", alignItems: "center", padding: "30px 40px"}}>
                <div style={{position:'relative', width:'320px'}}>
                    <TextBoxComponent onEnter={handleSearchOnEnter} onChange={handleSearchChange} placeholder="Búsqueda por nombre" type="text"/>     
                    <a style={{position:'absolute', right: "5px", top: "18px"}}>
                        <img src="/images/search.svg"/>
                    </a>
                </div>
                <div style={{"flex-grow": "1", textAlign:'end'}}>
                    <button className="puerta21-btn" type="button" style={{fontsize: "16px"}} onClick={() => {getReportDetail();}}>DESCARGAR REPORTE</button>
                </div>
            </div>
            <div style={{
                borderBottom: "1px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}>
            </div>
            <div style={{padding: "14px 40px 29px 40px", overflow: "auto", height: "calc(100% - 303px)",}}>
                <table className="table table-borderless table-hover" style={{
                    textAlign:'initial',
                    fontFamily:'CommutersSans'}}>
                    <thead>
                        <tr style={{ fontFamily:'Commuters Sans Light', color:'rgba(0, 0, 0, 0.75)'}}>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>CLIENTE</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>FECHA DE RESERVACIÓN</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>NÚMERO DE PERSONAS</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>COSTO DE LA CUENTA</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>COMISIÓN</th>
                            <th scope="col" style={{fontSize:'10px', paddingBottom:'32px'}}>TOTAL</th>
                        </tr>
                    </thead>
                    {
                        charges.length == 0 ? <tbody style={{display:'table-caption'}}><BackgroundInfo Type={backgrounInfo}/></tbody>:
                        <tbody>
                        {
                            charges.map(charge =>(
                                <tr style={{height:'64px', fontFamily: 'Roboto', fontSize: '12px', verticalAlign: 'middle'}}>
                                    <td>
                                        <a>
                                            <img src="/images/profile.jpg" style={{height: '34px',width: '26px',margin:'0 8px',borderTopLeftRadius: '17px',borderTopRightRadius: '17px',border: '0.5px solid rgba(0,0,0,0.4)'}}/>
                                            {charge.client_name}
                                        </a>
                                    </td>
                                    <td>{getDate(charge.reservation_date)}</td>
                                    <td>{charge.people}</td>
                                    <td>{Formatcurrency(charge.bill)} USD</td>
                                    <td>{Formatcurrency(commissionRestaurant)} USD x {charge.people}</td>
                                    <td>{Formatcurrency(charge.people*commissionRestaurant)} USD</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    }
                </table>
            </div>
            <div className="content-pagination">
                <div className="content-select">
                    <span className="label">
                        Filas:&nbsp;&nbsp;
                    </span>
                    <select onChange={handleRowsChange} className="pagination-select">
                        <option selected={rowsPerPage===5} value="5">5</option>
                        <option selected={rowsPerPage===25} value="25">25</option>
                        <option selected={rowsPerPage===100} value="100">100</option>
                    </select>
                </div>
                <div className="content-select-pagination">
                    <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                    <label className="label">de&nbsp;&nbsp;</label>
                    <label className="number">{total}</label>
                    <button className="button-angle" onClick={handleMovePageDown}>
                        <FontAwesomeIcon icon="angle-left" />
                    </button>
                    <button className="button-angle" onClick={handleMovePageUp}>
                        <FontAwesomeIcon icon="angle-right" />
                    </button>
                </div>
            </div>
            <ModalComponent id="modalReportDownload" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <img src="/images/iconoDescargaArchivo.svg" style={{
                                                                    height:'120px', 
                                                                    width:'120px'}}/>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"20px",
                                    color:'gray',
                                    marginTop:'60px'}}>
                                Descargamos tu archivo correctamente
                            </label>
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <button className="btn btn-primary" type="button" onClick={() => {ModalFunctions.HideModal('modalReportDownload');}}>CERRAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );
    
    const movile = (charges) =>(
        <article>
            <a style={{position: "absolute", top:25, left:'5%' }} onClick={()=> window.location = "/ChargeTo/" + restaurant.paymentDate }>
                <img width="24" height="24" src="/images/backicon.svg"/>
            </a>
            <div style={{
                            margin:'0 25px 0 0',
                            position: 'absolute',
                            top: 28,
                            right: 0,
                            width: '85%'
            }}>
                <label style={{ 
                            "text-transform" : "uppercase",
                            fontFamily:'Commuters Sans SemiBold',
                            fontSize: '16px',
                            fontWeight: '600',
                            textAlign:'center',
                            alignItems:'center'
                            }}>
                        COBROS {restaurant.restaurantName}
                </label>
            </div>
            <div class="list-group" style={{overflowX:'hidden'}}>
                {
                    charges.length == 0 ? <BackgroundInfo Type={backgrounInfo}/> :
                    charges.map( charge =>(
                        <a class="list-group-item list-group-item-action">
                            <div style={{display:'flex', width:'100%'}}>
                                <div style={{marginTop:'19px', marginBottom:'20px', marginRight:'inherit'}}>
                                    <a>
                                        <img src={
                                                charge.photo != null ? charge.photo : "/images/profile.jpg"
                                        }
                                        style={{height: '99px',width: '75.7px',margin:'0 8px',borderTopLeftRadius: '50px',borderTopRightRadius: '50px',border: '0.5px solid rgba(0,0,0,0.4)'}}/>
                                    </a>
                                </div>
                                <div style={{display:'flex', flexDirection:'column', marginTop:'30px', marginBottom:'20px', marginLeft:'1px', width:'100%', alignItems:'flex-start'}}>
                                    <div style={{display:'flex', 
                                        width: "350px",
                                        height: "25px",
                                        textOverflow:'ellipsis',
                                        whiteSpace:'nowrap',
                                        overflow:'hidden',
                                        fontFamily: "Commuters Sans SemiBold",
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: 1.25,
                                        letterSpacing: "normal",
                                        color: "black"}}>{charge.client_name}</div>
                                    <div><label>{charge.people} personas</label></div>
                                    <div><label>{getDate(charge.reservation_date)}</label></div>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <label>Cuenta: {charge.bill}</label>
                                        <span style={{backgroundColor:'black', height: '9px', width:'9px', borderRadius: '9px', display: 'inline-block', verticalAlign:'middle', marginLeft:'5px', marginRight:'5px'}}>&nbsp;</span>
                                        <label>Comisión: {charge.people*commissionRestaurant}</label>
                                    </div>
                                    
                                </div>
                            </div>
                        </a>
                    ))
                }
            </div>
        </article>
    );

    return (
        isDesktopOrLaptop ? desktop(charges) : movile(charges)
    )
}


export default ChargeList
