import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@material-ui/core/Fade';

const DropDowComponent = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div style={props.style}>
            <button class="btn" type="button" onClick={handleClick} style={{boxShadow:'none'}}
            aria-controls="fade-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
                <img src={"/images/" + (props.image ?? "three-dots-vertical") + ".svg"} alt={props.image ?? "three-dots-vertical"} style={{height: (props.iconSize ?? "24px")}}/>
            </button>
            <Menu
                style={ props.style ?? {marginLeft: "-80px", marginTop: "-2px" }}
                id="fade-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="dropMenu userMenuPopop"
                TransitionComponent={Fade}>
                {
                    props.options.map(option =>(
                        <li style={{padding:"4px"}}><a class="dropdown-item" style={{textAlign:"right"}} className="btn-link regularLink" onClick={(e)=>{option.onClick(e); handleClose(e)}} href={option.page}>{option.text}</a></li>
                    ))
                }
            </Menu>
        </div>
        // <div class="dropdown">
        //     <button class="btn" type="button" aria-expanded="false" onClick={open} style={{boxShadow:'none'}}>
        //         <img src="/images/three-dots-vertical.svg" style={{height: "24px"}}/>
        //     </button>
        //     <ul class="dropdown-menu" id={props.idDrop} aria-labelledby="dropdownMenu" style={{borderColor:"black", borderRadius:"0px", textAlign:"end", borderWidth:"2px", marginLeft: "-120px", marginTop: "-45px" }}>
                // {
                //     props.options.map(option =>(
                //         <li><a class="dropdown-item" onClick={option.onClick} href={option.page}>{option.text}</a></li>
                //     ))
                // }
        //         {/* <li><a class="dropdown-item" href={props.page}>EDITAR</a></li>
        //         <li onClick={props.onClick2}><a class="dropdown-item" >{props.text2}</a></li> */}
        //     </ul>
        // </div>
    )
}

export default DropDowComponent
