import AuthenticationService from "../AuthenticationService";
import BackendService from '../../services/BackendService';
const baseApiUrl = BackendService.getBasepath();
class PaymentsService{

    static getPaymentsList(page, itemsPerPage, date) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/payments/list/${date}/all?page=${page}&pagesize=${itemsPerPage}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getPaymentsSearchByName(name, page, itemsPerPage, date) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/payments/list/${date}/search?name=${name}&page=${page}&pagesize=${itemsPerPage}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getPaymentsTotal(date) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/payments/total/${date}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getPaymentsDetailList(page, itemsPerPage, date, restaurantId) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/payments/detail/${restaurantId}/${date}/all?page=${page}&pagesize=${itemsPerPage}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getPaymentsDetailSearchByName(name, page, itemsPerPage, date, restaurantId) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/payments/detail/${restaurantId}/${date}/search?name=${name}&page=${page}&pagesize=${itemsPerPage}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static getPaymentsDetailTotal(date, restaurantId) {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/founder/payments/total/${restaurantId}/${date}`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static async getPaymentStatus() {

        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` }
        };

        return fetch(`${baseApiUrl}/catalogs/payment-status`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data.data
                }
                else
                {
                    return null
                }
            });
    }

    static async putPaymentChangeStatus(paymentId,statusId){

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${AuthenticationService.curentAccessToken}` },
            body: JSON.stringify(
                { 
                    payment_eid : paymentId, 
                    payment_status_eid : statusId
                })
        };

        return fetch(`${baseApiUrl}/founder/payments/status`, requestOptions)
            .then(responce => responce.json())
            .then(data => {
                if(data.success == 1)
                {
                    return data
                }
                else
                {
                    return null
                }
            });
    }
    
}
export default PaymentsService;