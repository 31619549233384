import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import PhotographService from '../../services/founder/PhotographService'
import RestaurantsServices from '../../services/founder/RestaurantsServices'
import ModalComponent, { ModalFunctions } from '../../components/ModalComponent'
import { toast } from 'react-toastify'

const RestaurantPhotographs = () => {

    const [restaurantId] = React.useState(useParams());
    const [loading,isLoading] = React.useState(true);
    const [photographs,setPhotographs] = React.useState([]);
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    React.useEffect(()=>{
        getPhotographs();
    },[]);


    function handlePhotographClick(e,i)
    {
        document.getElementById('filePhotographs'+i).click();
    }

    function handleDeleteClick(e,id)
    {
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        isLoading(true);
        PhotographService.removePhotograph(id,restaurantId)
            .then(res => res.json())
            .then(json => {
                notify(json.message);
                getPhotographs();
            });
    }

    function changeFileInput(file,id){
        uploadPhotography(file,id).then(()=>{
            getPhotographs();
        })
    }

    function uploadPhotography(file,id)
    {
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        return new Promise((solve, reject) => {
            if(file){
                isLoading(true);
                var allowedexts = ["jpg","png"]
                if(file.size > 10485760)
                {
                    error("Los archivos no pueden pesar más de 10MB");
                    solve();
                }
                else if(!allowedexts.includes(file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2)))
                {
                    error("Solo se permiten archivos con la extension '.png' y '.jpg'");
                    solve();
                }
                else {
                    PhotographService.postPhotograph(file,id,restaurantId)
                    .then(res => res.json())
                    .then(json => {
                        notify("La imagen se subió correctamente");
                        solve();
                    });
                }
            }
            else{
                solve();
            }
        });

        
    }

    function getPhotographs()
    {
        isLoading(true);
        RestaurantsServices.getRestaurant(restaurantId.id)
            .then(res => {
                setPhotographs(res.photos);
                isLoading(false);
            })
    }

    async function dropFiles(ev){
        ev.preventDefault();
        var filesDroped = [];
        if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    filesDroped.push(ev.dataTransfer.items[i].getAsFile());
                    }
            }
        } else {
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                filesDroped.push(ev.dataTransfer.files[i]);
            }
        }

        var leftAllowed = 20 - photographs.length;
        for (var i = 0; i < filesDroped.length; i++) {
            if(i+1 > leftAllowed)
            {
                error("No se pueden subir más de 20 fotos");
            }
            else{
                await uploadPhotography(filesDroped[i],i);
            }
        }
        getPhotographs();
    }

    function notify(message) {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
    }

    function error(message) {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
    }

    function dragOverFiles(e,r){
        e.preventDefault();
    }

    const divsPhotographs = [];
    for (let i = 0; i < 20; i++) {
        if(photographs[i] != null)
        {
            divsPhotographs.push(
            // <div className="col-2" key={"divsPhotographs"+i.toString()} id={"divsPhotographs"+i.toString()} onClick={(e) => ModalFunctions.ShowModal("confirmModal"+i.toString())} style={{display:'flex', cursor:'pointer', flexDirection:'column', alignItems:'center', justifyContent:'center', margin:'8px 6px', backgroundColor:'rgba(0, 0, 0, 0.07)', width:'115px', height:'115px'}}>
            //     <div className="display-onHover" style={{width:'115px', height:'115px', "position": "fixed", "background": "rgba(0,0,0,0.5)"}}>
            //         <img src={"/images/closeIcon.svg"} style={{width:'25px', height:'25px', "position": "absolute", "right": "5px", "top": "5px"}}/>
            //     </div>
            //     <img src={photographs[i].photo} style={{width:'115px', height:'115px'}}/>
            //     <ModalComponent key={"confirmModal"+i.toString()} id={"confirmModal"+i.toString()} semi="1">
            //         <article style={{padding:"23px"}}>
            //             <h4 style={{fontFamily:"Commuters Sans SemiBold", width: "300px", marginRight: "auto", textTransform:"uppercase",textAlign:"left", fontSize:"20px"}}>
            //                     ¿Estás seguro de querer eliminar la foto?
            //             </h4>
            //             <div style={{display:"flex",marginLeft: "auto",marginTop:"115px"}}>
            //                 <Link className="btn-link" type="button" onClick={(e)=>{ e.stopPropagation(); ModalFunctions.HideModal("confirmModal"+i.toString())}} style={{color:"black", margin: "15px"}}>No</Link>
            //                 <button className="btn btn-primary" type="button" onClick={(e) => handleDeleteClick(e,photographs[i].id)}>Sí, Eliminar</button>
            //             </div>
            //         </article>
            //     </ModalComponent>
            // </div>
            <div className='col-2' style={{ width:'115px', height:'115px', display:'flex', cursor:'pointer', flexDirection:'column', alignItems:'center', justifyContent:'center', margin:'8px 6px', backgroundColor:'rgba(0, 0, 0, 0.07)', padding:'0px'}}>
                <div key={"divsPhotographs"+i.toString()} id={"divsPhotographs"+i.toString()} onClick={(e) => ModalFunctions.ShowModal("confirmModal"+i.toString())} style={{height:'115px', width:'115px', position: 'relative'}}>
                    <img src={photographs[i].photo} style={{width:'100%', height:'100%'}}/>
                    <div className="display-onHover" style={{position:'absolute', top:0, left:0, width:'100%', height:'100%', background:'rgba(0,0,0,0.5)', color: '#ffffff'}}>
                        <img src={"/images/closeIcon.svg"} style={{width:'25px', height:'25px', "position": "absolute", "right": "5px", "top": "5px"}}/>
                    </div>
                </div>    
                <ModalComponent key={"confirmModal"+i.toString()} id={"confirmModal"+i.toString()} semi="1">
                    <article style={{padding:"23px"}}>
                        <h4 style={{fontFamily:"Commuters Sans SemiBold", width: "300px", marginRight: "auto", textTransform:"uppercase",textAlign:"left", fontSize:"20px"}}>
                                ¿Estás seguro de querer eliminar la foto?
                        </h4>
                        <div style={{display:"flex",marginLeft: "auto",marginTop:"115px"}}>
                            <Link className="btn-link" type="button" onClick={(e)=>{ e.stopPropagation(); ModalFunctions.HideModal("confirmModal"+i.toString())}} style={{color:"black", margin: "15px"}}>No</Link>
                            <button className="btn btn-primary" type="button" onClick={(e) =>{ ModalFunctions.HideModal("confirmModal"+i.toString()); handleDeleteClick(e,photographs[i].id)}}>Sí, Eliminar</button>
                        </div>
                    </article>
                </ModalComponent>
            </div>
            )
        }
        else{
            divsPhotographs.push(
            <div className="col-2" id={"divsPhotographs"+i.toString()} onClick={(e) => handlePhotographClick(e,i)} style={{display:'flex', cursor:'pointer', flexDirection:'column', alignItems:'center', justifyContent:'center', margin:'8px 6px', backgroundColor:'rgba(0, 0, 0, 0.07)', width:'115px', height:'115px'}}
                    onDrop={dropFiles}
                    onDragOver={dragOverFiles}
                    draggable="true">
                <input type="file" id={"filePhotographs"+i.toString()} style={{display:"none"}} onChange={(e) => changeFileInput(e.target.files[0],i)}></input>
                <img src={"/images/PlusIcon.svg"} style={{width:'25px', height:'25px'}}/>
                <label style={{fontFamily:'Roboto', fontSize:'11px', marginTop:'8px'}}>Cargar fotografia</label>
            </div>);
        }
    }  

    const desktop = () =>(
        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    RESTAURANTE
                </label>
            </div>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "31px",
                textAlign: "left",
                padding: "0 24px"
            }}>
                <Link className="tabitem" to={`/Restaurants/Details/${restaurantId.id}`}>DESCRIPCIÓN</Link>
                <Link className="tabitem selected" to={`/Restaurants/Photographs/${restaurantId.id}`}>FOTOGRAFÍAS</Link>
                <Link className="tabitem" to={`/Restaurants/Capacity/${restaurantId.id}`}>CAPACIDAD</Link>
                <Link className="tabitem" to={`/Restaurants/Menu/${restaurantId.id}`}>MENÚ</Link>
                <Link className="tabitem" to={`/Restaurants/Hours/${restaurantId.id}`}>HORARIO</Link>
            </div>
            <div className="row" style={{display: "flex", padding: "29px 40px", height:'calc(100% - 150px)', overflow:'auto'}}>
                <div className="col-5" style={{textAlign: "left", marginTop:'36px'}}>
                    <label style={{
                                    width: "358px",
                                    height: "25px",
                                    fontFamily: "Commuters Sans SemiBold",
                                    fontSize: "16px",
                                    fontWeight: 300,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.25,
                                    letterSpacing: "normal",
                                    textAlign: "left",
                                    "flex-grow": "1",
                                    color: "black",
                                    margin: '38px 57px 20px 0px'}}>
                        AGREGA LAS FOTOGRAFÍAS DEL RESTAURANTE
                    </label>
                    <label style={{
                                color:'black',
                                width: '400px',
                                height: '64px',
                                margin: '20px 58px 0px 0Px',
                                fontfamily: 'Roboto-Light',
                                fontsize: '14px',
                                fontweight: '300',
                                fontstretch: 'normal',
                                fontstyle: 'normal',
                                lineheight: '1.14',
                                letterspacing: '0.35px'}}>
                        Para agregar las fotografías arrastralas de tu ordenador y sueltalas en la cuadrícula.
                    </label>
                    <label style={{
                                color:'black',
                                width: '358px',
                                height: '64px',
                                margin: '20px 58px 0px 0px',
                                fontfamily: 'Roboto-Light',
                                fontsize: '14px',
                                fontweight: '300',
                                fontstretch: 'normal',
                                fontstyle: 'normal',
                                lineheight: '1.14',
                                letterspacing: '0.35px'}}>
                        Puedes cargar hasta 20 fotografías.
                    </label>
                </div>
                <div className="col-6" style={{marginTop:'67px'}}>
                    <div className="row">
                        { 
                            loading ? 
                            <div>
                                Cargando...
                            </div> :
                            divsPhotographs
                        }
                    </div>
                </div>
            </div>
        </article>
    );
        
    const movile = () =>(
        <article>
            <a style={{position: "absolute", top:25, left:'5%' }} onClick={()=> window.location = "/Restaurants" }>
                <img width="24" height="24" src="/images/backicon.svg"/>
            </a>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '35%'
                         }}>
                RESTAURANTE
            </h5>
            <div className="div-tab-item">
                <Link className="tabitem" to={`/Restaurants/Details/${restaurantId.id}`} style={{paddingBottom:'16px'}}>DESCRIPCIÓN</Link>
                <Link className="tabitem selected" to={`/Restaurants/Photographs/${restaurantId.id}`} style={{paddingBottom:'16px'}}>FOTOGRAFÍAS</Link>
                <Link className="tabitem" to={`/Restaurants/Capacity/${restaurantId.id}`} style={{paddingBottom:'16px'}}>CAPACIDAD</Link>
                <Link className="tabitem" to={`/Restaurants/Menu/${restaurantId.id}`} style={{paddingBottom:'16px'}}>MENÚ</Link>
                <Link className="tabitem" to={`/Restaurants/Hours/${restaurantId.id}`} style={{paddingBottom:'16px'}}>HORARIO</Link>
            </div>
            <div style={{display:'flex', flexDirection:'column', padding: "29px 40px"}}>
                <label style={{
                                width: "358px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "16px",
                                fontWeight: 300,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                "flex-grow": "1",
                                color: "black",
                                margin: '38px 57px 20px 0px'}}>
                    AGREGA LAS FOTOGRAFÍAS DEL RESTAURANTE
                </label>
                <label style={{
                            color:'black',
                            width: '358px',
                            height: '64px',
                            margin: '20px 58px 0px 0px',
                            fontfamily: 'Roboto-Light',
                            fontsize: '14px',
                            fontweight: '300',
                            fontstretch: 'normal',
                            fontstyle: 'normal',
                            lineheight: '1.14',
                            letterspacing: '0.35px',
                            textAlign: "left"}}>
                    Puedes cargar hasta 20 fotografías.
                </label>
                <div className="row">
                    { 
                        loading ? 
                        <div>
                            Cargando...
                        </div> :
                        divsPhotographs
                    }
                </div>
            </div>
        </article>
    );

    return (
        isDesktopOrLaptop ? desktop() : movile()
    )
}


export default RestaurantPhotographs
