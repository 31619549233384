import {BrowserRouter as Router, Route} from 'react-router-dom'

import LoginPage from './pages/loginpage';
import HomePage from './pages/homepage';

import AuthenticationService from './services/AuthenticationService';

import 'bootstrap/dist/css/bootstrap.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretDown, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';

toast.configure()
library.add(faCaretDown, faAngleRight, faAngleLeft);

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentUser: AuthenticationService.curentUserValue
    };
  }

  componentDidMount() {

  }

  logout() {
    AuthenticationService.logout();
    document.location = '/login';
  }

  render() {
    const { currentUser } = this.state;
    return (
      <Router>
        {
          currentUser ?
          <Route component={HomePage}/>
          :
          <Route component={LoginPage}/>
        }
      </Router>
    )
  }

}

export default App;
