import React from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import RestaurantsList from './restaurantsList'
import NewRestaurant from './NewRestaurant'
import RestaurantDetails from './RestaurantPage/RestaurantDetails'
import RestaurantPhotographs from './RestaurantPage/RestaurantPhotographs'
import RestaurantCapacity from './RestaurantPage/RestaurantCapacity'
import RestaurantMenu from './RestaurantPage/RestaurantMenu'
import RestaurantHours from './RestaurantPage/RestaurantHours'

const Restaurantspage = () => {
    return (
        <Router>
            <Switch>
                <Route path="/Restaurants/NewRestaurant" component={NewRestaurant}/>
                <Route path="/Restaurants/Details/:id" component={RestaurantDetails}/>
                <Route path="/Restaurants/Photographs/:id" component={RestaurantPhotographs}/>
                <Route path="/Restaurants/Capacity/:id" component={RestaurantCapacity}/>
                <Route path="/Restaurants/Menu/:id" component={RestaurantMenu}/>
                <Route path="/Restaurants/Hours/:id" component={RestaurantHours}/>
                <Route component={() => <RestaurantsList/>}/>
            </Switch>
        </Router>
    )
}

export default Restaurantspage
