import React from 'react'
import {useParams, Link } from 'react-router-dom'
import CheckBoxComponent from '../../components/CheckBoxComponent'
import { useMediaQuery } from 'react-responsive'
import PriceComponent from '../../components/PriceComponent'
import ModalComponent, { ModalFunctions } from '../../components/ModalComponent'
import TextBoxComponent from '../../components/TextboxComponent'
import RestaurantsService from '../../services/founder/RestaurantsServices'
import CatalogsService from '../../services/app/CatalogsService'
import Select from 'react-select';
import { Rating } from 'react-simple-star-rating'
import { RatingView } from 'react-simple-star-rating'
import FieldEditable from '../../components/FieldEditable'
import OffersService from '../../services/founder/OffersService'

import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import DatePicker from "react-multi-date-picker"
import gregorianes from "react-date-object/locales/gregorian_es";
import { v4 as uuid } from 'uuid';
import {
    KeyboardTimePicker,
    KeyboardDatePicker,

} from '@material-ui/pickers';
import moment from 'moment';
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
  } from 'react-places-autocomplete';
import { toast } from 'react-toastify'
import IsSpecialCharacters from '../../services/UltiService'
import IsSpecialCharactersNotDot from '../../services/ValidateCaracters'
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
    Button,
    Box,
    Container,
    Grid,
    TextField,
    CircularProgress,
    Hidden,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    NativeSelect,
    Checkbox,
    InputLabel,
    Typography
} from '@material-ui/core';

const OfferDetails = () => {
    
    const model ={
      'id': 0,
      'title': '',
    'description':'',
    }
    const [values, setValues, offerTitle, setOfferTitle] = React.useState([])
    const [isCalendarStartDateOpen, setIsCalendarStartDateOpen] = React.useState(false);
    const [startDate, setStartDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD'));
    //offerid
    const [offerId,setOfferId]= React.useState("");
    const handleDateEndDateChange = (date) => {
        setEndDate(date);
        // if (dateRegex.test(date)) {
        // }
        const target = format(new Date(date), 'yyyy-MM-dd');
        updateFields('endDate', target);
        //validatedates(fields.startDate, fields.startTime, target, fields.endTime);
    };
    const [descriptionValidation,setDescriptionValidation]= React.useState({});
    const [directionValidation,setDirectionValidation]= React.useState(true);
    const [addresValidation,setAddresValidation]= React.useState(true);
    const [phoneValidation,setPhoneValidation]= React.useState({});
    const [chefValidation,setChefValidation]= React.useState({});
    const [selectedRestaurants, setSelectedRestaurants] = React.useState([]);
    const [repeat, setRepeat] = React.useState(false);

    const [isCalendarEndDateOpen, setIsCalendarEndDateOpen] = React.useState(false);
    const handleDateStartDateChange = (date) => {
        setStartDate(date);
        const target = format(new Date(date), 'yyyy-MM-dd');
        updateFields('startDate', target);

        // if (dateRegex.test(date)) {
        // }    //validatedates(target, fields.startTime, fields.endDate, fields.endTime);
    };
  
    const [hasOffers, setHasOffers] = React.useState(false);
    const { id } = useParams();
    const [repeatDays, setRepeatDays] = React.useState({
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    });
    const [restaurantId] = React.useState(useParams());
    const [restaurantInfo, setRestaurantInfo]= React.useState(model);
    const [cost,setCost]= React.useState("");
    //restaurantname
    const [restaurantname,setRestaurantname]= React.useState("");
    const [costsCatalog,setCostsCatalog] = React.useState([]);
    const [extraServicesCatalog,setExtraServicesCatalog] = React.useState([]);
    const [kitchenCatalog,setKitchenCatalog] = React.useState([]);
    const [options, setOptions] = React.useState([]);
  
 

    const [latitude,setLatitude]= React.useState(0);
    const [longitude,setLongitude]= React.useState(0);
    const [direction,setDirection]= React.useState("");
      
    const [cancel,setCancel] = React.useState("");
    const [address, setAddress] = React.useState('');

    const [kitchensOrigin,setKitchensOrigin] = React.useState("")
    const [rating,setRating] = React.useState(0);

    const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    const updateFields = (field, value) => {
        const newFields = {
            ...fields,
        };
        newFields[field] = value;
        setFields(newFields);
    };
    
    const [fields, setFields] = React.useState({
        title: '',
        description: '',
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        type: 'oferta',
    });

    function handleDescriptionChange(e){
        restaurantInfo.description.description = e.target.value;
        if(e.target.value == "" || e.target.value.includes('  ') || IsSpecialCharactersNotDot(e.target.value)){
            setDescriptionValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else if(e.target.value.length > 250){
            setDescriptionValidation({hasValidate:true,hasError:true,error:'Máximo 250 caracteres.'});
        }
        else if (regex.test(e.target.value)) {
            setDescriptionValidation({hasValidate:true,hasError:true,error:'Formato incorrecto.'});
        }
        else{
            setDescriptionValidation({hasValidate:true,hasError:false})
        }
    }
    

    function addNewoffer() {
        if (fields.title === '') {
            error('El título no puede estar vacío');
   
            return;
          }
          if (fields.description === '') {
            error('La descripción no puede estar vacía');
            return;
          }

          if (fields.type === '') {
            error('El tipo no puede estar vacío');
   
            return;
          }

          if (values === '') {
            error('La fecha no puede estar vacia');
   
            return;
          }
          if (startDate === '') {
            error('La fecha no puede estar vacia');
   
            return;
          }
          if (startDate === '') {
            error('La fecha no puede estar vacia');
   
            return;
          }

          if (endDate === '') {
            error('La fecha no puede estar vacia');
   
            return;
          }

         
          let numberhasoffers = 0;
          if (hasOffers) {
            numberhasoffers = 1;
          }

          const body = {};
          body.hasOffers = numberhasoffers;
          body.title = fields.title;
          body.description = fields.description;
          body.type = fields.type;
          const parseddates = [];

          // if values is empty, then we add the startDat
          if (values) {
            // use foreach to iterate over the values array
            values.forEach((date) => {
              // format the date to the correct format
              const parseddate = format(new Date(date), 'yyyy-MM-dd');
              // push the parsed date to the parseddates array
              parseddates.push(parseddate);
            });
            console.log('parseddates');
            console.log(parseddates);
          }
          const jsonVariable = JSON.stringify(parseddates);
      
          body.dates = jsonVariable;
          body.repeat = repeat;
          body.monday = repeatDays.monday;
          body.tuesday = repeatDays.tuesday;
          body.wednesday = repeatDays.wednesday;
          body.thursday = repeatDays.thursday;
          body.friday = repeatDays.friday;
          body.saturday = repeatDays.saturday;
          body.sunday = repeatDays.sunday;
          body.startDate = startDate;
          body.endDate = endDate;
    
          OffersService. editOffer(id,body).then((r) =>{
            if(r != null && r.success == 1){
                notify(r.message)
                setTimeout(() => {
                    document.location = '/Offers';
                }, 1500);
            }
            else{
                error(r.message)
            }
        });
   

    }

    // function handleDirectionChange(e){
    //     if(e.target.value == ""){
    //         setDirectionValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
    //     }
    //     else{
    //         setDirectionValidation({hasValidate:true,hasError:false});
    //         restaurantInfo.address.address = e.target.value;
    //     }
    // }

    function handlePhoneChange(e){
        const phoneRegex = /(^(\d{8,13})$)|^['+'](\d{8,13})$/;
        restaurantInfo.description.phone = e.target.value;
        if(!phoneRegex.test(e.target.value)){
            setPhoneValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else{
            setPhoneValidation({hasValidate:true,hasError:false});
        }
    }
    
    const renderView = () => (
        <>
            <div className="w-100 mx-2">
                <div className="RestaurantOffer-form-container mr-2">
                    <div style={{ color: 'black', fontFamily: 'roboto', fontSize: '16px' }}>
                        <span>
                            <b>Detalles de la oferta</b>
                            <br />
                        </span>
                    </div>
                    <div className="RestaurantOffer__form">
                        <div className="w100 mb-1">
                            <TextField
                                className="w100"
                                id="title"
                                label="Título"
                                value={fields.title}
                                onChange={(e) => updateFields('title', e.target.value)}
                              
                            />
                        </div>
                        <div className="w100 mb-1">
                            <TextField
                                className="w100"
                                id="description"
                                label="Descripción"
                                onChange={(e) => updateFields('description', e.target.value)}
                                value={fields.description}
                            />
                        </div>
                        <div className="w100 mb-1">
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Tipo de oferta
                                </InputLabel>
                                <NativeSelect
                                    value={fields.type}
                                    inputProps={{
                                    name: 'type',
                                    id: 'uncontrolled-native',
                                    }}
                                    onChange={(e) => updateFields('type', e.target.value)} // Agrega este manejador de eventos onChange
                                >
                                    <option value="offer">Oferta</option>
                                    <option value="promotion">Música en Vivo</option>
                                </NativeSelect>
                                </FormControl>
                            </Box>
                            </div>
                        <div className="w-100 mb-2">
                            <div>
                                <h6><b>Configuración del restaurante</b></h6>
                                <p>Selecciona la lista de los restaurantes donde se promocionará la oferta</p>
                            </div>
                            <h6>{restaurantname}</h6>

                        </div>
                        <div>
                        <h6><b>Rango de fechas</b></h6>
                            <div>Selecciona las fechas en la que se promocionarà la oferta</div>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                                <div className="mb-2">
                                    <DatePicker
                                        multiple
                                        value={values}
                                        onChange={setValues}
                                        locale={gregorianes}
                                    /> </div>
                            </MuiPickersUtilsProvider>

                        </div>
                        <div className="w100 mb-1">
                            <div className="RestaurantOffer__form__checkbox">
                                <div>
                                    <FormControlLabel
                                        key={uuid()}
                                        label="Repetir oferta"
                                        control={(
                                            <Checkbox
                                                checked={repeat}
                                                name={'repeat'}
                                                disableRipple
                                                color="primary"
                                                onChange={() => { setRepeat(!repeat); }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>Selecciona el rango de fechas donde se repetira la oferta los dias que escojas</div>
                        <div className="row">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                                <div className="w100">
                                    <Grid container>
                                        <Grid item xs={12} md={5}>
                                            <KeyboardDatePicker
                                                onClick={() => { setIsCalendarStartDateOpen(true); }}
                                                onClose={() => setIsCalendarStartDateOpen(false)}
                                                open={isCalendarStartDateOpen}
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="Fecha de inicio de la oferta"
                                                invalidLabel='Fecha invalida'
                                                invalidDateMessage="No es un formato válido"
                                                value={startDate}
                                                onChange={handleDateStartDateChange}
                                                //header disables
                                                disableToolbar
                                                autoOk
                                            >
                                            </KeyboardDatePicker>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <KeyboardDatePicker
                                                onClick={() => { setIsCalendarEndDateOpen(true); }}
                                                onClose={() => setIsCalendarEndDateOpen(false)}
                                                open={isCalendarEndDateOpen}
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="Fecha de finalización de la oferta"
                                                value={endDate}
                                                onChange={handleDateEndDateChange}
                                                //header disables
                                                disableToolbar
                                                autoOk
                                            >
                                            </KeyboardDatePicker>
                                        </Grid>
                                    </Grid>
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="weekdays-holder">
                            <div className='mt-2'>
                                Selecciona los dias que se repetirá la oferta
                            </div>
                            <div className="weekdays">
                                <FormControlLabel
                                    key={uuid()}
                                    label="Lunes"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.monday}
                                            name={'monday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, monday: !repeatDays.monday }); }}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    key={uuid()}
                                    label="Martes"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.tuesday}
                                            name={'tuesday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, tuesday: !repeatDays.tuesday }); }}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    key={uuid()}
                                    label="Miércoles"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.wednesday}
                                            name={'wednesday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, wednesday: !repeatDays.wednesday }); }}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    key={uuid()}
                                    label="Jueves"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.thursday}
                                            name={'thursday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, thursday: !repeatDays.thursday }); }}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    key={uuid()}
                                    label="Viernes"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.friday}
                                            name={'friday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, friday: !repeatDays.friday }); }}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    key={uuid()}
                                    label="Sábado"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.saturday}
                                            name={'saturday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, saturday: !repeatDays.saturday }); }}
                                        />
                                    )}
                                />
                                <FormControlLabel
                                    key={uuid()}
                                    label="Domingo"
                                    control={(
                                        <Checkbox
                                            checked={repeatDays.sunday}
                                            name={'sunday'}
                                            disableRipple
                                            color="primary"
                                            onChange={() => { setRepeatDays({ ...repeatDays, sunday: !repeatDays.sunday }); }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );


    function handleChefChange(e){
        restaurantInfo.description.cheff_name = e.target.value;
        if(e.target.value == "" || e.target.value.includes('  ') || IsSpecialCharacters(e.target.value)){
            setChefValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
        }
        else if(e.target.value.length > 30){
            setChefValidation({hasValidate:true,hasError:true,error:'Máximo 30 caracteres.'});
        }
        else if (regex.test(e.target.value)) {
            setChefValidation({hasValidate:true,hasError:true,error:'Formato incorrecto.'});
        }
        else{
            setChefValidation({hasValidate:true,hasError:false})
        }
    }

    function setRatingComponent(rating) {
        setRating(rating)
        switch (rating)
        {
            case 1:
                let one = costsCatalog.find(x => x.name === "$");
                setCost(one.encrypt_id);
            break;
            case 2:
                let two = costsCatalog.find(x => x.name === "$$");
                setCost(two.encrypt_id);
            break;
            case 3:
                let three = costsCatalog.find(x => x.name === "$$$");
                setCost(three.encrypt_id);
            break;
            case 4:
                let four = costsCatalog.find(x => x.name === "$$$$");
                setCost(four.encrypt_id);
            break;
            case 5:
                let five = costsCatalog.find(x => x.name === "$$$$$");
                setCost(five.encrypt_id);
            break;
            default:
                setCost("")
        }
    };

    function handleValet(e){
        let model = {};
            model.id = restaurantInfo.id;
        let service = extraServicesCatalog.find(x => x.name === "Valet parking");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }

    function handleMenu(e){
        let model = {};
            model.id = restaurantInfo.id;
        let service = extraServicesCatalog.find(x => x.name === "Menú vegano");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }

    function handleAccess(e){
        let model = {};
            model.id = restaurantInfo.id;
            let service = extraServicesCatalog.find(x => x.name === "Acceso para silla de ruedas");
            model.serviceId = service.encrypt_id;
        if(e.target.checked){
            model.status = 1;
        }
        else{
            model.status = 0;
        }
        RestaurantsService.putExtraService(model).then((r)=>{
            if(r != null){
                notify()
            }
            else{
                error()
            }
            getRestaurantInfo();
        });
    }

    function handlekitchen(e, food){
        let info ={};
        let validate = true;
        info.idKitchen = food;
        if(e.target.checked)
        {
            info.status = 1;
        }
        else
        {
            info.status = 0;
            let temp = restaurantInfo.kitchens.filter(x => x.encrypt_id !== food);
            validate = temp.length === 0 ? false : true;
        }
        if (validate) {
            putKitchen(info)
        }
        else{
            error("Es necesario seleccionar al menos una opción");
        }
    }

    function getCheckedKitchen(kitchenId) {
        let data = restaurantInfo.kitchens.find(x => x.encrypt_id === kitchenId);
        if(data != undefined){
            return data.status == 1 ? true : false;
        }else{
            return false;
        }
    }

    function getExtraCatalog(){
        CatalogsService.getExtraService().then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setExtraServicesCatalog(r);
                }
            }
        });
    }

    function getCostsCatalog(){
        CatalogsService.getCost().then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setCostsCatalog(r);
                }
            }
        });
    }

    function getKitchensCatalog(){
        CatalogsService.getKitchen().then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setKitchenCatalog(r.map((k) => {return {value: k.encrypt_id, label: k.name}}));
                }
            }
        });
    }

    function getKitchenOrigin(kitchens){
        let temp = "";
        kitchens.filter(x => x.status === 1).map((k) => { temp += k.name+',' });
        setKitchensOrigin(temp)
    }

    function getRestaurantInfo(){
        RestaurantsService.getRestaurant(restaurantId.id).then((r) => {
            if(r != null)
            {
                setRestaurantInfo(r);
                getKitchenOrigin(r.kitchens);
                setAddress(r.address.address);
                setRating(r.cost.name.length);
            }
            else
            {

            }
        });
    }

    function getCheckedExS(check, data){
        if(data.length != 0)
        {
            let extra = data.find(x => x.name == check);
            if(extra != undefined){
                return extra.status === 1 ? true : false;
            }
            else
            {
                return false
            }
        }
        else
        {
            return false
        }
    }

    function putDescription(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(!descriptionValidation.hasError && descriptionValidation.hasValidate != undefined){
            let model = {};
            model.id = restaurantId.id;
            model.description = restaurantInfo.description.description;
            RestaurantsService.putDescription(model).then((r) => {
                r.success === 1 ? 
                notify()
                :
                error()
                setDescriptionValidation({});
                ModalFunctions.HideModal('modalDescription'); 
            });
        }
    }

    function putDirection(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(longitude != 0 && latitude != 0){
            let model = {};
            model.id = restaurantId.id;
            model.address = address;
            model.latitude = latitude;
            model.longitude = longitude;
            RestaurantsService.putAddress(model).then((r) => {
                r.success === 1 ? 
                notify()
                :
                error()
                ModalFunctions.HideModal('modalDirection');
                getRestaurantInfo();
            });
        }

    }

    function putPhone(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(!phoneValidation.hasError && phoneValidation.hasValidate != undefined){
            let model = {};
            model.id = restaurantId.id;
            model.phone = restaurantInfo.description.phone;
            RestaurantsService.putPhone(model).then((r) => {
                r.success === 1 ? 
                notify()
                :
                error()
                setPhoneValidation({})
                ModalFunctions.HideModal('modalPhone'); 
            });
        }

    }

    function putChef(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(!chefValidation.hasError && chefValidation.hasValidate != undefined){
            let model = {};
            model.id = restaurantId.id;
            model.chef = restaurantInfo.description.cheff_name;
            RestaurantsService.putChef(model).then((r) => {
                r.success === 1 ? 
                notify()
                :
                error()
                setChefValidation({})
                ModalFunctions.HideModal('modalChef'); 
            });
        }

    }

    function putCost(){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        if(cost != "" && cost != restaurantInfo.cost.id){
            let model = {};
            model.id = restaurantId.id;
            model.costId = cost;
            RestaurantsService.putCost(model).then((r) => {
                r.success === 1 ? 
                notify()
                :
                error()
                ModalFunctions.HideModal('modalPrice'); 
                getRestaurantInfo();
            });
        }

    }

    function putKitchen(data){
        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }
        let model = {};
        model.id = restaurantId.id;
        model.idKitchen = data.idKitchen;
        model.status = data.status;
        RestaurantsService.putKitchen(model).then((r) => {
            r.success === 1 ? 
            notify()
            :
            error()
            getRestaurantInfo();
        });
    }

    function notify() {
        toast.success('Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

      function error(message) {
        toast.error(message != undefined ? message : 'No se pudo actualizar', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

      const handleChange = address => {
        setAddress(address);
        setDirection(address);
        if(address == "")
        {
            setLatitude(0)
            setLongitude(0)
            setAddresValidation(false);
            setDirectionValidation(true);
        }
        if(latitude == 0 && longitude == 0){
            setDirectionValidation(false);
            setAddresValidation(true);
        }
      };
     
    const handleSelect = address => {
        setAddress(address);
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {
              setLatitude(latLng.lat);
              setLongitude(latLng.lng);
              setDirection(address);
              setDirectionValidation(true);
              setAddresValidation(true);
            })
          .catch(error => console.error('Error', error));
      };
    
    // const handleChange = address => {
    //     setAddress(address);
    //   };
     
    // const handleSelect = address => {
    //     setAddress(address);
    //     geocodeByAddress(address)
    //       .then(results => getLatLng(results[0]))
    //       .then(latLng => {
    //           setLatitude(latLng.lat);
    //           setLongitude(latLng.lng);
    //           setDirection(address);
    //         })
    //       .catch(error => console.error('Error', error));
    //   };

      const handleRating = (rate) => {
        setRating(rate)
      }

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    React.useEffect(() => {
        const getOffer = async (id) => {
            try {
              const offer = await OffersService.getOfferById(id);
              console.log(offer);
    

              if (offer) {
                setFields(
                    {title: offer.title,
                    description: offer.description,
                    startDate: offer.startDate,
                    endDate: offer.endDate,
                    type: offer.type}
                );
                setStartDate(offer.start_date);
                setEndDate(offer.end_date);
                setRepeat(offer.repeats);
                setRepeatDays({
                    monday: offer.monday,
                    tuesday: offer.tuesday,
                    wednesday: offer.wednesday,
                    thursday: offer.thursday,
                    friday: offer.friday,
                    saturday: offer.saturday,
                    sunday: offer.sunday,
                });
                setHasOffers(offer.hasOffers);

                setRestaurantname(offer.restaurant_name);
  
          const vdates = offer.dates;
          const parsedjson = JSON.parse(vdates);
          const datesarray = [];
          for (let i = 0; i < parsedjson.length; i += 1) {
            const date = new Date(parsedjson[i]);
            // fix problem with date format reducing one day
            date.setDate(date.getDate() + 1);
            datesarray.push(date);
          }
            setValues(datesarray);
          // validations
              
                // Realiza otras acciones con los datos de la oferta si es necesario
              } else {
                console.log("No se pudo encontrar la oferta");
              }
            } catch (error) {
              console.error("Error al obtener la oferta:", error);
            }
          };
            getOffer(id);
      }, [id]);


    const desktop = () =>(
        <article>
        <div style={{ display: "flex", alignItems: "center", padding: "29px 40px" }}>
            <label style={{
                width: "111px",
                height: "25px",
                fontFamily: "Commuters Sans SemiBold",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.25,
                letterSpacing: "normal",
                textAlign: "left",
                "flex-grow": "1",
                color: "black"
            }}>
                EDITAR OFERTA
            </label>
        </div>
        <div style={{
            borderBottom: "2px solid #c8c8c8",
            height: "0px",
            textAlign: "left",
            padding: "0 24px"
        }}>
        </div>
        <div style={{ display: "flex", padding: "29px 36px 29px 40px", height: 'calc(100% - 150px)', overflow: 'auto' }}>
            <div style={{ display: "flex", width: '30%', justifyContent: 'space-between' }}>
                <div style={{ textAlign: "left" }}>
                    <p style={{
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        color: 'black',
                        letterSpacing: '0.5px',
                        width: '80%',
                        paddingTop: '40px'
                    }}>
                        Editar los datos de oferta.
                    </p>
                </div>
                <div style={{
                    width: "0px",
                    height: "630px",
                    margin: "56px 0px 70px",
                    opacity: "0.15",
                    border: "solid 0.5px #000001",
                    paddingLeft: "0px",
                    paddingRight: "0px"
                }}>
                </div>
            </div>
            <div style={{ display: 'flex', width: '70%' }}>
                <form style={{
                    textAlign: "left",
                    paddingLeft: "40px",
                    width: '100%'
                }}>
                    <div className="W100">
                        {renderView()}
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end" style={{ paddingTop: '20px' }}>
                        <Link to="/" className="btn btn-lg mt-5" style={{ width: '162px', boxShadow: 'none', fontFamily: 'Commuters Sans SemiBold' }}>
                            CANCELAR
                        </Link>
                        <button className="btn btn-primary btn-lg mt-5" type="button" style={{ width: '162px' }} onClick={() => { addNewoffer() }}>
                            GUARDAR CAMBIOS
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </article>
    );
        
    const movile = () =>(
        
         <article>
        <div style={{ display: "flex", alignItems: "center", padding: "29px 40px" }}>
            <label style={{
                width: "111px",
                height: "25px",
                fontFamily: "Commuters Sans SemiBold",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.25,
                letterSpacing: "normal",
                textAlign: "left",
                "flex-grow": "1",
                color: "black"
            }}>
                EDITAR OFERTA
            </label>
        </div>
        <div style={{
            borderBottom: "2px solid #c8c8c8",
            height: "0px",
            textAlign: "left",
            padding: "0 24px"
        }}>
        </div>
        <div style={{ display: "flex", padding: "29px 36px 29px 40px", height: 'calc(100% - 150px)', overflow: 'auto' }}>
            <div style={{ display: "flex", width: '30%', justifyContent: 'space-between' }}>
                <div style={{ textAlign: "left" }}>
                    <p style={{
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        color: 'black',
                        letterSpacing: '0.5px',
                        width: '80%',
                        paddingTop: '40px'
                    }}>
                        Editar los datos de oferta.
                    </p>
                </div>
                <div style={{
                    width: "0px",
                    height: "630px",
                    margin: "56px 0px 70px",
                    opacity: "0.15",
                    border: "solid 0.5px #000001",
                    paddingLeft: "0px",
                    paddingRight: "0px"
                }}>
                </div>
            </div>
            <div style={{ display: 'flex', width: '70%' }}>
                <form style={{
                    textAlign: "left",
                    paddingLeft: "40px",
                    width: '100%'
                }}>
                    <div className="W100">
                        {renderView()}
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end" style={{ paddingTop: '20px' }}>
                        <Link to="/" className="btn btn-lg mt-5" style={{ width: '162px', boxShadow: 'none', fontFamily: 'Commuters Sans SemiBold' }}>
                            CANCELAR
                        </Link>
                        <button className="btn btn-primary btn-lg mt-5" type="button" style={{ width: '162px' }} onClick={() => { addNewoffer() }}>
                            GUARDAR CAMBIOS
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </article>
    );
    

    return (
        isDesktopOrLaptop ? desktop() : movile()
    )
}

export default OfferDetails
