import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import BackgroundInfo from '../../components/BackgroundInfo';
import ReportContent from '../../services/founder/ReportContent';
import {useParams} from 'react-router-dom';
import ModalComponent, { ModalFunctions } from '../../components/ModalComponent';
import { Link } from 'react-router-dom';

const ReportDetails = () => {
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [total,setTotal] = React.useState(0);
    const [firstRow,setFirstRow] = React.useState(0);
    const [lastRow,setLastRow] = React.useState(0);
    const [lastPage,setLastPage] = React.useState(0);
    const [reports, setReports] = React.useState([]);
    const [refreshForce, setRefreshForce] = React.useState([]);
    const [backgrounInfo,setBackgrounInfo] = React.useState("");
    const [removeReport,setRemoveReport] = React.useState("");
    const [userName,setUserName] = React.useState("");
    const [order, setOrder] = React.useState("");
    const [detailImage, setDetailImage] = React.useState(null)
    const [detailClient, setDetailClient] = React.useState({})
    const [reportId] = React.useState(useParams());

    function notify() {
        toast.success('Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    // function orderInfo() {
    //     if(order === "" || order === "desc"){
    //         setOrder("asc");
    //     }
    //     else{
    //         setOrder("desc");
    //     }
    //     setRefreshForce(Math.random());
    // }

    function handleMovePageDown(e){
        if(page > 1){
            setPage(page-1);
            setRefreshForce(Math.random());
        }
        
    }

    function handleMovePageUp(e){
        if(page != lastPage){
            setPage(page+1);
            setRefreshForce(Math.random());
        }
    }
    
    function handleRowsChange(e){
        setPage(1);
        setRowsPerPage(e.target.value);
    }

    function getDate(date) {
        let mont =["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ]
        let data = new Date(date);
        return ( (data.getDate() >= 10 ? data.getDate():"0" + data.getDate()) + " " + mont[data.getMonth()] + " " + data.getFullYear() + " " + (data.getHours() >= 10 ? data.getHours() : "0" + data.getHours())  + ":" + (data.getMinutes() >= 10 ? data.getMinutes() : "0" + data.getMinutes()))
    }

    function DeleteContent(id) {
        var name = (detailClient.first_name !== null ? detailClient.first_name.toUpperCase() : " ") + " " + (detailClient.middle_name !== null ? detailClient.middle_name.toUpperCase() : " ") + " " + (detailClient.last_name !== null ? detailClient.last_name.toUpperCase() : " ");
        setRemoveReport(id);
        setUserName(name);
        ModalFunctions.ShowModal('modalDeleteContent');
    }

    function deleteContentService() {
        ModalFunctions.HideModal('modalDeleteContent');
        ReportContent.deleteContent(removeReport).then((r) =>{
            if(r.message == "Información eliminada correctamente")
            {
                notify()
                setRemoveReport("");
                setUserName("");
                document.location = '/ReportContent';
            }
        });
    }

    function getReportDetail() {
        ReportContent.getReportDetail(reportId.id,page,rowsPerPage).then((r) =>{
            if(r != null)
            {
                if(r.data.length != 0)
                {
                    setReports(r.data);
                    setPage(r.current_page);
                    setTotal(r.total);
                    setFirstRow(r.from);
                    setLastRow(r.to);
                    setLastPage(r.last_page);
                }
                else
                {
                    setReports([]);
                    setFirstRow(0);
                    setLastRow(0);
                    setTotal(0);
                    setLastPage(0);
                    setBackgrounInfo("Content");
                }
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }

    function getReportDetailImage() {
        ReportContent.getReportDetailImage(reportId.id).then((r) =>{
            if(r != null)
            {
                setDetailImage(r.photo);
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }

    function getReportDetailClient() {
        ReportContent.getReportDetailClient(reportId.id).then((r) =>{
            if(r != null)
            {
                setDetailClient(r);
            }
            else
            {
                setBackgrounInfo("Service");
            }
        });
    }

    function getIndex(array, serch) {
        var index = array.findIndex(x => x.reason === serch);
        return index + 1;
    }

    React.useEffect(()=>{
        getReportDetail();
        getReportDetailImage();
        getReportDetailClient();
    },[rowsPerPage,refreshForce])


    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = (reports) => (
        <article>
            <div style={{position:'absolute', left:'40px',top:'115px'}}>
                <a onClick={()=> window.location = "/ReportContent"}>
                    <img width="24" height="24" src="/images/backicon.svg"/>
                    <label className="puerta21-btn" style={{fontFamily:'roboto', fontSize:'12px', marginLeft:'8px'}}>Volver</label>
                </a>
            </div>
            <div style={{display: "flex", alignItems: "center", padding: "60px 40px 29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    REPORTE DE PUBLICACIÓN
                </label>
                <button className="btn btn-primary" type="button" style={{width:'310px'}} onClick={() =>{DeleteContent(reportId.id)}}>
                    ELIMINAR PUBLICACIÓN
                </button>
            </div>
            <div style={{display:'flex', width:'100%', height: "calc(100% - 185px)"}}>
                <div style={{padding: '30px 38px 30px 40px'}}>
                    <div style={{display:'flex', alignItems:'center', paddingBottom:'16px'}}>
                        <a>
                            <img src = {
                                            detailClient.photo != null ? detailClient.photo : "/images/profile.jpg"
                                        }
                                style={{height: '34px',width: '26px',margin:'0 8px',borderTopLeftRadius: '17px',borderTopRightRadius: '17px',border: '0.5px solid rgba(0,0,0,0.4)'}}/>
                        </a>
                        <label htmlFor="">{detailClient.first_name} {detailClient.middle_name} {detailClient.last_name}</label>
                    </div>
                    <div>
                        {
                            detailImage !== null ? <img src={detailImage}  style={{height: '344px',width: '345px'}}/> : <div style={{height: '344px',width: '345px'}}>Cargando...</div>
                        }
                    </div>
                </div>
                <div style={{
                        width: "0px",
                        height: "466px",
                        margin: "30px 0px 70px",
                        opacity: "0.40",
                        border: "solid 0.5px rgba(200, 200, 200 )",
                        paddingLeft:"0px",
                        paddingRight:"0px"
                    }}>
    
                </div>
                <div style={{padding: "20px 40px 29px 40px", overflow: "auto", height: "calc(100% - 20px)", width:'100%'}}>
                    <table className="table table-borderless table-hover" style={{
                        textAlign:'initial',
                        fontFamily:'CommutersSans'}}>
                        <thead>
                            <tr style={{ fontFamily:'Commuters Sans Thin', color:'rgba(0, 0, 0, 0.75)'}}>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'22px', width:'10%'}}>REPORTE</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'22px', width:'20%'}}>REPORTADO POR</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'22px', width:'45%'}}>MOTIVO DE REPORTE</th>
                                <th scope="col" style={{fontSize:'10px', paddingBottom:'22px', width:'25%'}}>FECHA DE REPORTE</th>
                            </tr>
                        </thead>
                        {
                            reports.length == 0 ? <tbody style={{display:'table-caption'}}><BackgroundInfo Type={backgrounInfo}/></tbody>:
                            <tbody>
                            {
                                reports.map(report =>(
                                    <tr id={getIndex(reports,report.reason)} style={{height:'80px', fontFamily: 'Roboto', fontSize: '12px', verticalAlign: 'middle'}}>
                                        <td>
                                            {getIndex(reports,report.reason)}
                                        </td>
                                        <td>
                                            <div style={{display:'flex', alignItems:'center'}}>
                                                <a>
                                                    <img src = {
                                                                    report.photo != null ? report.photo : "/images/profile.jpg"
                                                            } 
                                                        style={{height: '34px',width: '26px',margin:'0 8px',borderTopLeftRadius: '17px',borderTopRightRadius: '17px',border: '0.5px solid rgba(0,0,0,0.4)'}}/>
                                                </a>
                                                <label htmlFor="">{report.first_name} {report.middle_name} {report.last_name}</label>
                                            </div>
                                        </td>
                                        <td>
                                            {report.reason}
                                        </td>
                                        <td>
                                            {getDate(report.date)}
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        }
                    </table>
                    <div className="content-pagination">
                        <div className="content-select">
                            <span className="label">
                                Filas:&nbsp;&nbsp;
                            </span>
                            <select onChange={handleRowsChange} className="pagination-select">
                                <option selected={rowsPerPage===5} value="5">5</option>
                                <option selected={rowsPerPage===25} value="25">25</option>
                                <option selected={rowsPerPage===100} value="100">100</option>
                            </select>
                        </div>
                        <div className="content-select-pagination">
                            <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                            <label className="label">de&nbsp;&nbsp;</label>
                            <label className="number">{total}</label>
                            <button className="button-angle" onClick={handleMovePageDown}>
                                <FontAwesomeIcon icon="angle-left" />
                            </button>
                            <button className="button-angle" onClick={handleMovePageUp}>
                                <FontAwesomeIcon icon="angle-right" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent id="modalDeleteContent" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <img src="/images/iconoDescargaArchivo.svg" style={{
                                                                    height:'120px', 
                                                                    width:'120px'}}/>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"20px",
                                    color:'gray',
                                    marginTop:'60px'}}>
                                SE ELIMINARA LA PUBLICACIÓN EN EL PERFIL DE {userName}
                            </label>
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalDeleteContent')
                                }} style={{color:"black", margin: "15px"}}>MANTENER</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {deleteContentService();}}>ELIMINAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );

    const movile = (reports) => (
        <article>
            <a style={{position: "absolute", top:24, left:'5%' }} onClick={()=> window.location = "/ReportContent" }>
                <img width="24" height="24" src="/images/backicon.svg"/>
            </a>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 31,
                         left: '25%'
                         }}>
                PUBLICACIÓN REPORTADA
            </h5>
            <div style={{display:'flex', alignItems:'center', paddingBottom:'16px', paddingTop:'16px', paddingLeft:'24px'}}>
                <a>
                    <img src = {
                                    detailClient.photo != null ? detailClient.photo : "/images/profile.jpg"
                                }
                        style={{height: '55px',width: '40px',margin:'0 8px',borderTopLeftRadius: '25px',borderTopRightRadius: '25px',border: '0.5px solid rgba(0,0,0,0.4)'}}/>
                </a>
                <label style={{fontSize: "21px"}}>{detailClient.first_name} {detailClient.middle_name} {detailClient.last_name}</label>
            </div>
            <a>
                {
                    detailImage !== null ? <img src={detailImage}  style={{height: '344px',width: '345px'}}/> : <div style={{height: '344px',width: '345px'}}>Cargando...</div>
                }
            </a>
            <button className="btn btn-primary" type="button" style={{width:'310px', marginTop:'25px'}} onClick={() =>{DeleteContent(reportId.id)}}>
                ELIMINAR PUBLICACIÓN
            </button>
            <div class="list-group">
                {
                    reports.length == 0 ? <BackgroundInfo Type={backgrounInfo}/> :
                    reports.map( report =>(
                        <a style={{margin:"0", padding:"20px 24px"}}>
                            <div style={{textAlign:"left", display:"flex"}}>
                                <div style={{width:'calc(100% - 80px)', height:'max-content', padding:"0 10px" }}>
                                     <span style={{
                                        fontFamily: "Commuters Sans SemiBold",
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        wordWrap: "none",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        letterSpacing: "normal",
                                        color: "black",
                                        textTransform:"uppercase",
                                        display:"inline-block",
                                        overflow: "hidden",
                                        height: "20px"
                                        }}>{report.first_name} {report.middle_name} {report.last_name}</span>
                                    {/* <div style={{display:'flex', flexDirection:'row'}}>
                                        <label style={{fontFamily:"Roboto", display:"block", fontSize:'13px'}}>Reportes: {report.number_reports}</label>
                                    </div>*/}
                                    <div style={{marginTop:"6px"}}>
                                        <label style={{fontFamily:"Roboto", display:"block", fontSize:'13px'}}>{report.reason}</label>
                                    </div> 
                                    <div style={{marginTop:"6px"}}>
                                        <label style={{fontFamily:"Roboto", display:"block", fontSize:'13px'}}>Ultimo reporte: {getDate(report.date)}</label>
                                    </div>
                                </div>
                            </div>
                        </a>
                    ))
                }
            </div>
            <div className="content-pagination">
                <div className="content-select">
                    <span className="label">
                        Filas:&nbsp;&nbsp;
                    </span>
                    <select onChange={handleRowsChange} className="pagination-select" defaultValue="5">
                        <option value="5">5</option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className="content-select-pagination">
                    <label className="number">{firstRow}-{lastRow}&nbsp;&nbsp;</label>
                    <label className="label">de&nbsp;&nbsp;</label>
                    <label className="number">{total}</label>
                    <button className="button-angle" onClick={handleMovePageDown}>
                        <FontAwesomeIcon icon="angle-left" />
                    </button>
                    <button className="button-angle" onClick={handleMovePageUp}>
                        <FontAwesomeIcon icon="angle-right" />
                    </button>
                </div>
            </div>
            <ModalComponent id="modalDeleteContent" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems:"center", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <img src="/images/iconoDescargaArchivo.svg" style={{
                                                                    height:'120px', 
                                                                    width:'120px'}}/>
                            <label style={{
                                    fontFamily:"Roboto-Light",
                                    fontSize:"20px",
                                    color:'gray',
                                    marginTop:'60px'}}>
                                SE ELIMINARA LA PUBLICACIÓN EN EL PERFIL DE {userName}
                            </label>
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <Link className="btn-link" type="button" onClick={()=>{
                                ModalFunctions.HideModal('modalDeleteContent')
                                }} style={{color:"black", margin: "15px"}}>MANTENER</Link>
                        <button className="btn btn-primary" type="button" onClick={() => {deleteContentService();}}>ELIMINAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </article>
    );
    
    return (
        isDesktopOrLaptop ? desktop(reports) : movile(reports)
    )
}

export default ReportDetails
