import React from 'react'

const StatusSelectComponent = (props) => {

    function GetClassName(status){
        return status === 'Pendiente' ? "form-puerta21-status-select-black": 
                        status === 'Pagado' ? "form-puerta21-status-select-green"  :
                        status === 'Por vencer' ? "form-puerta21-status-select-orange": 
                        "form-puerta21-status-select-red";
    }

    function GetText(status){
        return status === 'Pendiente' ? "PENDIENTE": 
                       status === 'Pagado' ? "PAGADO"  :
                       status === 'Por vencer' ? "POR VENCER": 
                       "VENCIDO";
    }

    function updateStatus(paymentId, status){
        props.changestatus(status,paymentId);
        setCollapsed(true);
    }

    function Toggle(){
        if (props.enabled) {
            setCollapsed(!collapsed);        
        }
    }

    const [collapsed, setCollapsed] = React.useState(true);
    
    return (
        <div>
            <div className={ GetClassName(props.status) } id={props.id}>
                <label style={{marginLeft:'5px'}} onClick={Toggle}>{ GetText(props.status) }</label>
                <img src="/images/chevron-down.svg" style={{width:'10px', height:'10px'}} onClick={Toggle}/>
            </div>
            {
                !collapsed ? 
                    (<div id={"options"+ props.id} className="form-puerta21-status-select-options">
                        <div className="form-puerta21-status-select-black" style={{ marginTop:'16px'}} onClick={() =>(updateStatus(props.id,'Pendiente'))}>
                            <label style={{marginLeft:'5px'}}>PENDIENTE</label>
                        </div>
                        <div className="form-puerta21-status-select-green" style={{ marginTop:'16px'}} onClick={() =>(updateStatus(props.id,'Pagado'))}>
                            <label style={{marginLeft:'5px'}}>PAGADO</label>
                        </div>
                        <div className="form-puerta21-status-select-orange" style={{ marginTop:'16px'}} onClick={() =>(updateStatus(props.id,'Por vencer'))}>
                            <label style={{marginLeft:'5px'}}>POR VENCER</label>
                        </div>
                        <div className="form-puerta21-status-select-red" style={{ marginTop:'16px', marginBottom:'16px' }} onClick={() =>(updateStatus(props.id,'Vencido'))}>
                            <label style={{marginLeft:'5px'}}>VENCIDO</label>
                        </div>
                    </div>)
                : <div></div>
            }
        </div>
    )
}

export default StatusSelectComponent
